/* eslint-disable no-undef */
const { PRO_LOADED } = require('../constants/action-types');

const createGlobalExperimentVariable = experiments =>
  Object.values(experiments)
    .map(experiment => `${experiment.ticketId}_${experiment.group}`)
    .join(', ');

const qualtricsReloadOnSubsResponse = mwApi => next => action => {
  // eslint-disable-next-line callback-return
  next(action);

  if (action.type === PRO_LOADED) {
    const experiments = mwApi.getState().experiments;

    global.PROJECT_LIKE_TILES_EXPERIMENT = createGlobalExperimentVariable(experiments);

    if (typeof QSI !== 'undefined' && typeof QSI.API !== 'undefined') {
      // When websites load, we need to re-run the Qualtrics logic in
      // order to pick up information about eligibility that is based on the
      // user's owned products.
      QSI.API.unload();
      QSI.API.load();
    }
  }
};

module.exports = {
  qualtricsReloadOnSubsResponse
};
