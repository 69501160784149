const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };

const transformCpanelData = data => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.filter(cpanelProduct => cpanelProduct?.entitlement_id);
};

const apiDesc = {
  getHostingCpanel: {
    url: '/products/proxy/hosting/cpanel/accounts',
    method: 'GET',
    resourceData: [],
    dataTransform: transformCpanelData,
    options: {
      headers: {
        'Accept': 'application/json',
        'User-Agent': 'myProducts'
      }
    }
  }
};

const api = new Reduxful('hostingCpanelApi', apiDesc, apiConfig);

module.exports = api;
module.exports.transformCpanelData = transformCpanelData;
