const {
  RESELLER_STOREFRONT_LOADING,
  RESELLER_STOREFRONT_LOADED,
  RESELLER_STOREFRONT_FAILED
} = require('../constants/action-types');

const initialResellerStorefrontState = {
  isResellerStorefrontLoading: false,
  isResellerStorefrontError: false
};

const resellerStorefront = (state = initialResellerStorefrontState, action = {}) => {
  switch (action.type) {
    case RESELLER_STOREFRONT_LOADING:
      return { ...state, isResellerStorefrontLoading: true };
    case RESELLER_STOREFRONT_LOADED:
      return {
        ...state,
        resellerStorefrontData: action.payload.data,
        isResellerStorefrontLoading: false,
        isResellerStorefrontError: false
      };
    case RESELLER_STOREFRONT_FAILED:
      return {
        ...state,
        isResellerStorefrontError: true
      };
    default:
      return state;
  }
};

module.exports = {
  default: resellerStorefront
};
