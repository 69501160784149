const { DIRECT_TO_CART_LOADING, DIRECT_TO_CART_LOADED, DIRECT_TO_CART_FAILURE, PRODUCT_RENEWAL_SUCCESS } = require('../constants/action-types');

const initialState = {
  renewalSuccess: {}
};

const expressRenewal = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case DIRECT_TO_CART_LOADING: {
      const { basketId } = action.payload;
      newState[basketId] = { ...newState[basketId], isLoading: true, isLoaded: false, error: null };
      return newState;
    }
    case DIRECT_TO_CART_LOADED: {
      const { basketId } = action.payload;
      newState[basketId] = { ...newState[basketId], isLoading: false, isLoaded: true, error: null };
      return newState;
    }
    case DIRECT_TO_CART_FAILURE: {
      const { basketId } = action.payload;
      newState[basketId] = { ...newState[basketId], isLoading: false, isLoaded: true, error: true };
      return newState;
    }
    case PRODUCT_RENEWAL_SUCCESS: {
      const { productId, subscriptionId } = action.payload;
      const productRenewalSuccess = { ...state.renewalSuccess, [productId]: true, subscriptionId };
      return {
        ...state,
        renewalSuccess: productRenewalSuccess
      };
    }
    default:
      return state;
  }
};

module.exports = {
  default: expressRenewal
};

