const {
  productTypes,
  PLIDS
} = require('./constants');
const {
  SPECIAL_STATUS,
  EXPIRATION_DATE_STATUS: { PASSED }
} = require('../../../constants/app');
const PFIDs = require('./pfids');

function is123RegCustomer(plid) {
  return PLIDS['123REG'].includes(Number(plid));
}

function isSecureServer(plid) {
  return !PLIDS['123REG'].includes(Number(plid)) && PLIDS.GODADDY !== plid;
}

function getWebsitesOrderedGroups(productObjs) {
  const groupedProducts = productObjs.reduce(
    (
      {
        paidUnactivated,
        paidActivated,
        unactivatedFreeTrial,
        activatedFreeTrial,
        unactivatedExpiredFreeTrial
      },
      product
    ) => {
      if (product.billing?.specialStatus === SPECIAL_STATUS.TRIAL) {
        if (product.activated) {
          activatedFreeTrial.push(product.id);
        } else if (product.billing.paidThroughDateStatus === PASSED) {
          unactivatedExpiredFreeTrial.push(product.id);
        } else {
          unactivatedFreeTrial.push(product.id);
        }
      } else if (product.activated) {
        paidActivated.push(product.id);
      } else {
        paidUnactivated.push(product.id);
      }
      return {
        paidUnactivated,
        paidActivated,
        unactivatedFreeTrial,
        activatedFreeTrial,
        unactivatedExpiredFreeTrial
      };
    },
    {
      paidUnactivated: [],
      paidActivated: [],
      unactivatedFreeTrial: [],
      activatedFreeTrial: [],
      unactivatedExpiredFreeTrial: []
    }
  );
  return groupedProducts;
}

function getEmailAndOfficeOrderedGroups(productObjs) {
  const { EMAIL_AND_OFFICE, MICROSOFT_EMAIL } = productTypes;
  const M365_TYPES = [EMAIL_AND_OFFICE, MICROSOFT_EMAIL];

  const groupedProducts = productObjs.reduce(
    ({ m365Unactivated, oxUnactivated, emailForwardsPack, others }, product) => {
      if (M365_TYPES.includes(product.type) && !product.activated && !product.hasChildren && !PFIDs.EMAIL_FORWARDS_PACK.includes(product.CES?.pfid)) {
        m365Unactivated.push(product.id);
      } else if (product.type === productTypes.OPEN_EXCHANGE && !product.activated && !product.hasChildren) {
        oxUnactivated.push(product.id);
      } else if (M365_TYPES.includes(product.type) && PFIDs.EMAIL_FORWARDS_PACK.includes(product.CES?.pfid)) {
        emailForwardsPack.push(product.id);
      } else {
        others.push(product.id);
      }
      return { m365Unactivated, oxUnactivated, emailForwardsPack, others };
    },
    {
      m365Unactivated: [],
      oxUnactivated: [],
      emailForwardsPack: [],
      others: []
    }
  );
  return groupedProducts;
}

function isCommerceSaasProduct(productObj) {
  return [productTypes.INVOICING, productTypes.POINT_OF_SALE].includes(productObj?.type);
}

function hasCommerceSubscription(productObj) {
  return Boolean(productObj?.commerceSubscription?.id);
}

function getCommerceOrderedGroups(productObjs) {
  const nonCommerceSaasProducts = productObjs.filter((product) => !isCommerceSaasProduct(product));
  const commerceSaasProducts = productObjs.filter((product) => isCommerceSaasProduct(product));

  return [
    {
      ids: nonCommerceSaasProducts.sort((a, b) => {
        if (hasCommerceHomeStore(a) && !hasCommerceHomeStore(b)) {
          // place commerce home store entitlements at the top
          return -1;
        }
        const aName = a.type === productTypes.COMMERCE_HOME ? a.name : a.detailedName;
        const bName = b.type === productTypes.COMMERCE_HOME ? b.name : b.detailedName;
        return aName.localeCompare(bName);
      }).map((p) => p.id)
    },
    {
      ids: commerceSaasProducts.sort((a, b) => a.name.localeCompare(b.name)).map((p) => p.id)
    }
  ];
}

// Checks whether godaddy business contains a commerce home store entitlement
// after mapping relations between business and stores.
function hasCommerceHomeStore(productObj) {
  return 'commerceHomeStoresCount' in productObj && productObj.commerceHomeStoresCount > 0;
}

module.exports = {
  is123RegCustomer,
  getWebsitesOrderedGroups,
  getEmailAndOfficeOrderedGroups,
  getCommerceOrderedGroups,
  isSecureServer,
  hasCommerceHomeStore,
  isCommerceSaasProduct,
  hasCommerceSubscription
};
