const { getIsReseller } = require('../utilities/ResellerHelper');
const { PLIDS } = require('../src/account-products/config/constants');

const selectSwitchboard = state => state?.config?.switchboard;
const selectMarket = state => state?.config?.market;
const selectIsPcx = state => state?.config?.pcx;
const selectPlid = state => state?.config?.plid;

const selectSSOBaseUrl = state => state.config.baseUrls?.ssoUrl;
const selectSubscriptionsPageUrl = state => state.config?.subscriptionPageUrl;

const selectIsReseller = state => getIsReseller(selectPlid(state));
const selectIs123RegCustomer = state => PLIDS['123REG'].includes(state.config.plid);

const selectIsDelegation = state => !!state.config?.delegation;

module.exports = {
  selectSwitchboard,
  selectMarket,
  selectIsPcx,
  selectPlid,
  selectSSOBaseUrl,
  selectSubscriptionsPageUrl,
  selectIsReseller,
  selectIs123RegCustomer,
  selectIsDelegation
};
