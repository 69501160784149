const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE';

module.exports = {
  SET_WINDOW_SIZE,
  setSize: windowSize => dispatch => {
    dispatch({
      type: SET_WINDOW_SIZE,
      windowSize
    });
  }
};
