const { makeFetchAdapter } = require('reduxful');
const TimeoutController = require('./TimeoutController');
const { apmClient } = require('../../services/apm');

const onResolved = response => {
  return response.ok ? response : Promise.reject(response);
};

/**
 * Class to wrap custom behaviors for a fetch-based request adapter
 *
 * @class FetchAdapter
 */
class FetchAdapter {
  /**
   * Creates an instance of FetchAdapter.
   * @param {object} configuration - Configuration for API request adapter
   * @param {number} configuration.timeoutMS - timeout in milliseconds for any API call described
   * @memberof FetchAdapter
   */
  constructor(configuration) {
    if (!configuration) {
      configuration = {
        timeoutMS: 10000 // 10s timeout for API calls
      };
    }
    let requestAdapter;
    if (!global.fetch || !global.AbortController) {
      // For IE, legacy browsers or other clients missing the AbortController or fetch API (such as node or react-native)
      // continue without cancellation as an option
      const fetch = require('@gasket/fetch');

      requestAdapter = (url, options) => {
        const fullOptions = {
          ...options,
          timeout: configuration.timeoutMS
        };
        fetch(url, fullOptions).then(onResolved);
      };
    } else {
      const controller = new TimeoutController(configuration);
      requestAdapter = (url, options) => {
        const fullOptions = {
          ...options,
          signal: controller.signal,
          timeout: configuration.timeoutMS
        };

        controller.beginTimeout();
        return fetch(url, fullOptions)
          .then(onResolved)
          .finally(() => {
            controller.cancelTimeout();
          });
      };
    }

    const timedRequestAdapter = async (url, options = {}) => {
      const fullOptions = {
        ...options,
        timeout: configuration.timeoutMS
      };

      const apmTransaction = apmClient.startElasticApmTransaction(fullOptions.method, url);

      try {
        const response = await requestAdapter(url, fullOptions);
        apmClient.endSuccessfulApmTransaction(apmTransaction);
        // Request resolved to something, could be 200 level success or a failure code, but the server responded
        return response;
      } catch (exception) {
        apmClient.captureErrorElasticApm(`API call exception to ${fullOptions?.method} ${url}`, apmTransaction, {
          status: exception.status,
          statusText: exception.statusText
        });
        // eslint-disable-next-line no-console
        console.error(`API call exception to ${url}`, exception);

        // Exception with the call itself, no response from the server: cancelled, aborted, SSL failure, etc=
        // Bubble up the exception
        return exception;
      }
    };

    this.requestAdapter = timedRequestAdapter;
    return makeFetchAdapter(this.requestAdapter);
  }
}

module.exports = FetchAdapter;
