const { PLIDS, PLT } = require('../src/account-products/config/constants');

function getIsReseller(plid) {
  return (plid && plid.toString() !== PLIDS.GODADDY.toString());
}

const isApiReseller = plt => plt === PLT.API_RESELLER_TYPE;

module.exports = {
  getIsReseller,
  isApiReseller
};
