module.exports.PRODUCT_IS_SETUP = 'SETUP';

module.exports.PRODUCT_NOT_SETUP = 'NOT_SETUP';

module.exports.PRODUCT_IS_PENDING = 'PENDING';

module.exports.PRODUCT_IS_PROTECTED_DOMAIN = 'PROTECTED_DOMAIN';

module.exports.PRODUCT_IS_PRIVATE_DOMAIN = 'PRIVATE DOMAIN';

module.exports.PRODUCT_IS_NAKED_DOMAIN = 'NAKED_DOMAIN';

module.exports.DOMAIN_DISCONNECTED_STATUS = ['DISCONNECTED'];

module.exports.DOMAIN_CONNECTED_STATUS = [
  'CONNECTED',
  'CONNECTED_DCC',
  'EXTERNALLY_CONNECTED',
  'INTERNALLY_CONNECTED_MANAGED_WORDPRESS',
  'INTERNALLY_CONNECTED_WEBSITE'
];

module.exports.DOMAIN_ACTIVATION_STATUS = {
  DISCONNECTED: 'DISCONNECTED',
  CONNECTED: 'CONNECTED',
  CONNECTED_DCC: 'CONNECTED_DCC',
  EXTERNALLY_CONNECTED: 'EXTERNALLY_CONNECTED',
  INTERNALLY_CONNECTED_MWP: 'INTERNALLY_CONNECTED_MANAGED_WORDPRESS',
  INTERNALLY_CONNECTED_WEBSITE: 'INTERNALLY_CONNECTED_WEBSITE'
};

module.exports.DOMAIN_TRUNCATION_LENGTH = 40;

module.exports.PARENT = 'parent';
