const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');


const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };
const defaultResponse = [];

const catalogRoute = '/products/catalogapi/Catalog/v1/pricelockprice';

const apiDesc = {
  renewalPriceLockedPrice: {
    url: catalogRoute,
    resourceData: defaultResponse,
    method: 'POST',
    options: getState => ({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': getState().config.csrfToken,
        'x-app-key': 'products'
      }
    })
  }
};

const api = new Reduxful('catalogapi', apiDesc, apiConfig);

module.exports = api;
