const { is123RegCustomer } = require('../product_config_helpers');
const { makeURL } = require('./url_config_parser');

const { productConfig, accordionConfig } = require('../product_config');
const { BUTTON_ACTIONS } = require('../../../../constants/button');

function getIsModalEnum(value) {
  return Object.values(BUTTON_ACTIONS).includes(value);
}

function getProductRenew(product, { plid }) {
  const renew = product.calculated.buttons.renew;

  if (typeof renew === 'function') {
    const renewValue = renew();
    if (getIsModalEnum(renewValue)) {
      return renewValue;
    }
    if (renewValue) {
      return makeURL(renewValue, product, { plid });
    }
    return renewValue;
  }

  return renew;
}

function findProductCategoryInfo(productObj) {
  for (const productCategoryInfo of productConfig) {
    if (productCategoryInfo.criteria(productObj)) {
      return productCategoryInfo;
    }
  }
  return null;
}

function populateButtons(productObj = {}, categoryButtons = {}, context = {}) {
  if (!categoryButtons) {
    return;
  }

  for (const [buttonType] of Object.entries(categoryButtons)) {
    const productButtons = productObj.calculated.buttons;

    const buttonDataGenerator = categoryButtons[buttonType];
    const buttonData = buttonDataGenerator(productObj, context);

    if (!buttonData) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (typeof buttonData === 'object') {
      productButtons[buttonType] = {
        ...buttonData,
        ...(buttonData.url ? { url: makeURL(buttonData.url, productObj, context) } : {}),
        type: buttonData.type
      };
    } else if (typeof buttonData === 'string' && !getIsModalEnum(buttonData)) {
      productButtons[buttonType] = makeURL(buttonData, productObj, context);
    } else {
      productButtons[buttonType] = buttonData;
    }
  }
}

function calculateSecondaryValues(productObj, context) {
  const info = findProductCategoryInfo(productObj);
  if (!info) {
    // eslint-disable-next-line no-console
    console.info('CONFIG PARSE ERROR, uncategorized product: ', productObj);
    return;
  }
  productObj.calculated = {
    row: {},
    buttons: {}
  };
  populateButtons(productObj, info.buttons, context);
  for (const rowField in info.row) {
    if (Object.prototype.hasOwnProperty.call(info.row, rowField)) {
      const fieldGenFunc = info.row[rowField];
      const result = fieldGenFunc(productObj, context);
      // doing this thusly ensures we don't assign null or undefined values
      if (result) productObj.calculated.row[rowField] = result;
    }
  }
}

function getCategoryInfoFromProducts(productObjs, { plid }) {
  const is123Reg = is123RegCustomer(plid);

  const categories = {};
  for (const [id, productObj] of Object.entries(productObjs)) {
    const productCategoryInfo = findProductCategoryInfo(productObj);

    if (productCategoryInfo) {
      const accordionId = productCategoryInfo.accordion || '';
      const accordionInfo = accordionConfig.find(elem => elem.id === accordionId);

      if (accordionInfo && !accordionInfo.hidden) {

        // if category already exists, add productId to category list
        const existingCategory = !!categories[accordionId];
        if (existingCategory && !categories[accordionId].productObjIDs.includes(id)) {
          categories[accordionId].productObjIDs.push(id);
        }
        // create a new category if needed
        if (!existingCategory) {
          // sets the manageAllURL depending on whether the user is a 123Reg customer or not
          let manageAllURL = '';
          if (is123Reg && accordionInfo.manageAll123RegUrl) {
            manageAllURL = makeURL(accordionInfo.manageAll123RegUrl);
          } else if (accordionInfo.manageAllURL) {
            manageAllURL = makeURL(accordionInfo.manageAllURL);
          }
          categories[accordionId] = {
            id: accordionId,
            langTitle: accordionInfo.langTitle,
            productObjIDs: [id],
            orderedGroups: accordionInfo?.customProductOrder,
            customProductOrderFunc: accordionInfo?.customProductOrder,
            isCollapsed: true,
            manageAllURL
          };
        }
      } else if (!accordionInfo.hidden) {
        // eslint-disable-next-line no-console
        console.info('CONFIG PARSE ERROR, accordion does not exist: ', accordionId);
      }
    } else {
      // eslint-disable-next-line no-console
      console.info('CONFIG PARSE ERROR, uncategorized product: ', productObj);
    }
  }
  return categories;
}

/*
  categorizationData sample object
  [
    {
      id: 'domains',
      langTitle: 'groupTitle.domains',
      manageAllURL: 'https://dcc.godaddy.com',
      productObjIDs: [
        '3566773',
        '875634563',
        'jf89u894g5-r3ji53jg864-fj3uih64ui2-234jhf'
      ]
    },
    {
      id: 'websites',
      langTitle: 'groupTitle.websites',
      productObjIDs: [
        '875e634563',
        'sjf89u894g5'
      ]
    },
    {
      id: 'smartline',
      langTitle: 'groupTitle.smartline',
      productObjIDs: [
        '875e634563',
        'sjf89u894g5'
      ]
    }
  ]
*/

/**
 * this will be called each time productObj data changes
 * its result will be read by the UI to render the accordions
 * @param {Array} productObjs array of all npos
 * @param {Object} context an object w other info needed to categorized npos
 * @param {number} context.plid private label id of site
 * @returns {Object} Info needed to render all accordions
 */
function generateCategorizationData(productObjs, { plid }) {
  const categories = getCategoryInfoFromProducts(productObjs, { plid });
  const result = [];

  Object.values(categories)?.forEach(category => {
    // populate orderedGroups
    if (category.orderedGroups) {
      const categoryProductObjs = Object.values(productObjs).filter(productObj => category.productObjIDs.includes(productObj.id));
      category.orderedGroups = category.orderedGroups(categoryProductObjs).filter(orderedGroup => orderedGroup?.ids?.length > 0);
    } else {
      category.orderedGroups = [{ ids: category.productObjIDs }];
    }
    result.push(category);
  });
  // sort the categories to match the order defined in accordionConfig
  result.sort((a, b) => {
    const Aindex = accordionConfig.findIndex(elem => elem.id === a.id);
    const Bindex = accordionConfig.findIndex(elem => elem.id === b.id);
    return Aindex - Bindex;
  });
  return result;
}

module.exports = {
  findProductCategoryInfo,
  generateCategorizationData,
  calculateSecondaryValues,
  getProductRenew,
  getIsModalEnum
};
