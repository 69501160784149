const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };

const baseRoute = '/platapi';
const defaultResponse = [];

// RAA Domains
const raaDescriptions = {
  getRAADomains: {
    url: `${baseRoute}/v1/domains`,
    resourceData: defaultResponse,
    method: 'GET'
  },
  sendRAADomainEmail: {
    url: `${baseRoute}/v1/domains/:domain/verifyRegistrantEmail`,
    resourceData: defaultResponse,
    method: 'POST',
    options: getState => ({
      headers: {
        'X-XSRF-Token': getState().config.csrfToken
      }
    })
  }
};

// Country data
const countryDescriptions = {
  getCountries: {
    url: `${baseRoute}/v1/countries?marketId=:market`,
    resourceData: [],
    method: 'GET'
  },
  getStates: {
    url: `${baseRoute}/v1/countries/:country?marketId=:market`,
    resourceData: [],
    method: 'GET',
    dataTransform: ({ states = [] } = {}) => states
  }
};

const apiDesc = {
  ...countryDescriptions,
  ...raaDescriptions
};
const api = new Reduxful('platApi', apiDesc, apiConfig);

module.exports = api;
