const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');
const { MAX_RENDERABLE_VENTURES } = require('../account-products/tiles/VentureTile/constants/ventures');

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };

const baseRoute = '/websitesapi';
const defaultResponse = [];

const condenseWebsiteData = data => {
  const fakeOrionId = '00001000-0010-0001-0001-000000100000';
  const transformed = data
    .filter(
      website =>
        website.attributes.website &&
        website.accountId !== fakeOrionId &&
        (website.status === 'active' || website.status === 'initialized') &&
        website.storeType !== 'apple'
    )
    .map(item => ({
      websiteId: item.id,
      accountId: item.accountId,
      name: item.businessName,
      url: item.domainName,
      isPublished: item.publishStatus === 'published',
      status: item.status,
      createDate: item.createDate,
      imageUrl: item.backgroundImage, // will be null if no website image
      startedAsFreeMatTrial: item.attributes.startedAsFreeMatTrial,
      startedAsTrial: item.attributes.startedAsTrial,
      freemium: item.attributes.freemium,
      freeMat: item.attributes.freeMatTrial,
      trial: item.attributes.trial
    }))
    .slice(0, MAX_RENDERABLE_VENTURES + 1);
  return transformed;
};

const apiDesc = {
  getWebsiteData: {
    url: `${baseRoute}/v2/composite/website-list`,
    resourceData: defaultResponse,
    dataTransform: data => ({ summary: condenseWebsiteData(data), raw: data }),
    method: 'GET'
  }
};

const api = new Reduxful('websitesapi', apiDesc, apiConfig);
module.exports = api;

module.exports.condenseWebsiteData = condenseWebsiteData;
