const cloneDeep = require('lodash.clonedeep');
const {
  VENTURES_LOADING,
  VENTURES_LOADED,
  VENTURES_FAILED,
  DETAILED_VENTURE_LOADING,
  DETAILED_VENTURE_LOADED,
  DETAILED_VENTURE_FAILED,
  ADD_FAILED_VENTURE_ID
} = require('../constants/action-types');

const initialVentureDataState = {
  ventures: [],
  failedVentureIds: [],
  venturesLoading: false,
  venturesLoaded: false,
  venturesError: false,
  errorList: []
};

const ventureData = (state = initialVentureDataState, action = {}) => {
  switch (action.type) {
    case VENTURES_LOADING:
      return { ...state, venturesLoading: true };
    case VENTURES_LOADED:
      return { ...state, venturesLoading: false, venturesLoaded: true, ventures: action.data || [] };
    case VENTURES_FAILED:
      return { ...state, venturesLoading: false, venturesError: true, errorList: action.data };
    case DETAILED_VENTURE_LOADING: {
      const currentVentureIndex = state.ventures?.findIndex(detailedVenture => detailedVenture.id === action.ventureId);
      const clonedDetailedVentures = cloneDeep(state.ventures);
      clonedDetailedVentures[currentVentureIndex] = {
        ...clonedDetailedVentures[currentVentureIndex],
        error: action.error,
        loading: true,
        loaded: false
      };
      return {
        ...state,
        ventures: clonedDetailedVentures
      };
    }
    case DETAILED_VENTURE_LOADED: {
      const currentVentureIndex = state.ventures?.findIndex(detailedVenture => detailedVenture.id === action.ventureId);
      const clonedDetailedVentures = cloneDeep(state.ventures);
      clonedDetailedVentures[currentVentureIndex] = {
        ...action.data,
        error: action.error,
        loading: false,
        loaded: true
      };
      return {
        ...state,
        ventures: clonedDetailedVentures
      };
    }
    case DETAILED_VENTURE_FAILED: {
      const currentVentureIndex = state.ventures?.findIndex(detailedVenture => detailedVenture.id === action.ventureId);
      if ((!currentVentureIndex && currentVentureIndex !== 0) || currentVentureIndex < 0) return state;
      const clonedDetailedVentures = cloneDeep(state.ventures);
      clonedDetailedVentures[currentVentureIndex] = {
        ...clonedDetailedVentures[currentVentureIndex],
        error: action.error,
        loading: false,
        loaded: true
      };
      return {
        ...state,
        ventures: clonedDetailedVentures
      };
    }
    case ADD_FAILED_VENTURE_ID: {
      const ventureId = action.ventureId;
      if (!ventureId) return state;

      const failedVentureIds = new Set(state.failedVentureIds);
      failedVentureIds.add(ventureId);
      return {
        ...state,
        failedVentureIds: Array.from(failedVentureIds)
      };
    }
    default:
      return state;
  }
};

module.exports = {
  default: ventureData
};
