const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };
const defaultResponse = [];


const apiDesc = {
  renewInCart: {
    url: '/products/renewInCart',
    resourceData: defaultResponse,
    method: 'POST',
    options: getState => ({
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getState().config.csrfToken
      }
    })
  }
};

const api = new Reduxful('cartapi', apiDesc, apiConfig);

module.exports = api;
