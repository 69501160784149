const {
  DOMAIN_HOSTING_LOADING,
  DOMAIN_HOSTING_LOADED,
  DOMAIN_HOSTING_FAILED
} = require('../constants/action-types');

const  STATUS = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  REJECTED: 'REJECTED'
};

function domainsHostings(state = {}, action) {
  const newState = { ...state };
  switch (action.type) {
    case DOMAIN_HOSTING_LOADING: {
      const { domainName } = action.payload;
      newState[domainName] = {
        ...newState[domainName],
        status: STATUS.LOADING
      };
      return newState;
    }
    case DOMAIN_HOSTING_LOADED: {
      const { domainName, hostingProvider } = action.payload;
      newState[domainName] = {
        ...newState[domainName],
        hostingProvider,
        status: STATUS.LOADED
      };
      return newState;
    }
    case DOMAIN_HOSTING_FAILED: {
      const { domainName, error } = action.payload;
      newState[domainName] = {
        ...newState[domainName],
        status: STATUS.REJECTED,
        error
      };
      return newState;
    }
    default:
      return state;
  }
}

function getIsDomainHostingNotLoaded(domainHosting = {}) {
  const { status } = domainHosting;
  const hostingIsNotLoaded = ![STATUS.LOADED, STATUS.LOADING, STATUS.REJECTED].includes(status);
  return hostingIsNotLoaded;
}

function getDomainHostingLoaded(domainHosting = {}) {
  const { status } = domainHosting;
  const hostingLoaded = [STATUS.LOADED, STATUS.REJECTED].includes(status);
  return hostingLoaded;
}

module.exports = {
  default: domainsHostings,
  getIsDomainHostingNotLoaded,
  getDomainHostingLoaded,
  STATUS
};
