const planTypes = {
  AIROPLUS: 'logoImage'
};

const productTypes = {
  ADULT_BLOCK: 'adultBlock',
  AUCTIONS: 'auctions',
  BROKERAGE: 'brokerage',
  BUSINESS_EMAIL: 'businessEmail',
  CALENDAR: 'calendar',
  CASH_PARKING: 'cashParking',
  CLOUD_SERVERS: 'cloudServers',
  CONTACT_MANAGER: 'contactManager',
  CONVERSATIONS_UNIFIEDINBOXONLY: 'conversationsUnifiedInboxOnlyFreePlan',
  CONVERSATIONS: 'conversations',
  COSTCO_BUNDLE: 'costcoBundle',
  DO_IT_YOURSELF_SOFTWARE: 'doItYourselfSoftware',
  DOMAIN_DISCOUNT_CLUB: 'discountDomainClub',
  DOMAIN_APPRAISALS: 'domainAppraisals',
  DOMAIN_BACKORDER: 'domainBackordering',
  DOMAINS: 'domains',
  DOMAIN: 'domain',
  DREAM_WEB_DESIGNS: 'dreamWebDesigns',
  DUDA: 'duda',
  EASY_DATABASE: 'easyDatabase',
  EMAIL_AND_OFFICE: 'emailAndOffice',
  EMAIL_MARKETING: 'emailMarketing',
  EXPRESS_EMAIL_MARKETING: 'expressEmailMarketing',
  FAX_THRU_EMAIL: 'faxThruEmail',
  GD_AUCTIONS: 'gdAuctions',
  GD_BUSINESS_ADS: 'businessAds',
  GD_GOMOBILE: 'goMobile',
  GD_STUDIO: 'designStudio',
  GET_FOUND: 'getFound',
  GET_PAID: 'getPaid',
  GLOBAL_BLOCK: 'globalBlock',
  GODADDY_IT_SERVICES: 'gdITServices',
  GODADDY_PRO: 'gdPro',
  HOST_EUROPE: 'hostEurope',
  HOSTED_EXCHANGE: 'hostedExchange',
  HOSTING: 'hosting',
  HOSTING_CPANEL: 'cpanel',
  INSTANT_PAGE: 'instantPage',
  INVOICING: 'invoicing',
  KABBAGE: 'kabbage',
  LEASED_DOMAIN: 'leasedDomain',
  LOGO_DESIGN_SERVICES: 'logoDesignServices',
  MANAGED_LISTING_SERVICES: 'managedListingServices',
  MANAGED_WORDPRESS: 'managedWordPress',
  MEDIA_TEMPLE_AWS: 'mtAws',
  MEDIA_TEMPLE_HOSTING: 'mediaTempleHosting',
  MEDIA_TEMPLE_EMAIL: 'mtMail',
  MEMBERSHIP: 'membership',
  MICROSOFT_EMAIL: 'microsoftEmail',
  OMNI_HARDWARE: 'omniHardware', // GD Payments Hardware (aka child entitlements)
  OMNI_PAY: 'omniCommerce', // GD Payments (parent entitlements)
  ONLINE_BOOK_KEEPING: 'onlineBookKeeping',
  ONLINE_MARKETING: 'onlineMarketing',
  ONLINE_STORAGE: 'onlineStorage',
  ONLINE_STORE: 'onlineStore',
  OPEN_EXCHANGE: 'openXchange',
  PARTNER_PRODUCTS: 'partnerProducts',
  PHOTO_ALBUM: 'photoAlbum',
  PHOTOSHOOT: 'photoShoot',
  POINT_OF_SALE: 'pos',
  PREMIUM_DNS: 'premiumDNS',
  PREMIUM_SUPPORT: 'premiumSupport',
  PRIVATE_LABEL_ID_TYPE: 'PrivateLabelIdType',
  PRODUCTIVITY: 'productivity',
  PROFESSIONAL_WEB_SERVICES: 'professionalWebServices',
  PRO_PACKAGE: 'proPackage',
  QUICK_SHOPPING_CART: 'quickShoppingCart',
  RESELLER: 'reseller',
  SEARCH_ENGINE_VISIBILITY: 'searchEngineVisibility',
  SEO: 'seo',
  SEO_SERVICES: 'seoServices',
  SERVERS: 'servers',
  SMARTLINE_PHONE_SERVICES: 'smartLine',
  SOCIAL_MEDIA_MANAGEMENT: 'socialMediaManagement',
  SOCIAL_VALUE: 'socialValue',
  SSL_CERTS: 'sslCerts',
  SSL_CERTIFICATE: 'sslCertificate',
  TELEPHONY: 'telephony',
  TRUSTED_SITE: 'trustedSite',
  WEBSITE_BACKUP: 'websiteBackup',
  WEBSITE_BUILDER: 'websiteBuilder',
  WEBSITE_CARE: 'websiteCare',
  WEBSITE_DESIGN_SERVICES: 'websiteDesignServices',
  WEBSITE_PROTECTION: 'websiteProtection',
  WEBSITE_SECURITY: 'websiteSecurity',
  WEBSITES_AND_MARKETING: 'websitesAndMarketing',
  WORDPRESS_DESIGN_SERVICES: 'wordpressDesignServices',
  WORDPRESS: 'wordpress',
  WORKSPACE_EMAIL: 'workspaceEmail',
  WSB_MAKEOVER: 'wsbMakeover',
  WOO_COMMERCE: 'woosaas',
  MEDIASOL: 'mediasol',
  COMMERCE_HOME: 'commerceHome',
  BUNDLE: 'bundle',
  BCT: 'bctpremium'
};

const ACCORDION_IDS = {
  domains: 'domains',
  websites: 'websites',
  onlineMarketing: 'onlineMarketing',
  proWebServices: 'proWebServices',
  managedWooCommerce: 'managedWooCommerce',
  commerceAndPayments: 'commerceAndPayments',
  partnerProducts: 'partnerProducts',
  hostEurope: 'hostEurope',
  websiteSecurityAndBackups: 'websiteSecurityAndBackups',
  sslCerts: 'sslCerts',
  msoftHostedExchange: 'msoftHostedExchange',
  workspace: 'workspace',
  emailAndOffice: 'emailAndOffice',
  cloudServers: 'cloudServers',
  easyDatabase: 'easyDatabase',
  servers: 'servers',
  mediaTemple: 'mediaTemple',
  managedWordpress: 'managedWordpress',
  webHosting: 'webHosting',
  goMobile: 'goMobile',
  websiteProtection: 'websiteProtection',
  costcoBundle: 'costcoBundle',
  photoAlbum: 'photoAlbum',
  contactManager: 'contactManager',
  reseller: 'reseller',
  onlineBookKeeping: 'onlineBookKeeping',
  searchEngineVisibility: 'searchEngineVisibility',
  expressEmailMarketing: 'expressEmailMarketing',
  emailMarketing: 'emailMarketing',
  businessAds: 'businessAds',
  domainProducts: 'domainProducts',
  gdPro: 'gdPro',
  gdITServices: 'gdITServices',
  premiumSupport: 'premiumSupport',
  conversations: 'conversations',
  smartlinePhoneServices: 'smartlinePhoneServices',
  mediaSol: 'mediaSol'
};

const productStatus = {
  ACTIVE: 'ACTIVE',
  PENDSETUP: 'PENDSETUP',
  // NES Constants
  PENDING_PROVISION: 'PENDING-PROVISION'
};

const openExchangeMarkets = [
  'es-AR',
  'pt-BR',
  'es-CL',
  'es-CO',
  'en-IN',
  'hi-IN',
  'mr-IN',
  'ta-IN',
  'en-MY',
  'es-MX',
  'en-PK',
  'es-PE',
  'en-PH',
  'en-ZA',
  'tr-TR',
  'es-VE',
  'id-ID',
  'vi-VN',
  'th-TH',
  'pt-PT',
  'en-SG',
  'en-HK',
  'zh-HK',
  'zh-TW',
  'ja-JP',
  'ko-KR',
  'ru-RU',
  'uk-UA',
  'el-GR',
  'pl-PL'
];

const protectionPlans = {
  GOOD: 'GOOD',
  BETTER: 'BETTER',
  BEST: 'BEST'
};

const secondaryComponents = {
  PRIVACY: 'PrivacySubtitle',
  SMARTLINE: 'SmartlineSubtitle',
  MWP_CHILDREN: 'ManagedWordPressChildren',
  WAM_APPSTORE: 'WAMAppStoreSubtitle',
  EMAIL_FIVE_PACK: 'EmailFivePackSubtitle',
  THIRDPARTY: 'thirdParty',
  PAID_SUPPORT: 'paidSupport'
};

const primaryButtonTypes = {
  MANAGE: 'manage',
  MOBILE_MANAGE: 'mobile_manage',
  MOBILE_SETUP: 'mobile_setup',
  MOBILE_SIGNIN: 'mobile_signin',
  SEE_PLAN: 'see_plan',
  SETUP: 'setup',
  SIGNIN: 'signin',
  WEB_INBOX: 'web_inbox',
  GET_NUMBER: 'get_number',
  PENDING: 'pending',
  COMPARE_ALL: 'compare_all'
};

const accordionAlertSeverities = {
  NONE: 0,
  NOT_SETUP: 1,
  EXPIRING: 2
};

const PLIDS = {
  '123REG': [525844, 587240],
  'GODADDY': 1,
  'GENERIC_RESELLER': 3153
};

const PLT = {
  API_RESELLER_TYPE: 3
};

const USER = {
  INVESTOR: 'Investor',
  PARTNER: 'Partner',
  US_INDEPENDENT: 'US Independent',
  INTERNATIONAL_INDEPENDENT: 'International Independent'
};

const CREDIT_CARD = 'CreditCard';

const isUPPEligibleProductGUID = '6bb45f05-b8f2-4a30-8a9d-092d77967170';

const SUPPORT_PHONE_NUMBERS = {
  US: '+1 480-651-9733',
  SG: '+65 63494181',
  CO: '+57 (601) 919-7050',
  ID: '(021) 5099-6503',
  KR: '(030) 864 0202',
  TH: '02-566-0781',
  PH: '+63 (02) 8626-3063',
  JP: '050 5050 8615',
  VN: '+84 (0284) 458 1025'
};

const MARKETS = {
  US: 'en-US',
  SG: 'en-SG',
  CO: 'es-CO',
  ID: 'id-ID',
  KR: 'ko-KR',
  TH: 'th-TH',
  PH: 'en-PH',
  JP: 'ja-JP',
  VN: 'vi-VN',
  CA: 'en-CA'
};

const NAMESPACES = {
  DIABLO: 'diablo'
};

module.exports = {
  accordionAlertSeverities,
  productTypes,
  productStatus,
  protectionPlans,
  openExchangeMarkets,
  planTypes,
  secondaryComponents,
  primaryButtonTypes,
  ACCORDION_IDS,
  PLIDS,
  PLT,
  isUPPEligibleProductGUID,
  USER,
  CREDIT_CARD,
  SUPPORT_PHONE_NUMBERS,
  MARKETS,
  NAMESPACES
};
