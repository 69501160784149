const productGroupKeys = {
  AUCTIONS: 'auctions',
  BROKERAGE: 'brokerage',
  BUSINESS_EMAIL: 'businessEmail',
  CLOUD_SERVERS: 'cloudServers',
  DISCOUNT_DOMAIN_CLUB: 'discountDomainClub',
  DOMAIN_BACKORDER: 'domainBackordering',
  DOMAIN: 'domain',
  DOMAINS: 'domains',
  DUDA: 'duda',
  EMAIL_AND_OFFICE: 'emailAndOffice',
  GET_FOUND_DESIGN_SERVICES: 'getFoundDesignServices',
  GET_FOUND: 'getFound',
  HOST_EUROPE: 'hostEurope',
  HOSTING: 'hosting',
  INSTANT_PAGE: 'instantPage',
  KABBAGE: 'kabbage',
  MEDIASOL: 'mediasol',
  MEMBERSHIP: 'membership',
  OMNI_COMMERCE_SOFTWARE: 'omniCommerceSoftware',
  ONLINE_MARKETING: 'onlineMarketing',
  ONLINE_STORE: 'onlineStore',
  OPEN_XCHANGE: 'openXchange',
  PARTNER_PRODUCTS: 'partnerProducts',
  PHOTOSHOOT: 'photoShoot',
  PRODUCTIVITY: 'productivity',
  QUICK_SHOPPING_CART: 'quickShoppingCart',
  RESELLER: 'reseller',
  SEO: 'seo',
  SERVERS: 'servers',
  SMARTLINE_PHONE_SERVICES: 'smartlinePhoneServices',
  SOCIAL_VALUE: 'socialValue',
  TELEPHONY: 'telephony',
  WDS_GOCENTRAL_OLS: 'pwsGocentralOls',
  WEB_DESIGN_SERVICES: 'webDesignServices',
  WEBSITE_BACKUP: 'websiteBackup',
  WEBSITE_BUILDER: 'websiteBuilder',
  WEBSITE_SECURITY: 'websiteSecurity',
  WORDPRESS_DESIGN_SERVICES: 'wordpressDesignServices',
  WORDPRESS: 'wordpress',
  WSB_MAKEOVER: 'WSBmakeover',
  BUNDLE: 'bundle',
  CPANEL: 'cpanel',
  SSL_CERTS: 'sslCerts'
};

module.exports = productGroupKeys;
