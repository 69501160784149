/* eslint-disable no-process-env */
import { apmClient } from '../services/apm';
import { App } from '@godaddy/gasket-next';

/* The following are Imports intended for Gasket/NextJs
Webpack Configurations to push the imported packages/modules to commons.[contenthash].js browser bundle
*/
import 'lodash.throttle';

// global style imports
import '../components/app.scss';
import '../components/MuiPod.scss';

// uxcore icon style imports (my products)
import '@ux/icon/alert/index.scss';
import '@ux/icon/chevron-down/index.scss';
import '@ux/icon/chevron-left/index.scss';
import '@ux/icon/chevron-right/index.scss';
import '@ux/icon/chevron-up/index.scss';
import '@ux/icon/gd-the-go/index.scss';
import '@ux/icon/information/index.scss';
import '@ux/icon/mail/index.scss';
import '@ux/icon/plus/index.scss';
import '@ux/icon/share/index.scss';
import '@ux/icon/website/index.scss';
import '@ux/icon/world/index.scss';
import '@ux/icon/add/index.scss';
// uxcore misc
import '@ux/search/dist/styles.css';
import '@ux/tag/dist/styles.css';
// other external library style imports
import '@domains/react-xgrade/css/index.css';
// domain search bar styles
import '@site/domain-search-box/dist/styling.css';
import '@site/domain-search-box/dist/index.css';

import '@godaddy/retention/src/styles/index.scss';
import '@venture/tiles/dist/styles.css';

function withApm() {
  return (Component) => {
    const Wrapper = (ownProps) => {
      const shopperId = ownProps.initialState?.config.shopperId;
      const enableDebugMode = ownProps.initialState?.config?.switchboard?.enableDebugAPM;
      const { apmProps } = ownProps;
      apmClient.configure(apmProps, shopperId, enableDebugMode);
      return (
        <Component { ...ownProps } />
      );
    };

    Wrapper.getInitialProps = async (ctx) => {
      const apmProps = {
        serviceName: process?.env?.npm_package_name,
        serverUrl: process?.env?.ELASTIC_APM_SERVER_URL,
        serviceVersion: process?.env?.npm_package_version,
        environment: process?.env?.NODE_ENV
      };

      return {
        ...(Component.getInitialProps
          ? await Component.getInitialProps(ctx)
          : {}),
        apmProps
      };
    };
    return Wrapper;
  };
}

export default withApm()(App);
