const HOSTING_PRODUCT_TYPE_IDS = {
  14: 'web-hosting',
  135: 'hosting',
  210: 'hosting',
  402: 'hosting',
  407: 'hosting',
  459: 'linux-hosting-cpanel',
  461: 'windows-hosting',
  222: 'dedicated-server',
  480: 'dedicated-server',
  494: 'dedicated-server',
  422: 'cloud-servers',
  513: 'cloud-servers'
};

module.exports = {
  HOSTING_PRODUCT_TYPE_IDS
};
