const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };

const profileRoute = '/platapi/v1.1/shoppers/:shopperId';
const defaultProfileResponse = {};

const defaultParams = { auditClientIp: 'browser' };
const defaultGetParams = { ...defaultParams, includes: 'contact,preference' };

const constants = {
  FAILURE_CODES: {
    ACCESS_DENIED_HBI: 'ACCESS_DENIED_HBI',
    CUSTOMER_VALIDATION_SKIPPED: 'ACCESS_DENIED_CUSTOMER_VALIDATION_SKIPPED'
  }
};

const apiDesc = {
  getShopperProfile: {
    url: profileRoute,
    resourceData: defaultProfileResponse,
    method: 'GET'
  },
  updateShopperProfile: {
    url: profileRoute,
    resourceData: defaultProfileResponse,
    method: 'PATCH',
    options: getState => ({
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getState().config.csrfToken
      }
    }),
    errorTransform: ({ code }) => code
  }
};

const api = new Reduxful('shopperApi', apiDesc, apiConfig);

module.exports = api;
module.exports.defaultParams = defaultParams;
module.exports.defaultGetParams = defaultGetParams;
module.exports.constants = constants;
