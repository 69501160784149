const app = require('./app').default;
const config = require('./config').default;
const CurrencyApi = require('../src/api/CurrencyApi');
const CustomerTypeApi = require('../src/api/CustomerTypeApi');
const domainActivator = require('./domainActivator').default;
const domainerInfo = require('../src/account-products/reducers/domainerInfo').default;
const DomainsApi = require('../src/api/DomainsApi');
const experiments = require('./experiments').default;
const freeCredits = require('./freeCredits').default;
const HostingCpanel = require('../src/api/HostingCpanelApi');
const managedWordpress = require('./managedWordpress').default;
const muipod = require('./muipod').default;
const paymentMethods = require('./paymentMethods').default;
const PlatApi = require('../src/api/PlatApi');
const proData = require('./proData').default;
const resellerStorefront = require('./resellerStorefront').default;
const ShopperApi = require('../src/api/ShopperApi');
const CatalogAPI = require('../src/api/CatalogApi');
const CartApi = require('../src/api/CartApi');
const subscriptions = require('./subscriptions').default;
const websiteDataApi = require('../src/api/WebsiteDataApi');
const productGraphApi = require('../src/api/ProductGraphApi');
const ShimApi = require('../src/api/ShimApi');
const windowSize = require('../src/layout/state/windowSize/reducers').default;
const normalizedProducts = require('../src/account-products/reducers/normalizedProducts').default;
const ventureData = require('./ventureData').default;
const domainsHostings = require('./domainsHostings').default;
const expressRenewal = require('./expressRenewal').default;

module.exports = {
  app,
  config,
  domainActivator,
  domainerInfo,
  experiments,
  freeCredits,
  hivemind: f => f || null,
  managedWordpress,
  muipod,
  paymentMethods,
  proData,
  resellerStorefront,
  subscriptions,
  windowSize,
  normalizedProducts,
  ventureData,
  domainsHostings,
  expressRenewal,
  // Reduxful-generated reducers
  ...CurrencyApi.reducers,
  ...CustomerTypeApi.reducers,
  ...PlatApi.reducers,
  ...ShopperApi.reducers,
  ...DomainsApi.reducers,
  ...CatalogAPI.reducers,
  ...CartApi.reducers,
  ...websiteDataApi.reducers,
  ...productGraphApi.reducers,
  ...ShimApi.reducers,
  ...HostingCpanel.reducers
};
