const { GD_STUDIO, PHOTOSHOOT, SUPER_RESELLER_PFIDS } = require('../src/account-products/config/pfids');

const renewDisabledPfids = [
  '632636',
  '939416',
  '3010',
  '3002',
  '3004',
  '3005',
  '2657',
  '2658',
  '2659',
  '3006',
  '3007',
  '3008',
  // gocentral freemium
  '891787',
  '891788',
  '1471823',
  '1471824'
].concat(GD_STUDIO, PHOTOSHOOT, SUPER_RESELLER_PFIDS);

module.exports = {
  default: renewDisabledPfids
};
