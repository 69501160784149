/* eslint-disable id-length */
const get = require('lodash/get');
const {
  getDetailedName,
  getBillingInfo,
  getIsProductSetupSupportedSelfServe,
  mapFivePackRelationsIds,
  addRelationsToNPOs,
  mapGDPaymentsRelationsIds,
  mapCommerceHomeRelationsToGDPayments,
  mapCommerceSaasRelationsToGDPayments,
  addBusinessIdToGDPaymentsHW,
  getType,
  appendNewAccount,
  mapIsUppEligibleToNPO,
  identifyUPPEligibleNESProducts,
  identifyUPPEnabledCESProducts
} = require('./NormalizerHelper');
const { getIsReseller } = require('../../../../utilities/ResellerHelper');
const { getIntlCache } = require('../intlHelper');
const telemetry = require('../../../../utilities/telemetry');
const { yesterday, aYearInFuture } = require('../../../../utilities/dateHelpers');
const { isCommerceSaasProduct } = require('../../config/product_config_helpers');

// constants
const { CUSTOM_PFID } = require('../../config/pfids');
const { freemiumPfids, FREEMIUM_ACTIVE_DAYS } = require('../../../../constants/freemiumPfids');
const { PARENT } = require('../../../../constants/productStateConstants');
const { productStatus, productTypes } = require('../../config/constants');
const app = require('../../../../constants/app');
const NESProductTypes = require('../../../../constants/nes-product-types');
const productGroupKeys = require('../../../../constants/product-group-keys');

const intlCache = getIntlCache();

const CESRequiredFields = ['product.productGroupKey', 'subscriptionId'];

function amendWithCESsubsApi({ cesSubscriptions, products }, config) {
  const relationsMap = {};

  cesSubscriptions.forEach(sub => {
    // if some required fields are missing, don't create an NPO from that sub
    if (!CESRequiredFields.every(reqField => get(sub, reqField, false))) {
      const missingRequiredFields = CESRequiredFields.filter(reqField => !get(sub, reqField, false));
      // eslint-disable-next-line no-console
      console.error(`CES subscription [${sub.product.label}] missing the following field(s): ${missingRequiredFields}`);
      telemetry.send('normalizer_amend_func', 'amend_with_CES_sub', 'missing_fields', 'on_load', missingRequiredFields);
      return;
    }

    const { productGroupKey } = sub.product;
    const isDomain = productGroupKey === productGroupKeys.DOMAINS;
    const label = sub?.label || appendNewAccount(productGroupKey, config);
    const pfid = sub.product?.pfid?.toString();
    const createdAt = sub.createdAt;
    const isFreemium = freemiumPfids.includes(pfid);
    let paidThroughDate = sub.expiresAt;

    if (isFreemium) {
      const freemiumExpirationDate = new Date(createdAt);
      freemiumExpirationDate.setUTCDate(freemiumExpirationDate.getUTCDate() + FREEMIUM_ACTIVE_DAYS);
      paidThroughDate = freemiumExpirationDate.toISOString();
    }
    const isUPPEligibleSubscription = identifyUPPEnabledCESProducts({ config, sub, productGroupKey });
    const result = {
      isPending:
        sub.status === productStatus.PENDSETUP &&
        (isDomain || (productGroupKey === productGroupKeys.SERVERS && config.switchboard?.vpsPendingState)),
      name: sub.product.label,
      plan: sub.product.plan ?? '',
      detailedName: isDomain ? label.toLowerCase() : label,
      paidThroughDate,
      // Not all subscriptions are guaranteed to have an externalId so we fallback to subscriptionId
      id: sub.externalId || sub.subscriptionId,
      type: getType(sub.product),
      autoRenewEnabled: sub.renewAuto,
      createdAt,
      billing: getBillingInfo({
        paidThroughDate,
        autoRenew: sub.renewAuto,
        productGroupKeyOrType: productGroupKey,
        billing: sub.billing,
        pfid,
        status: sub.status,
        numberOfTerms: sub.product?.renewalPeriod,
        renewable: sub.renewable,
        period: sub.product?.renewalPeriodUnit,
        paymentProfileId: sub?.paymentProfileId
      }),
      CES: {
        resourceId: sub.subscriptionId.split(':')[0].toString(),
        productTypeId: sub.subscriptionId.split(':')[1].toString(),
        pfid,
        addons: sub.addons ? sub.addons.map(addon => ({ ...addon, pfid: parseInt(addon.pfid, 10).toString() })) : [],
        status: sub.status,
        renewalPfid: sub.product?.renewalPfid?.toString(),
        renewOptions: sub.renewOptions || null,
        namespace: sub.product?.namespace,
        bundleName: sub.bundleName,
        bundleDomain: sub.bundleDomain,
        bundleRelations: sub?.bundleRelations,
        nesSubscriptionId: sub?.nesSubscriptionId,
        // Injection for A/B experiment on hosting UPP display
        __hostingExperiment: sub?.__hostingExperiment
      },
      // todo: isUpgradable from categorizationconfig
      isUpgradable: false,
      isHardBundled: false,
      relations: {
        parentId: '',
        childrenIds: []
      },
      isUPPEligible: isUPPEligibleSubscription,
      get hasChildren() {
        return this.relations.childrenIds.length > 0;
      },
      get isChild() {
        return this.relations.parentId?.length > 0;
      },
      get isBundle() {
        return this.type === productTypes.BUNDLE;
      },
      get isBundleChild() {
        return this.CES.bundleRelations?.some(({ relationType = '' }) => relationType === PARENT) ?? false;
      }
    };

    const productSetupSupported = getIsProductSetupSupportedSelfServe(result);

    // we default activated to true so that the customer won't see DA disappear if Fabric returns late
    result.activated = isDomain ? true : (label.toLowerCase() !== app.PRODUCT_NOT_SETUP || !productSetupSupported);
    if (isDomain) {
      result.domain = {
        tld: sub.label.split('.')[1].toLowerCase(),
        protection: null,
        allowedGBBActions: []
      };
    }

    if (result.CES?.renewOptions?.pfid) result.CES.renewOptions.pfid = result.CES.renewOptions.pfid.toString();

    // add mappings map parent subscriptionId to externalId
    mapFivePackRelationsIds(relationsMap, sub, result.id);

    products[result.id] = result;
  });
  addRelationsToNPOs(relationsMap, products);
  return products;
}

function amendWithSslCredits({ products, sslCredits }) {
  const sslCreditsProductGuids = sslCredits?.map(sslCredit => sslCredit.productGuid);

  Object.entries(products).forEach(([key, product]) => {
    if (product.type === productGroupKeys.SSL_CERTS) {
      const activated = !sslCreditsProductGuids?.includes(key);
      products[key] = { ...product, activated };
    }
  });
  return products;
}

// NOTE: NESRequiredFields logic left here, but commented out.
// It's very possible that we'll need it again, but for the short term
// the errors reported by it aren't very helpful
//
// const NESRequiredFields = [
//   'productFamily',
//   'product',
//   'product.name',
//   'product.productType',
//   'commonName',
//   'entitlementId',
//   'autoRenew',
//   'metadata',
//   'metadata.createdAt'
// ];

function amendWithShimEntitlements({ entitlements, products }, config) {
  const intl = intlCache(config.market);

  const relationsMap = {};
  entitlements.forEach(entitlement => {
    // const missingRequiredFields = NESRequiredFields.filter(reqField => (!get(entitlement, reqField, false)));
    // if (missingRequiredFields.length > 0) {
    //   // eslint-disable-next-line no-console
    //   console.error(`NES entitlement [${entitlement.commonName || 'NAME MISSING'}] missing the following field(s): ${missingRequiredFields}`);
    //   telemetry.send('normalizer_amend_func', 'amend_with_NES_entitlement', 'missing_fields', 'on_load', missingRequiredFields);
    // }

    // import the storeId and businessName for commerceHome shoppers
    const isCommerceHome = entitlement.product?.productType && entitlement.product?.productType === NESProductTypes.COMMERCE_HOME;
    const isCommerceSaas = isCommerceSaasProduct({ type: entitlement.product?.productType });
    let businessId = entitlement.product.provisioningData?.businessId || '';
    let storeId = null;

    if (isCommerceHome || isCommerceSaas) {
      businessId = entitlement.product?.businessId;
      storeId = entitlement.product?.storeId;
    }

    const isDomain =
      entitlement.productFamily &&
      entitlement.productFamily === NESProductTypes.DOMAIN &&
      entitlement.product?.productType !== NESProductTypes.ADULT_BLOCK &&
      entitlement.product?.productType !== NESProductTypes.GLOBAL_BLOCK;

    const isLTODomain = !!entitlement.latestContract;
    const paidThroughDate =
      entitlement.latestContract?.nextPaymentDate || entitlement.paidThroughDate || aYearInFuture.toString(); // If there's not paidThroughDate we assume is a one pay thing
    const entitlementType = entitlement.product?.productType || entitlement.productFamily || '';
    const entitlementName = entitlement.product?.name || appendNewAccount(entitlement.product?.productType, config);
    const commonName = entitlement.commonName || intl.formatMessage({ id: 'newAccount' });
    const period = isLTODomain ? entitlement.latestContract.paymentTerm.termType : entitlement.product?.term?.termType;
    const numberOfTerms = isLTODomain ? entitlement.latestContract.paymentsLeft : entitlement.product?.term?.numberOfTerms;

    const result = {
      id: String(entitlement.entitlementId) || 'MISSING_ID',
      isPending:
        entitlement.status === productStatus.PENDING_PROVISION &&
        (isDomain || (entitlement.productFamily === productGroupKeys.SERVERS && config.switchboard?.vpsPendingState)),
      status: entitlement.status || '',
      name: entitlementName,
      plan: entitlement.product?.plan ?? '',
      managementConsole: entitlement?.managementConsole?.url,
      detailedName: getDetailedName({
        isDomain,
        entitlementType,
        commonName,
        entitlementName,
        sld: entitlement.product?.sld,
        tld: entitlement.product?.tld
      }),
      paidThroughDate,
      type: entitlementType,
      autoRenewEnabled: entitlement.autoRenew || false,
      createdAt: entitlement.metadata?.createdAt || yesterday.toString(),
      // todo: isUpgradable from categorizationconfig
      isUpgradable: false,
      billing: getBillingInfo({
        paidThroughDate,
        autoRenew: entitlement.autoRenew,
        productGroupKeyOrType: entitlement.product.productType,
        isThirdParty: entitlement.isThirdParty,
        period,
        numberOfTerms
      }),
      NES: {
        businessId,
        storeId
      },
      isHardBundled: !!entitlement.isHardBundled, // parsed as !! in case the entitlement doesn't have the property
      relations: {
        parentId: '',
        childrenIds: []
      },
      get hasChildren() {
        return this.relations.childrenIds.length > 0;
      },
      get isChild() {
        return this.relations.parentId.length > 0;
      }
    };

    // if we get tld / sld data, like we do from global block, let's hang onto it in the NES object
    if (entitlement?.product?.sld) {
      result.NES.sld = entitlement.product.sld.toLowerCase();
    }
    if (entitlement?.product?.tld) {
      result.NES.tld = entitlement.product.tld.toLowerCase();
    }

    // we default activated to true so that the customer won't see DA disappear if Fabric returns late
    const productSetupSupported = getIsProductSetupSupportedSelfServe(result);
    result.activated = isDomain ? true : entitlement.status === productStatus.ACTIVE || !productSetupSupported;
    if (isDomain) {
      result.domain = {
        tld: entitlement.product.tld,
        protection: entitlement.product.plan ? entitlement.product.plan.toUpperCase() : null,
        allowedGBBActions: []
      };
    }

    mapGDPaymentsRelationsIds(relationsMap, entitlement);

    products[result.id] = result;
  });

  addRelationsToNPOs(relationsMap, products);

  // For the new Commerce And Payments V2 experience, we create child store level view to parent business level view
  mapCommerceHomeRelationsToGDPayments(products);

  // The businessId only comes in the GDPayment parent entitlement
  // We also need to include the businessId in the child NPO (GDPayment Hardware) to use it in the manage button
  addBusinessIdToGDPaymentsHW(products);
}

// Related to the api call defined in DomainFabricService.js (loadDomainsStatus)
function amendWithDomainEligibility({ products, domainEligibility }) {
  const keysOfDomainEligibilityData = Object.keys(domainEligibility);
  if (keysOfDomainEligibilityData.length === 0) {
    return;
  }

  Object.entries(products).forEach(([key, product]) => {
    if (product.domain) {
      const domainName = product.detailedName;
      if (keysOfDomainEligibilityData.includes(domainName)) {
        const domainEligibilityObj = domainEligibility[domainName];

        products[key] = {
          ...products[key],
          domain: {
            ...products[key].domain,
            allowedGBBActions: domainEligibilityObj
          }
        };
      }
    }
  });
}

// Related to the api call defined in DomainsApi.js (getEligibilities)
function amendWithDCC({ products, dccFabric }) {
  const keysOfDCCFabricData = Object.keys(dccFabric);
  if (keysOfDCCFabricData.length === 0) {
    return;
  }

  Object.entries(products).forEach(([key, product]) => {
    if (product.domain) {
      const domainName = product.detailedName;

      if (keysOfDCCFabricData.includes(domainName)) {
        const dccFabricObj = dccFabric[domainName];
        products[key] = {
          ...products[key],
          activated: dccFabricObj.activated
        };

        if (product.CES) {
          // we don't need to do this for NES since the same info comes with the entitlements
          if (dccFabricObj.protectionPlan) {
            products[key] = {
              ...products[key],
              domain: {
                ...products[key].domain,
                protection: dccFabricObj.protectionPlan.toUpperCase()
              }
            };
          }
        }
      }
    }
  });
}

/**
 * For each subsite, create a new child mwp NPO, connect it to its parent product, and add it to state.
 * The new child NPO object have the same fields as other NPOs for compatability.
 * Some of these fields, especially in the billing object, have filler data for proper rendering.
 * @param {object} state The redux state
 * @param {array} sites MWP sub-sites returned from MWP api endpoint
 */
function amendWithMWP({ products, sites = [] }) {
  sites.forEach(site => {
    // if a parent product can be identified
    const parentObj = products[site.accountUid] ? { ...products[site.accountUid] } : null;
    const mwpDomain = site.domain; // if site.domain doesn't exist, it's likely an unused auto-provisioned fake site
    if (parentObj && [productGroupKeys.WORDPRESS, productTypes.MANAGED_WORDPRESS].includes(parentObj.type)) {
      // filter out sites associated to non-WP products, like MWCS (Managed WooCommerce Stores)
      const childAlreadyAdded = parentObj.relations?.childrenIds.some(childId => products[childId].name === mwpDomain);
      if (!childAlreadyAdded) {
        const currChildCount = parentObj.relations?.childrenIds.length;
        const childObj = {
          // add mwp-specific information
          mwp: {
            siteId: site.siteUid,
            status: site.status
          },
          activated: true,
          isPending: false,
          name: mwpDomain,
          detailedName: mwpDomain,
          id: `${parentObj.id}-${currChildCount}`,
          type: parentObj.type,
          autoRenewEnabled: false,
          createdAt: site.siteCreationDate,
          billing: {
            payStatus: app.PAID,
            paidThroughDateStatus: app.EXPIRATION_DATE_STATUS.DISTANT,
            specialStatus: app.SPECIAL_STATUS.NONE,
            paidThroughDateProximity: null,
            isActiveNonRenewable: false,
            isRenewable: false,
            thirdParty: false,
            period: parentObj.billing?.period,
            numberOfTerms: parentObj.billing?.numberOfTerms
          },

          ...(parentObj.CES && { CES: parentObj.CES }),
          ...(parentObj.NES && { NES: parentObj.NES }),
          isUpgradable: false,
          isHardBundled: false,
          relations: {
            parentId: parentObj.id,
            childrenIds: []
          },
          isChild: true,
          hasChildren: false
        };
        parentObj.relations = {
          ...(parentObj.relations ? parentObj.relations : {}),
          childrenIds: [...parentObj.relations.childrenIds, childObj.id]
        };

        products[childObj.id] = childObj;
      }
      if (parentObj.relations.childrenIds.length > 1) {
        parentObj.hasChildren = true;
      } else {
        parentObj.detailedName = mwpDomain;
      }
      if (site.status === app.ACTIVE) {
        // use MWP as source of truth as they handle domain changes and CES may be out of sync
        parentObj.activated = true;
      }
      products[parentObj.id] = parentObj;
    }
  });

  // add siteId info to mwp single parent NPOs so they can site-specific urls
  const singleParentObjects = Object.values(products).filter(
    productObj => productObj.type === productGroupKeys.WORDPRESS && productObj.relations.childrenIds.length === 1
  );
  singleParentObjects.forEach(parentObj => {
    const childProduct = products[parentObj.relations.childrenIds[0]];
    const childSiteId = childProduct.mwp.siteId;
    parentObj = {
      ...parentObj,
      mwp: {
        siteId: childSiteId
      }
    };
    products[parentObj.id] = parentObj;
  });
}

function amendWithWebsiteData({ products, websiteData = [] } = {}) {
  websiteData.forEach(website => {
    const accountId = website?.accountId;
    const product = products[accountId];
    if (!product) return;

    products[accountId] = {
      ...products[accountId],
      websiteStatus: website?.status,
      websiteId: website?.id,
      websiteBusinessName: website?.businessName,
      publishStatus: website?.publishStatus,
      billing: {
        ...products[accountId].billing,
        freemium: website.attributes?.freemium,
        trial: website.attributes?.trial,
        freeMat: website.attributes?.freeMatTrial,
        startedAsFreeMatTrial: website.attributes?.startedAsFreeMatTrial,
        startedAsTrial: website.attributes?.startedAsTrial,
        startedAsFreemium: website.attributes?.startedAsFreemium
      }
    };

    // identify DIFY attachment and primary products based on primary product type.
    // MWP principals + standalone DIFY: accountId will correspond to DIFY attachment.
    const accountIdIsDify = Object.values(CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS).some(category =>
      category.includes(product?.CES?.pfid)
    );
    let difyAttachmentId = null;
    let difyPrimaryProductId = null; // this is the product the dify product is attached to
    if (accountIdIsDify) {
      difyAttachmentId = accountId;
      difyPrimaryProductId = website?.properties?.associatedAccountId;
    } else {
      // W+M and DMS primary products: accountId corresponds to primary product. DIFY attachment in addons obj
      // in customer accounts the addons object is nested under data, but it is unclear if this is always true
      const addonIds = website.data?.addons ? Object.keys(website.data?.addons) : Object.keys(website?.addons || []);
      if (addonIds.length > 0) {
        const addonDifyId =
          addonIds.find(id =>
            Object.values(CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS).some(category => category.includes(products[id]?.CES?.pfid))
          ) || null;
        if (addonDifyId) {
          difyAttachmentId = addonDifyId;
          difyPrimaryProductId = accountId;
        }
      }
    }

    if (difyPrimaryProductId) {
      products[difyPrimaryProductId] = {
        ...products[difyPrimaryProductId],
        hasDifyAttached: true,
        attachedDifyPfid: products[difyAttachmentId]?.CES?.pfid
      };
    }
  });
}

function findMatchingShimSub(productId, shimSubscriptions) {
  const shimSub = shimSubscriptions.find(({ linkedEntitlements }) => {
    return linkedEntitlements.some(({ entitlementUri = '' }) => {
      return entitlementUri.includes(productId);
    });
  });
  return shimSub;
}

function amendProductDetails({ products, shimSubscriptions = [] }) {
  Object.entries(products)
    .filter(([, { type, NES }]) => type === productTypes.BCT || NES)
    .forEach(([productId, product]) => {
      const shimSub = findMatchingShimSub(productId, shimSubscriptions);
      if (!shimSub) return;

      const updatedProduct = { ...product };

      if (updatedProduct.type === productTypes.BCT) {
        // amends BCT Products
        updatedProduct.name = shimSub.name ?? '';
      }

      if (updatedProduct.NES) {
        // amends subscriptionId to NES subs
        updatedProduct.NES = {
          ...updatedProduct.NES,
          subscriptionId: shimSub.subscriptionId ?? ''
        };
      }

      products[productId] = updatedProduct;
    });
}

function findMatchingCommerceSubscription(productId, commerceSubscriptions) {
  return commerceSubscriptions.find(({ entitlementId }) => productId === entitlementId);
}

function amendWithCommerceSubscriptions({ products, commerceSubscriptions }) {
  Object.entries(products)
    .filter(([, product]) => isCommerceSaasProduct(product))
    .forEach(([productId, product]) => {
      const commerceSubscription = findMatchingCommerceSubscription(productId, commerceSubscriptions);
      if (!commerceSubscription) return;

      products[productId] = { ...product, commerceSubscription };
    });

  mapCommerceSaasRelationsToGDPayments(products);
}

function amendUPPEligibleProducts({ products, shimSubscriptions = [] }, config) {
  const hasUppFeatureFlag = config?.switchboard?.enableUPP;
  const shouldShowUPPReseller = config?.switchboard?.enableUPPResellers || !getIsReseller(config?.plid);
  if (hasUppFeatureFlag && shouldShowUPPReseller) {
    const uppEligibleProducts = identifyUPPEligibleNESProducts({
      shimSubscriptions,
      UPPEnabledProducts: config?.switchboard?.UPPEnabledProducts
    });
    // append isUPPEligible to ALL NPOs
    return mapIsUppEligibleToNPO(uppEligibleProducts, products, config?.switchboard?.UPPEnabledProducts);
  }
}

// This function will amend UPP eligibility to NES NPOs
function amendWithNesShimSub({ products, shimSubscriptions = [] }, config) {
  amendUPPEligibleProducts({ products, shimSubscriptions }, config);
  amendProductDetails({ products, shimSubscriptions });
}

module.exports = {
  default: {
    amendWithCESsubsApi,
    amendWithShimEntitlements,
    amendWithSslCredits,
    amendWithDomainEligibility,
    amendWithDCC,
    amendWithMWP,
    amendWithWebsiteData,
    amendWithNesShimSub,
    amendProductDetails,
    amendWithCommerceSubscriptions
  }
};
