/* eslint-disable no-unused-vars */
const { getExperiment } = require('../../../reducers/experiments');
const {
  getCommerceOrderedGroups,
  getEmailAndOfficeOrderedGroups,
  is123RegCustomer,
  isSecureServer,
  hasCommerceHomeStore,
  isCommerceSaasProduct,
  hasCommerceSubscription
} = require('./product_config_helpers');
const {
  getIsWAM,
  getIsDigitalMarketing,
  mwpHasCustomDomain,
  getIsVhSupportedMwp
} = require('../../../utilities/productsHelper');
const { makeURL } = require('./parsers/url_config_parser');

// CONSTANTS
const {
  openExchangeMarkets,
  primaryButtonTypes,
  productStatus,
  productTypes,
  secondaryComponents
} = require('./constants');
const PFIDs = require('./pfids');
const {
  optionsDisabledPfids,
  optionsDisabledTypes
} = require('../../../constants/optionsDisabledProducts');
const { buttonType, buttonIconTypes, BUTTON_ACTIONS } = require('../../../constants/button');
const {
  PRODUCT_NOT_SETUP,
  SPECIAL_STATUS,
  TITANTEST,
  EXPIRATION_DATE_STATUS: { NOW, SOON, PASSED, DISTANT }
} = require('../../../constants/app');
const { freemiumPfids } = require('../../../constants/freemiumPfids');

function redirectToExternalUrl(urlString, newWindow = false) {
  if (newWindow) {
    window.open(urlString, '_blank');
  } else {
    window.location.href = urlString;
  }

  return urlString;
}

const findExperiment = (experiments = [], name = '') => {
  return experiments.find(experiment => experiment.name === name);
};

const accordionConfig = [
  {
    id: 'domains',
    langTitle: 'groupTitle.domains',
    manageAllURL: 'dcc', // manageAllURL can not be a URL whose generation depends on a productObj
    manageAll123RegUrl: 'dccManage'
  },
  {
    id: 'websites',
    langTitle: 'groupTitle.websitesPlusMarketing'
  },
  {
    id: 'commerceAndPayments',
    langTitle: 'groupTitle.commerceAndPayments',
    customProductOrder: (productObjs) => getCommerceOrderedGroups(productObjs)
  },
  {
    id: 'partnerProducts',
    langTitle: 'groupTitle.partnerProducts'
  },
  {
    id: 'hostEurope',
    langTitle: 'groupTitle.hostEuropeProducts'
  },
  {
    id: 'webHosting',
    langTitle: 'groupTitle.webHosting',
    manageAllURL: 'hostHosting'
  },
  {
    id: 'managedWordpress',
    langTitle: 'groupTitle.managedWordpress',
    manageAllURL: 'hostHosting'
  },
  {
    id: 'mediaTemple',
    langTitle: 'mediaTemple'
  },
  {
    id: 'servers',
    langTitle: 'groupTitle.servers',
    manageAllURL: 'hostHosting'
  },
  {
    id: 'easyDatabase',
    langTitle: 'groupTitle.easyDatabase'
  },
  {
    id: 'cloudServers',
    langTitle: 'groupTitle.cloudServers'
  },
  {
    id: 'emailAndOffice',
    langTitle: 'groupTitle.office365',
    manageAllURL: 'productivity',
    customProductOrder: (productObjs) => {
      const productSubGroups = getEmailAndOfficeOrderedGroups(productObjs);
      // this is the ordering of products in the accordion we want
      return [
        {
          ids: productSubGroups.m365Unactivated,
          consolidated: {
            groupName: 'unactivatedM365Email',
            titleId: productSubGroups.m365Unactivated.length === 1 ? 'o365_UnusedMailboxes.singular' : 'o365_UnusedMailboxes',
            titleValues: { inactiveEmailCount: productSubGroups.m365Unactivated.length },
            subtitleId: 'productSetupSubTitle.setUpNewEmail',
            buttonType: buttonType.SETUP,
            buttonIconType: buttonIconTypes.ADD_ICON,
            buttonTextId: 'setupButton',
            buttonClickUrl: makeURL(
              'setupEmailAndOffice',
              // first m365Unactivated productObj
              productObjs.find(productObj => productObj.id === productSubGroups.m365Unactivated[0])
            )
          }
        },
        {
          ids: productSubGroups.oxUnactivated,
          consolidated: {
            groupName: 'unactivatedOxEmail',
            titleId: productSubGroups.oxUnactivated.length === 1 ? 'ox_UnusedMailboxes.singular' : 'ox_UnusedMailboxes',
            titleValues: { inactiveEmailCount: productSubGroups.oxUnactivated.length },
            subtitleId: 'productSetupSubTitle.setUpNewEmail',
            buttonType: buttonType.SETUP,
            buttonIconType: buttonIconTypes.ADD_ICON,
            buttonTextId: 'setupButton',
            buttonClickUrl: makeURL(
              'setupEmailAndOffice',
              // first oxUnactivated productObj
              productObjs.find(productObj => productObj.id === productSubGroups.oxUnactivated[0])
            )
          }
        },
        {
          ids: productSubGroups.emailForwardsPack,
          consolidated: {
            groupName: 'emailForwardsPack',
            titleId: 'emailForwarding',
            titleValues: { inactiveEmailCount: 0 },
            buttonType: buttonType.MANAGE,
            buttonTextId: 'manageButton',
            buttonClickUrl: makeURL(
              'productivityEmailForwards',
              // first emailForward productObj
              productObjs.find(productObj => productObj.id === productSubGroups.emailForwardsPack[0])
            )
          }
        },
        { ids: productSubGroups.others }
      ];
    }
  },
  {
    id: 'sslCerts',
    langTitle: 'groupTitle.ssl',
    manageAllURL: 'certs'
  },
  {
    id: 'businessAds',
    langTitle: 'groupTitle.businessAds'
  },
  {
    id: 'workspace',
    langTitle: 'groupTitle.workspaceEmail',
    manageAllURL: 'wccEmailPlan'
  },
  {
    id: 'websiteSecurityAndBackups',
    langTitle: 'groupTitle.websiteSecurityandBackups',
    manageAllURL: 'sec'
  },
  {
    id: 'digitalMarketing',
    langTitle: 'digitalMarketing'
  },
  {
    id: 'searchEngineVisibility',
    langTitle: 'groupTitle.searchEngineVisibility'
  },
  {
    id: 'expressEmailMarketing',
    langTitle: 'groupTitle.expressEmailMarketing'
  },
  {
    id: 'emailMarketing',
    langTitle: 'groupTitle.emailMarketing'
  },
  {
    id: 'domainProducts',
    langTitle: 'groupTitle.domainProducts'
  },
  {
    id: 'proWebServices',
    langTitle: 'groupTitle.proWebServices'
  },
  {
    id: 'contactManager',
    langTitle: 'groupTitle.crm'
  },
  {
    id: 'costcoBundle',
    langTitle: 'groupTitle.costco'
  },
  {
    id: 'gdITServices',
    langTitle: 'groupTitle.gdITServices'
  },
  {
    id: 'gdPro',
    langTitle: 'gdPro',
    manageAllURL: 'hubSites'
  },
  {
    id: 'goMobile',
    langTitle: 'groupTitle.gdGoMobile'
  },
  {
    id: 'msoftHostedExchange',
    langTitle: 'groupTitle.hostedExchange'
  },
  {
    id: 'onlineBookKeeping',
    langTitle: 'groupTitle.onlineBookkeeping'
  },
  {
    id: 'photoAlbum',
    langTitle: 'groupTitle.photoAlbum'
  },
  {
    id: 'premiumSupport',
    langTitle: 'groupTitle.premiumSupport'
  },
  {
    id: 'reseller',
    langTitle: 'groupTitle.reseller'
  },
  {
    id: 'conversations',
    langTitle: 'groupTitle.conversations'
  },
  {
    id: 'websiteProtection',
    langTitle: 'groupTitle.websiteProtection'
  },
  {
    id: 'managedWooCommerce',
    langTitle: 'groupTitle.managedWooCommerce'
  },
  {
    id: 'mediaSol',
    langTitle: 'groupTitle.mediaSol'
  },
  {
    id: 'paidSupport',
    langTitle: 'groupTitle.paidSupport'
  },
  {
    id: 'bundle',
    langTitle: 'groupTitle.bundles',
    hidden: true
  },
  {
    id: 'airoplus',
    langTitle: 'airoPlus'
  }
];

/* - supported button types:
  primaryAction
  primaryActionMobile (only used if defined and on mobile)
  renew
  dns
  domainActivatorBuildWebsite
  domainActivatorSetupEmail
  domainActivatorConnectSite
-*/

// note that all generation funcs will receive arguments: productObj, context
// where context is an object containing: isReseller, locale, productCount, customerType, isBiDi (bidirectional locale, RtL), plid, and featureFlags

/* - product config blob:
  {
    id: 'superb email forwarding or something', // id tells what this product config blob is
    criteria: productObj => productObj.type === productTypes.DOMAINS,
    isSetupSupported: true, // optional, helps determine if the product should be treated as activate-able or not
    accordion: 'domains', // must be one of the accordions defined above
    row: { // determines how the product displays in its row
      // primaryText and secondaryText should return literal strings
      // primaryKey and secondaryKey should return an object that contains a key and token values for the localizaton system
      // whichever you choose, the primary will become the title and the secondary will become the subtitle
      // (a secondary is not required)
      primaryText: productObj => productObj.detailedName,
      // this returns a string that corresponds to a react component. It is generally used to render custom subtitles
      secondaryComponent: return secondaryComponents.XXXXX
      secondaryKey: productObj => {
        return {
          // language strings can be found in /public/locales/en-US.json
          // refer to the readme.md to learn how to add your own language strings
          key: 'groupTitle.workspaceEmail',
          // token values are often not needed
          values: { 'planName': productObj.domain.protection }
        };
      }
      secondaryText: productObjs => productObj.detailedName
    },
    buttons: {
      // these allowed URL keys are shown above
      // these functions need only return a URL key as defined in the url_config.js
      primaryAction: productObj => 'domainManage',
      dns: productObj => 'dccDns',
      renew: productObj => {
        // only give a renewal link if the product is within or passed the renewal period
        if (productObj.billing.paidThroughDateStatus !== EXPIRATION_DATE_STATUS.DISTANT) return 'subscriptionPage';
        return null;
      },
      options: productObj => BUTTON_ACTIONS.MUIPOD // or any other modal we support
      // these functions may resolve null, in which case no button/link will be rendered
    }
  },
-*/

const defaultBehaviors = {
  getRenewalUrl: productObj => {
    if (!productObj.billing?.isRenewable) return null;
    if (productObj.billing.paidThroughDateStatus === DISTANT) return null;
    return 'subscriptionPage';
  },
  getButtonAction: (productObj, { isReseller, experiments, featureFlags }) => {
    if (!isReseller && productObj?.isUPPEligible) return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;
    if (isReseller && featureFlags.enableUPPResellers && productObj?.isUPPEligible) return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;
    if (!productObj.isHardBundled) return BUTTON_ACTIONS.MUIPOD;
    if (productObj.isHardBundled && !productObj.billing.thirdParty) return BUTTON_ACTIONS.PLANUPGRADE;
    if (!isReseller && productObj.websiteId) return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;
    return BUTTON_ACTIONS.GO_TO_LAUNCH_PAGE;
  }
};

const productConfig = [
  // the first in this list whose "criteria" func succeeds will categorize as such, so ORDER MATTERS
  // NO FIELD-GENERATION FUNCTION MAY USE productObj.calculated DATA, AS IT MAY BECOME CIRCULAR LOGIC
  {
    id: 'domains',
    criteria: productObj => [productTypes.DOMAINS, productTypes.DOMAIN, productTypes.LEASED_DOMAIN].includes(productObj.type),
    accordion: 'domains',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryComponent: productObj => {
        if (!productObj.isPending && productObj.type !== productTypes.LEASED_DOMAIN) return secondaryComponents.PRIVACY; // there's too much complex behavior here to do this in data
      },
      secondaryKey: productObj => {
        if (productObj.type === productTypes.LEASED_DOMAIN) {
          return { key: 'leasedDomain' };
        }
      }
    },
    buttons: {
      primaryAction: (productObj, { experiments, isReseller, plid, isEligibleForVentureHome }) => {
        if (productObj.isPending) {
          return { type: primaryButtonTypes.PENDING, isDisabled: true };
        }

        return {
          type: primaryButtonTypes.MANAGE,
          clickFunc: () => {
            const cPanelWebHostingExperiment = findExperiment(experiments, 'vh-cpanel-mvp');
            if (cPanelWebHostingExperiment?.inTreatment || isEligibleForVentureHome) {
              return redirectToExternalUrl(makeURL('ventureHome', productObj));
            }

            // determine if user is 123Reg customer by checking their plid
            if (is123RegCustomer(plid)) {
              return redirectToExternalUrl(makeURL('domainManage', productObj));
            }

            if (isReseller) {
              return redirectToExternalUrl(makeURL('domainResellerManage', productObj));
            }
            return redirectToExternalUrl(makeURL('domainManage', productObj));
          }
        };
      },
      primaryActionMobile: (productObj, { experiments, isReseller, plid, isEligibleForVentureHome }) => {
        if (productObj.isPending) {
          return { type: primaryButtonTypes.PENDING, isDisabled: true };
        }

        return {
          type: primaryButtonTypes.MANAGE,
          clickFunc: () => {
            const cPanelWebHostingExperiment = findExperiment(experiments, 'vh-cpanel-mvp');
            if (cPanelWebHostingExperiment?.inTreatment || isEligibleForVentureHome) {
              return redirectToExternalUrl(makeURL('ventureHome', productObj));
            }

            // determine if user is 123Reg customer by checking their plid
            if (is123RegCustomer(plid)) {
              return redirectToExternalUrl(makeURL('domainManage', productObj));
            }

            if (isReseller) {
              return redirectToExternalUrl(makeURL('domainResellerManage', productObj));
            }

            return redirectToExternalUrl(makeURL('domainManageMobile', productObj));
          }
        };
      },
      dns: productObj => {
        if (!productObj.isPending) return 'dccDns';
      },
      renew: (productObj, { experiments }) => {
        if (!productObj.billing?.isRenewable) return null;
        if (productObj.billing.paidThroughDateStatus === DISTANT) return null;
        return 'subscriptionPageDomain';
      },
      domainActivatorBuildWebsite: (productObj, { isReseller }) => {
        if (!isReseller) return 'onboardingLaunchDomain';
        return 'dccSiteBuilder';
      },
      domainActivatorSetupEmail: (productObj, { isReseller, locale }) => {
        if (!isReseller) return 'setupEmailGD';
        if (openExchangeMarkets.includes(locale)) return 'dccProfessionalEmail';
        return 'dccO365';
      },
      domainActivatorConnectSite: productObj => 'dccSetupForwarding'
    }
  },

  {
    id: 'gd studio',
    criteria: productObj => productObj.type === productTypes.GD_STUDIO || PFIDs.GD_STUDIO.includes(productObj.CES?.pfid),
    accordion: 'websites',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        let url = 'studioAccountSettings';

        if (productObj.websiteStatus === 'active') {
          url = 'launchToEditor';
        }

        return { url, type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },
  {
    id: 'online marketing',
    criteria: productObj => {
      return (
        productObj.type === productTypes.ONLINE_MARKETING ||
        productObj.type === productTypes.SOCIAL_VALUE ||
        PFIDs.ONLINE_MARKETING.includes(productObj.CES?.pfid) ||
        getIsDigitalMarketing(productObj)
      );
    },
    isSetupSupported: true,
    accordion: 'digitalMarketing',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.websiteBusinessName || productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        if (productObj.billing.paidThroughDateStatus === PASSED) return null;
        if (productObj.hasDifyAttached) return { url: 'marketingHubWebsite', type: primaryButtonTypes.MANAGE };
        // digital marketing suites with businessName defined are considered activated
        if (getIsDigitalMarketing(productObj) && productObj.websiteBusinessName) {
          return { url: 'launchToAccount', type: primaryButtonTypes.MANAGE };
        }
        if (!productObj.activated) return { url: 'launchToAccount', type: primaryButtonTypes.SETUP };
        return { url: 'launchToAccount', type: primaryButtonTypes.MANAGE };
      },
      renew: (productObj, { isReseller }) => {
        const { isRenewable, paidThroughDateStatus, specialStatus, freemium, trial } = productObj.billing || {};
        const isTrial = trial || specialStatus === SPECIAL_STATUS.TRIAL;
        const isExpiring = paidThroughDateStatus === SOON || paidThroughDateStatus === NOW;

        if (!isRenewable || paidThroughDateStatus === DISTANT || specialStatus === SPECIAL_STATUS.FREE) {
          return null;
        }

        if (!isReseller && (freemium || isTrial) && isExpiring) {
          return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;
        }

        return 'subscriptionPage';
      }
    }
  },
  {
    id: 'duda',
    criteria: productObj => productObj.type === productTypes.DUDA,
    isSetupSupported: true,
    accordion: 'partnerProducts',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: (productObj, { plid }) => {
        const isNewAccount = productObj.detailedName?.toLowerCase() === PRODUCT_NOT_SETUP.toLowerCase();
        if (is123RegCustomer(plid)) {
          return { url: 'websiteBuilderDManage', type: isNewAccount ? primaryButtonTypes.SETUP : primaryButtonTypes.MANAGE };
        }
        return { url: 'wsbaManage', type: isNewAccount ? primaryButtonTypes.SETUP : primaryButtonTypes.MANAGE };
      },
      renew: (productObj, { plid }) => {
        if (!productObj.billing?.isRenewable) return null;
        if (productObj.billing.paidThroughDateStatus === DISTANT) return null;
        if (is123RegCustomer(plid)) {
          return 'subscriptionPage';
        }
        return 'subscriptionPageRenewalDuda';
      }
    }
  },

  {
    id: 'dify attachments',
    criteria: productObj => Object.values(PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS).some(category => category.includes(productObj?.CES?.pfid)),
    accordion: 'websites',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        const { pfid = '' } = productObj?.CES || {};
        if (PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.MWP_BASIC.includes(pfid)) {
          if (productObj.activated) {
            return { url: 'websiteCare', type: primaryButtonTypes.MANAGE };
          }
          return { url: 'websiteCare', type: primaryButtonTypes.SETUP };
        }
        if (
          PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.MWP_STANDARD.includes(pfid) ||
          PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.MWP_PREMIUM.includes(pfid) ||
          PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.STANDALONE_STANDARD.includes(pfid) ||
          PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.STANDALONE_PREMIUM.includes(pfid)
        ) {
          if (productObj.activated) {
            return { url: 'marketingHubWebsite', type: primaryButtonTypes.MANAGE };
          }
          return { url: 'onboardingServices', type: primaryButtonTypes.SETUP };
        }
      },
      options: (productObj, { isReseller, featureFlags }) => {
        const { pfid = '' } = productObj?.CES || {};
        // only DIFY attachments that have an action button should have options
        if (PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.MWP_BASIC.includes(pfid) ||
          PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.MWP_STANDARD.includes(pfid) ||
          PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.MWP_PREMIUM.includes(pfid) ||
          PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.STANDALONE_STANDARD.includes(pfid) ||
          PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.STANDALONE_PREMIUM.includes(pfid)) {
          return defaultBehaviors.getButtonAction(productObj, { isReseller, featureFlags });
        }
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'photoshoot',
    criteria: productObj => productObj.type === productTypes.PHOTOSHOOT || PFIDs.PHOTOSHOOT.includes(productObj.CES?.pfid),
    accordion: 'digitalMarketing',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name,
      productIconSVG: productObj => 'photoShoot'
    },
    buttons: {
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'wordpress design services',
    criteria: productObj => {
      return (
        productObj.type === productTypes.WORDPRESS_DESIGN_SERVICES ||
        PFIDs.WORDPRESS_DESIGN_SERVICES.includes(productObj.CES?.pfid)
      );
    },
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'host', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'website design services',
    criteria: productObj =>
      productObj.type === productTypes.WEBSITE_DESIGN_SERVICES || PFIDs.WDS_STANDARD_STORE.includes(productObj.CES?.pfid),
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        let url = 'studio';
        if (productObj.CES?.pfid === '70761') {
          url = 'instant_page';
        } else if (productObj.CES?.pfid === PFIDs.INSTANT_PAGE_MONTHLY_FREEMIUM) {
          url = 'instantPage';
        } else if (PFIDs.WDS_STANDARD_STORE.includes(productObj.CES?.pfid)) {
          url = 'dashboardAccount';
        } else if (!!productObj?.attachedDifyPfid && productObj.activated) {
          // DIFY W+M site with DIFY marketing attached. DIY created website in diff config
          if (PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.WM_BASIC.includes(productObj.attachedDifyPfid)) {
            url = 'websiteCare';
          } else if (
            PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.WM_STANDARD.includes(productObj.attachedDifyPfid) ||
            PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.WM_PREMIUM.includes(productObj.attachedDifyPfid)
          ) {
            url = 'marketingHubWebsite';
          }
        }
        return { url, type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction
    }
  },

  {
    id: 'website builder',
    criteria: productObj => {
      return getIsWAM(productObj);
    },
    isSetupSupported: true,
    accordion: 'websites',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => {
        if (productObj.activated && !(productObj.type === productTypes.WEBSITES_AND_MARKETING && productObj.billing.thirdParty)) {
          return productObj.detailedName;
        }
      },
      secondaryComponent: productObj => {
        if (productObj.type === productTypes.WEBSITES_AND_MARKETING && productObj.billing.thirdParty) {
          return secondaryComponents.WAM_APPSTORE;
        }
      }
    },
    buttons: {
      primaryAction: (productObj, { isReseller, experiments }) => {
        let url = 'websiteBuilderDefault';
        if (productObj.CES?.pfid === '70761') {
          url = 'instantPageSsoMya';
        } else if (productObj.CES?.pfid === '819787') {
          url = 'instantPageLaunch';
        } else if (PFIDs.SHOWINBIO.includes(productObj.CES?.pfid)) {
          url = 'showInBio';
        } else if (!!productObj?.attachedDifyPfid && productObj.activated) {
          // DIY website with DIFY marketing attached. DIFY created website logic in diff config
          if (PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.WM_BASIC.includes(productObj.attachedDifyPfid)) {
            url = 'websiteCare';
          } else if (
            PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.WM_STANDARD.includes(productObj.attachedDifyPfid) ||
            PFIDs.CUSTOM_PFID.MS_DIFY_ATTACHMENT_PFIDS.WM_PREMIUM.includes(productObj.attachedDifyPfid)
          ) {
            url = 'marketingHubWebsite';
          }
        } else if (['536', '537'].includes(productObj.CES?.productTypeId)) {
          if (productObj.activated && !isReseller) {
            url = 'launchToAccountWithDomainWBITC';
          } else {
            url = 'launchToAccount';
          }
        } else if (productObj.type === productTypes.WEBSITES_AND_MARKETING) {
          url = 'launchToAccountWithDomainWMITC';
        }
        return {
          url,
          type:
            productObj.activated || productObj.status === productStatus.PENDING_PROVISION
              ? primaryButtonTypes.MANAGE
              : primaryButtonTypes.SETUP
        };
      },
      options: (productObj, { isReseller, experiments, featureFlags }) => {
        const { type: productType, CES, billing } = productObj;
        const { pfid, bundleName } = CES || {};
        const { specialStatus, paidThroughDateStatus, thirdParty, freemium, trial } = billing || {};

        const isAppStoreNESProduct = !CES && thirdParty;
        const isTrial = trial || specialStatus === SPECIAL_STATUS.TRIAL;
        const isExpired = paidThroughDateStatus === PASSED;
        const isFreemium = freemiumPfids.includes(pfid);

        if (!isReseller && (freemium || isTrial)) {
          return null;
        }

        if (optionsDisabledPfids.includes(pfid) || optionsDisabledTypes.includes(productType) || isAppStoreNESProduct) {
          return;
        }

        // CXAP-7224
        // let shoppers with a "Website Builder Free 1-Page" see the editPlan button
        // so they can cancel the product via MUI
        if (!PFIDs.INSTANT_PAGE_MONTHLY_FREEMIUM.includes(pfid)) {
          if (isTrial || isFreemium || isExpired) {
            return null;
          }
        }

        // if the W+M plan is part of a bundle, it cannot be independently upgraded
        if (bundleName) {
          return false;
        }

        return defaultBehaviors.getButtonAction(productObj, { isReseller, experiments, featureFlags });
      },
      renew: (productObj, { isReseller, experiments }) => {
        return () => {
          const { isRenewable, paidThroughDateStatus, specialStatus, freemium, trial } = productObj.billing || {};
          const isTrial = trial || specialStatus === SPECIAL_STATUS.TRIAL;

          if (!isRenewable || paidThroughDateStatus === DISTANT || specialStatus === SPECIAL_STATUS.FREE) {
            return null;
          }

          if (!isReseller && (freemium || isTrial)) {
            return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;
          }

          return 'subscriptionPage';
        };
      }
    }
  },
  {
    id: 'managed woocommerce',
    criteria: productObj => productObj.type === productTypes.WOO_COMMERCE,
    accordion: 'managedWooCommerce',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'mwcStoresEntitlement', type: primaryButtonTypes.MANAGE };
      },
      renew: productObj => {
        if (!productObj.billing?.isRenewable) return null;
        if (productObj.billing.paidThroughDateStatus === DISTANT) return null;
        return 'mwcStoresMySub';
      }
    }
  },

  {
    id: 'online store',
    // TODO: when the PFID hacks are removed, this PFID check will be unnecessary
    criteria: productObj => productObj.type === productTypes.ONLINE_STORE || PFIDs.STANDARD_OLS.includes(productObj.CES?.pfid),
    isSetupSupported: true,
    accordion: 'websites',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'onlineStoreInit', type: productObj.activated ? primaryButtonTypes.MANAGE : primaryButtonTypes.SETUP };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'quick shopping cart',
    criteria: productObj => productObj.type === productTypes.QUICK_SHOPPING_CART,
    accordion: 'websites',
    row: {
      primaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'qscLogin', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'godaddy payments hardwares',
    criteria: productObj => productObj.type === productTypes.OMNI_HARDWARE && !hasCommerceHomeStore(productObj),
    accordion: 'commerceAndPayments',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'inPersonPayments', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'godaddy payments',
    criteria: productObj => productObj.type === productTypes.OMNI_PAY,
    accordion: 'commerceAndPayments',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => hasCommerceHomeStore(productObj) ? '' : productObj.name
    },
    buttons: {
      primaryAction: (productObj, { featureFlags }) => {
        if (hasCommerceHomeStore(productObj)) {
          if (productObj.commerceHomeStoresCount === 1) {
            return null;
          }
          return { url: 'commerceHomeCompare', type: primaryButtonTypes.COMPARE_ALL };
        }
        return { url: 'businessPayments', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'partner products',
    criteria: productObj => productObj.type === productTypes.PARTNER_PRODUCTS,
    accordion: 'partnerProducts',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name,
      productIconSVG: productObj => 'websiteDesigner'
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'wsbaManage', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'kabbage',
    criteria: productObj => productObj.type === productTypes.KABBAGE,
    accordion: 'partnerProducts',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name,
      productIconSVG: productObj => 'kabbage'
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'kabbageManage', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'host europe',
    criteria: productObj => {
      return (
        productObj.type === productTypes.HOST_EUROPE ||
        PFIDs.HOST_EUROPE_DEDICATED.includes(productObj.CES?.pfid) ||
        PFIDs.HOST_EUROPE_VPS.includes(productObj.CES?.pfid) ||
        PFIDs.HOST_EUROPE_PANEL.includes(productObj.CES?.pfid)
      );
    },
    accordion: 'hostEurope',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        let url;
        if (PFIDs.HOST_EUROPE_DEDICATED.includes(productObj.CES?.pfid)) url = 'fedLoginDedi';
        if (PFIDs.HOST_EUROPE_VPS.includes(productObj.CES?.pfid)) url = 'fedLoginVps';
        if (PFIDs.HOST_EUROPE_PANEL.includes(productObj.CES?.pfid)) url = 'fedLoginPanel';
        return { url, type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'website security and backups',
    criteria: productObj => {
      return (
        productObj.type === productTypes.WEBSITE_SECURITY ||
        productObj.type === productTypes.WEBSITE_BACKUP ||
        PFIDs.WEBSITE_BACKUP.includes(productObj.CES?.pfid)
      );
    },
    isSetupSupported: true,
    accordion: 'websiteSecurityAndBackups',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => {
        const isNewAccount = productObj.detailedName?.toLowerCase() === PRODUCT_NOT_SETUP.toLowerCase();
        return isNewAccount ? '' : productObj.detailedName;
      }
    },
    buttons: {
      primaryAction: productObj => {
        if (productObj.activated) {
          return { url: 'secManage', type: primaryButtonTypes.MANAGE };
        }
        return { url: 'secSetup', type: primaryButtonTypes.SETUP };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'ssl certificates',
    criteria: productObj => productObj.type === productTypes.SSL_CERTS,
    isSetupSupported: true,
    accordion: 'sslCerts',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: (productObj, { plid }) => {
        const is123Reg = is123RegCustomer(plid);

        if (productObj.activated) {
          return { url: is123Reg ? 'sslManage' : 'secManage', type: primaryButtonTypes.MANAGE };
        }
        return { url: is123Reg ? 'sslSetup' : 'secSetup', type: primaryButtonTypes.SETUP };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'hardbundled certs',
    criteria: productObj => productObj.type === productTypes.SSL_CERTIFICATE,
    isSetupSupported: true,
    accordion: 'sslCerts',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        if (productObj.activated) {
          return { url: 'sslManage', type: primaryButtonTypes.MANAGE };
        }
        return { url: 'sslSetup', type: primaryButtonTypes.SETUP };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'msoft hosted exchange',
    criteria: productObj => productObj.type === productTypes.HOSTED_EXCHANGE,
    accordion: 'msoftHostedExchange',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'msoftHostedExchange', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'workspace email',
    criteria: productObj => productObj.type === productTypes.WORKSPACE_EMAIL,
    accordion: 'workspace',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'signInwbe', type: primaryButtonTypes.SIGNIN };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'calendar',
    criteria: productObj => productObj.type === productTypes.CALENDAR,
    accordion: 'workspace',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'signInogc', type: primaryButtonTypes.SIGNIN };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'online storage',
    criteria: productObj => productObj.type === productTypes.ONLINE_STORAGE,
    accordion: 'workspace',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'signInoff', type: primaryButtonTypes.SIGNIN };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'fax thru email',
    criteria: productObj => productObj.type === productTypes.FAX_THRU_EMAIL,
    accordion: 'workspace',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'faxCreate', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'godaddy it services',
    criteria: productObj =>
      productObj.type === productTypes.GODADDY_IT_SERVICES || PFIDs.GODADDY_IT_SERVICES.includes(productObj.CES?.pfid),
    accordion: 'gdITServices',
    row: {
      primaryText: productObj => productObj.name,
      secondaryKey: productObj => {
        return { key: 'goDaddyITServicesSupport' };
      }
    },
    buttons: {
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'email and office',
    criteria: productObj => {
      const value = [
        productTypes.EMAIL_AND_OFFICE,
        productTypes.BUSINESS_EMAIL,
        productTypes.PRODUCTIVITY,
        productTypes.MICROSOFT_EMAIL
      ].includes(productObj.type) &&
        !PFIDs.EMAIL_FORWARDS_PACK.includes(productObj.CES?.pfid);
      return value;
    },
    isSetupSupported: true,
    accordion: 'emailAndOffice',
    row: {
      primaryText: productObj => productObj.name,
      secondaryKey: productObj => {
        if (productObj.type === productTypes.PRODUCTIVITY) return { key: 'goDaddyITServicesSupport' };
      },
      secondaryText: productObj => {
        if (productObj.type !== productTypes.PRODUCTIVITY && !productObj.hasChildren) return productObj.detailedName;
      },
      secondaryComponent: productObj => {
        if (productObj.type === productTypes.EMAIL_AND_OFFICE && productObj.hasChildren) {
          return secondaryComponents.EMAIL_FIVE_PACK;
        }
      }
    },
    buttons: {
      primaryAction: (productObj, { isReseller }) => {
        if (!productObj.activated) {
          if (productObj.hasChildren) {
            return { url: 'setupEmailAndOffice', type: primaryButtonTypes.MANAGE };
          }
          if (isReseller) return { url: 'setupEmailAndOfficeReseller', type: primaryButtonTypes.SETUP };
          return { url: 'setupEmailAndOffice', type: primaryButtonTypes.SETUP };
        }
        return { url: 'signInO365', type: primaryButtonTypes.SIGNIN };
      },
      options: (productObj, { isReseller, experiments, featureFlags }) => {
        if (!isReseller) return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;
        return defaultBehaviors.getButtonAction(productObj, { isReseller, experiments, featureFlags });
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'open exchange',
    criteria: productObj => {
      return productObj.type === productTypes.OPEN_EXCHANGE || PFIDs.OPEN_XCHANGE.includes(productObj.CES?.pfid);
    },
    isSetupSupported: true,
    accordion: 'emailAndOffice',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        if (!productObj.activated) return { url: 'setupEmailAndOffice', type: primaryButtonTypes.SETUP };
        // Titan Experiment by vox@godaddy.com team - November 2023
        if (productObj.detailedName?.endsWith(TITANTEST)) return { url: 'signInTitan', type: primaryButtonTypes.SIGNIN };
        return { url: 'signInoxc', type: primaryButtonTypes.SIGNIN };
      },
      renew: defaultBehaviors.getRenewalUrl,
      options: (productObj, { isReseller, experiments, featureFlags }) =>  {
        if (!isReseller) return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;
        return defaultBehaviors.getButtonAction(productObj, { isReseller, experiments, featureFlags });

      }
    }
  },
  {
    id: 'emailforwards',
    criteria: productObj => {
      return productObj.type === productTypes.EMAIL_AND_OFFICE && PFIDs.EMAIL_FORWARDS_PACK.includes(productObj.CES?.pfid);
    },
    accordion: 'emailAndOffice',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'productivityEmailForwards', type: primaryButtonTypes.MANAGE };
      }
    }
  },
  {
    id: 'cloud servers',
    criteria: productObj => productObj.type === productTypes.CLOUD_SERVERS,
    accordion: 'cloudServers',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'cloud', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'easy database',
    criteria: productObj => productObj.type === productTypes.EASY_DATABASE,
    accordion: 'easyDatabase',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      // CONTROLPANELURLS GROUP 23: the only defined URL for this product is invalid
      // primaryAction: productObj => '',
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'servers',
    criteria: productObj => productObj.type === productTypes.SERVERS,
    accordion: 'servers',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        let url = 'myServersList';
        if (productObj.CES?.productTypeId === '98') url = 'dediHostingAccountList';
        if (productObj.CES?.productTypeId === '222') url = 'vDediHostingList';
        if (productObj.CES?.productTypeId === '559') url = 'vps4';
        return { url, type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'media temple email',
    criteria: productObj => productObj.type === productTypes.MEDIA_TEMPLE_EMAIL,
    accordion: 'mediaTemple',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'managementConsole', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'media temple aws',
    criteria: productObj => productObj.type === productTypes.MEDIA_TEMPLE_AWS,
    accordion: 'mediaTemple',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: () => null
    },
    buttons: {}
  },

  {
    id: 'media temple',
    criteria: productObj => productObj.type === productTypes.MEDIA_TEMPLE_HOSTING,
    accordion: 'mediaTemple',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'mediaTemple', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'managed wordpress',
    criteria: productObj => productObj.type === productTypes.WORDPRESS || productObj.type === productTypes.MANAGED_WORDPRESS,
    isSetupSupported: true,
    accordion: 'managedWordpress',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => {
        if (productObj.relations?.childrenIds?.length === 1) {
          return productObj.detailedName;
        }
      }
    },
    buttons: {
      primaryAction: (productObj, { products, isReseller }) => {
        if (!productObj.activated) {
          if (productObj.mwp?.siteId) {
            // we aren't using the setup url here because it requires a siteID, which we don't have
            return { url: 'mwpOnboarding', type: primaryButtonTypes.SETUP };
          }
          return { url: 'hostHosting', type: primaryButtonTypes.SETUP };
        }
        // host/hosting is the generic overview destination
        // hostMwpSite is the deep-linked path with the siteID included
        if (productObj.mwp?.siteId) {
          const isVhSupportedMwp = getIsVhSupportedMwp(productObj);
          const hasDomainAttached = mwpHasCustomDomain(productObj, products);

          if (!isReseller && isVhSupportedMwp) {
            if (hasDomainAttached) {
              return { url: 'ventureHomeAccountId', type: primaryButtonTypes.MANAGE };
            }
            return { url: 'ventureHomeWebsiteRollout', type: primaryButtonTypes.MANAGE };
          }

          return { url: 'hostMwpSite', type: primaryButtonTypes.MANAGE };
        }
        return { url: 'hostHosting', type: primaryButtonTypes.MANAGE };
      },
      options: productObj => {
        // upgrade/downgrade of NES products is not supported
        if (productObj.type === productTypes.MANAGED_WORDPRESS) return null;

        if (productObj.isUPPEligible) return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;

        if (!productObj.isChild) return BUTTON_ACTIONS.MUIPOD;
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'web hosting',
    criteria: productObj => productObj.type === productTypes.HOSTING,
    isSetupSupported: true,
    accordion: 'webHosting',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        let url = 'gatewayToAccount';
        if (productObj.CES?.productTypeId === '14') url = 'hostingLanding';
        if (productObj.CES?.productTypeId === '459') url = 'hostingCPanel';
        if (productObj.CES?.productTypeId === '461') url = 'hostingPlesk';
        return { url, type: productObj.activated ? primaryButtonTypes.MANAGE : primaryButtonTypes.SETUP };
      },
      options: (productObj, params) => {
        // Logic for A/B experiment on hosting UPP display
        const hostingExperiment = productObj.CES.__hostingExperiment;
        if (hostingExperiment && hostingExperiment.inExperiment) {
          if (hostingExperiment.isOnCohort) {
            return BUTTON_ACTIONS.PLANUPGRADE_UNIVERSAL;
          }

          return BUTTON_ACTIONS.MUIPOD;

        }

        return defaultBehaviors.getButtonAction(productObj, params);
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'web hosting cpanel_nes',
    criteria: productObj => productObj.type === productTypes.HOSTING_CPANEL,
    isSetupSupported: true,
    accordion: 'webHosting',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'hostingCPanel', type: productObj.activated ? primaryButtonTypes.MANAGE : primaryButtonTypes.SETUP };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'godaddy gomobile',
    criteria: productObj => productObj.type === productTypes.GD_GOMOBILE,
    accordion: 'goMobile',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'domainManageMobile', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'website protection',
    criteria: productObj => productObj.type === productTypes.WEBSITE_PROTECTION,
    accordion: 'websiteProtection',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'websiteProtection', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'costco bundle',
    criteria: productObj => productObj.type === productTypes.COSTCO_BUNDLE,
    accordion: 'costcoBundle',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      options: defaultBehaviors.getButtonAction
    }
  },

  {
    id: 'photo album',
    criteria: productObj => productObj.type === productTypes.PHOTO_ALBUM,
    accordion: 'photoAlbum',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'photoAlbumLaunch', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'contact manager',
    criteria: productObj => productObj.type === productTypes.CONTACT_MANAGER,
    accordion: 'contactManager',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      options: defaultBehaviors.getButtonAction
    }
  },

  {
    id: 'reseller',
    criteria: productObj => productObj.type === productTypes.RESELLER,
    accordion: 'reseller',
    row: {
      primaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'resellerDefault', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'online bookkeeping',
    criteria: productObj => productObj.type === productTypes.ONLINE_BOOK_KEEPING || productObj.type === productTypes.GET_PAID,
    accordion: 'onlineBookKeeping',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'bookkeepingLogin', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'search engine visibility',
    criteria: productObj => productObj.type === productTypes.SEARCH_ENGINE_VISIBILITY || productObj.type === productTypes.SEO,
    isSetupSupported: true,
    accordion: 'searchEngineVisibility',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        const isLegacy = PFIDs.SEARCH_ENGINE_VISIBILITY_LEGACY.includes(productObj.CES?.pfid);
        return {
          url: isLegacy ? 'sevNew' : 'sevManage',
          type: productObj.activated ? primaryButtonTypes.MANAGE : primaryButtonTypes.SETUP
        };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'get found',
    criteria: productObj => {
      return productObj.type === productTypes.GET_FOUND || PFIDs.GET_FOUND_DESIGN_SERVICES.includes(productObj.CES?.pfid);
    },
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'getFound', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'express email marketing',
    criteria: productObj => productObj.type === productTypes.EXPRESS_EMAIL_MARKETING,
    accordion: 'expressEmailMarketing',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'expressEmailMarketing', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'email marketing',
    criteria: productObj => productObj.type === productTypes.EMAIL_MARKETING,
    accordion: 'emailMarketing',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: (productObj, { isReseller }) => {
        if (isReseller) return { url: 'emailMarketingLoginReseller', type: primaryButtonTypes.MANAGE };
        return { url: 'emailMarketingLogin', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'godaddy business ads',
    criteria: productObj => productObj.type === productTypes.GD_BUSINESS_ADS,
    accordion: 'businessAds',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'adbuilder', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'trusted site',
    criteria: productObj => productObj.type === productTypes.TRUSTED_SITE,
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'trustedSite', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'premium dns',
    criteria: productObj => productObj.type === productTypes.PREMIUM_DNS,
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: (productObj, { plid }) => {
        const manageUrl = isSecureServer(plid) ? 'dccManageSecureServer' : 'dccManage';
        return { url: manageUrl, type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'cash parking',
    criteria: productObj => productObj.type === productTypes.CASH_PARKING,
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'cashParking', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'discount domain club',
    criteria: productObj => {
      return (
        productObj.type === productTypes.DOMAIN_DISCOUNT_CLUB ||
        (productObj.type === productTypes.MEMBERSHIP && !PFIDs.AUCTIONS.includes(productObj.CES?.pfid))
      );
    },
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return null;
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'domain appraisals',
    criteria: productObj => productObj.type === productTypes.DOMAIN_APPRAISALS,
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'domainAppraisals', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'domain backorder',
    criteria: productObj => productObj.type === productTypes.DOMAIN_BACKORDER,
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'domainBackordering', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'godaddy auctions',
    criteria: productObj =>
      productObj.type === productTypes.GD_AUCTIONS ||
      productObj.type === productTypes.AUCTIONS ||
      (productObj.type === productTypes.MEMBERSHIP && PFIDs.AUCTIONS.includes(productObj.CES?.pfid)),
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'auctionsSetting', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'brokerage',
    criteria: productObj => productObj.type === productTypes.BROKERAGE,
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'dbs', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'adult and global block',
    criteria: productObj => [productTypes.GLOBAL_BLOCK, productTypes.ADULT_BLOCK].includes(productObj.type),
    accordion: 'domainProducts',
    row: {
      primaryText: productObj => productObj.NES?.sld,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        if (productObj.billing.paidThroughDateStatus === DISTANT)
          return { url: 'dccBlocks', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'logo design services',
    criteria: productObj => productObj.type === productTypes.LOGO_DESIGN_SERVICES,
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'dream', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  // NOTE: per @zhulihan, dream web design and gateway are super old (and perhaps unused)
  {
    id: 'dream web design',
    criteria: productObj => {
      return productObj.type === productTypes.DREAM_WEB_DESIGNS;
    },
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'gatewaySetupOptions', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'seo services',
    criteria: productObj => productObj.type === productTypes.SEO_SERVICES,
    isSetupSupported: true,
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        if (productObj.activated) return { url: 'seoServicesSso', type: primaryButtonTypes.MANAGE };
        return { url: 'seoServicesSetup', type: primaryButtonTypes.SETUP };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'social media management',
    criteria: productObj => productObj.type === productTypes.SOCIAL_MEDIA_MANAGEMENT,
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'instant-page', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'managed listing services',
    criteria: productObj => productObj.type === productTypes.MANAGED_LISTING_SERVICES,
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'hostHosting', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'wsb makeover',
    criteria: productObj => productObj.type === productTypes.WSB_MAKEOVER || PFIDs.WSB_MAKEOVER.includes(productObj.CES?.pfid),
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'websiteBuilderDefault', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'website care',
    criteria: productObj =>
      productObj.type === productTypes.WEBSITE_CARE || productObj.type === productTypes.PROFESSIONAL_WEB_SERVICES,
    accordion: 'proWebServices',
    row: {
      primaryText: productObj => productObj.detailedName,
      secondaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'servicesPws', type: primaryButtonTypes.MANAGE };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'godaddy pro',
    criteria: productObj => productObj.type === productTypes.GODADDY_PRO || productObj.type === productTypes.PRO_PACKAGE,
    accordion: 'gdPro',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => {
        const isNewAccount = productObj.detailedName?.toLowerCase() === PRODUCT_NOT_SETUP.toLowerCase();
        return isNewAccount ? '' : productObj.detailedName;
      }
    },
    buttons: {
      primaryAction: productObj => {
        return { url: 'hubSites', type: primaryButtonTypes.MANAGE };
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'premium support',
    criteria: productObj => productObj.type === productTypes.PREMIUM_SUPPORT,
    isSetupSupported: true,
    accordion: 'premiumSupport',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName
    },
    buttons: {
      primaryAction: productObj => {
        if (productObj.activated) return { url: 'premiumSupport', type: primaryButtonTypes.MANAGE };
        return { url: 'premiumSupportSetup', type: primaryButtonTypes.SETUP };
      },
      options: defaultBehaviors.getButtonAction,
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'conversations',
    criteria: productObj => productObj.type === productTypes.TELEPHONY || productObj.type === productTypes.CONVERSATIONS,
    isSetupSupported: true,
    accordion: 'conversations',
    row: {
      primaryText: productObj => productObj.name,
      secondaryText: productObj => productObj.detailedName,
      secondaryComponent: productObj => {
        if (productObj.plan === 'appleConversations' || productObj.plan === 'googlePlayConversations') {
          return secondaryComponents.THIRDPARTY;
        }
      }
    },
    buttons: {
      primaryAction: productObj => {
        const isNewAccount = productObj.detailedName?.toLowerCase() === PRODUCT_NOT_SETUP;
        if (isNewAccount) {
          return { url: 'conversationsOverview', type: primaryButtonTypes.SETUP };
        }
        return {
          url: 'conversationsWebInbox',
          type: primaryButtonTypes.WEB_INBOX
        };
      },
      options: (productObj, { isReseller, featureFlags }) => {
        if (productObj.plan === productTypes.CONVERSATIONS_UNIFIEDINBOXONLY) {
          return null;
        }
        return defaultBehaviors.getButtonAction(productObj, { isReseller, featureFlags });
      },
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'mediasol',
    criteria: productObj => productObj.type === productTypes.MEDIASOL,
    isSetupSupported: false,
    accordion: 'mediaSol',
    row: {
      primaryText: productObj => 'GoDaddy Ad Manager' // always returns this english string
    },
    buttons: {
      primaryAction: productObj => ({
        url: 'mediaSolutions',
        type: primaryButtonTypes.MANAGE
      })
    }
  },

  {
    id: 'commerceHome',
    criteria: productObj => productObj.type === productTypes.COMMERCE_HOME,
    isSetupSupported: false,
    accordion: 'commerceAndPayments',
    row: {
      primaryText: productObj => productObj.name,
      secondaryKey: productObj => ''
    },
    buttons: {
      primaryAction: (productObj, { featureFlags }) => ({
        url: 'commerceHomeStore',
        type: primaryButtonTypes.MANAGE
      })
    }
  },
  {
    id: 'commerceSaas',
    criteria: productObj => isCommerceSaasProduct(productObj),
    accordion: 'commerceAndPayments',
    row: {
      primaryText: productObj => productObj.name,
      secondaryKey: productObj => {
        if (!productObj.isChild && !productObj?.commerceSubscription?.storeId) {
          if (hasCommerceSubscription(productObj)) {
            return { key: 'productSetupSubTitle.selectStore' };
          }
          return { key: 'productSetupSubTitle.missingSubscription', values: { href: '/products' } };
        }
      }
    },
    buttons: {
      primaryAction: (productObj, { featureFlags }) => {
        if (productObj?.commerceSubscription?.storeId) {
          return { url: 'commerceSaas', type: primaryButtonTypes.SEE_PLAN };
        }
        if (hasCommerceSubscription(productObj)) {
          return { url: 'commerceSaasBusiness', type: primaryButtonTypes.SETUP };
        }
        return null;
      },
      renew: (productObj) => {
        if (!hasCommerceSubscription(productObj)) return null;
        if (!productObj.isChild && !productObj?.commerceSubscription?.storeId) return null;
        return defaultBehaviors.getRenewalUrl(productObj);
      }
    }
  },

  {
    id: 'bundle',
    criteria: productObj => productObj.type === productTypes.BUNDLE,
    isSetupSupported: false,
    accordion: 'bundle',
    row: {
      primaryText: () => '',
      secondaryKey: () => ''
    },
    buttons: {
      // this category does not go into an displayable accordion
      primaryAction: productObj => ({}),
      renew: defaultBehaviors.getRenewalUrl
    }
  },

  {
    id: 'support products',
    criteria: productObj => {
      return productTypes.BCT === productObj.type;
    },
    isSetupSupported: false,
    accordion: 'paidSupport',
    row: {
      primaryText: productObj => productObj.name,
      secondaryComponent: () => secondaryComponents.PAID_SUPPORT
    },
    buttons: {
      // this category does not have any buttons
      primaryAction: productObj => null
    }
  },
  {
    id: 'airoplus',
    criteria: productObj => (
      productObj.type === productTypes.AIROPLUS || productObj.type === productTypes.DO_IT_YOURSELF_SOFTWARE
    ),
    isSetupSupported: false,
    accordion: 'airoplus',
    row: {
      primaryText: productObj => productObj.name
    },
    buttons: {
      primaryAction: (productObj, { featureFlags }) => ({
        url: 'airoManage',
        type: primaryButtonTypes.MANAGE
      })
    }
  }
];

module.exports = {
  defaultBehaviors,
  accordionConfig,
  productConfig,
  redirectToExternalUrl
};
