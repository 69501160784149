const { PAYMENTMETHODS_LOADING, PAYMENTMETHODS_LOADED, PAYMENTMETHODS_LOAD_FAILED } = require('../constants/action-types');

const initialPaymentMethodsState = {
  arePaymentMethodsLoading: true,
  paymentMethodsError: false,
  paymentMethods: []
};

const paymentMethods = (state = initialPaymentMethodsState, action = {}) => {
  switch (action.type) {
    case PAYMENTMETHODS_LOADING:
      return { ...state, arePaymentMethodsLoading: true };
    case PAYMENTMETHODS_LOADED:
      return { ...state, arePaymentMethodsLoading: false, paymentMethods: action.payload.data };
    case PAYMENTMETHODS_LOAD_FAILED:
      return { ...state, arePaymentMethodsLoading: false, paymentMethodsError: true };
    default:
      return state;
  }
};

module.exports = {
  default: paymentMethods
};
