/* -
  EXTERNAL VARS:
  businessID: the business id used for GD payments
  customerID: the customer id (the new UUID type)
  imgDomain: domain for img1 CDN
  locale: the location or 'market', like en-US, de-DE, etc
  privateLabelID: the plid, usually not needed
  productDomain: the domain name associated with the product
  productID: product id, typically external ID or orion ID
  productSiteID: myh/myp/mwp site ID associated with the product
  resourceID: the CES resource id
  shopperID: the shopper id (the old numerical type)
  siteDomain: environment, like test-godaddy.com
  productTypeID: the CES productTypeID

  NOTES:
  pl_id and prog_id are automatically passed through on the URL
  there is no need to write them in URLs here
-*/

const hosts = {
  account: 'account.[siteDomain]',
  activate: 'activate.[siteDomain]',
  adBuilder: 'adbuilder.[siteDomain]',
  auctions: 'auctions.[siteDomain]',
  bookkeeping: 'bookkeeping.[siteDomain]',
  cart: 'cart.[siteDomain]',
  certs: 'certs.[siteDomain]',
  cloud: 'cloud.[siteDomain]',
  commerce: 'spa.commerce.[siteDomain]',
  conversations: 'conversations.[siteDomain]',
  crm: 'cust.marketing.[siteDomain]',
  dashboard: 'dashboard.[siteDomain]',
  dbs: 'dbs.[siteDomain]',
  dcc: 'dcc.[siteDomain]',
  dediHostingManager: 'dedicatedhostingmanager.[siteDomain]',
  dream: 'dream.[siteDomain]',
  email: 'email.[siteDomain]',
  emailMarketing: 'emailmarketing.[siteDomain]',
  fax: 'fax.[siteDomain]',
  fos: 'www.[siteDomain]',
  gateway: 'gateway.[siteDomain]',
  gem: 'gem.[siteDomain]',
  host: 'host.[siteDomain]',
  hostingManager: 'hostingmanager.[siteDomain]',
  hub: 'hub.[siteDomain]',
  hxcc: 'hxcc.[siteDomain]',
  img1: 'img1.[imgDomain]',
  instant_page: 'instant-page.[siteDomain]', // why does this exist
  instantPage: 'instantpage.[siteDomain]', // when this exists
  kabbage: 'kabbage.[siteDomain]',
  launch: 'launch.[siteDomain]',
  locu: 'locu.[siteDomain]',
  login: 'login.[siteDomain]',
  marketingHub: 'marketing.hub.[siteDomain]',
  mcc: 'mcc.[siteDomain]',
  mtMail: 'mtmail.godaddy.com',
  mui: 'mui.[siteDomain]',
  mwcStores: 'mwcstores.[siteDomain]',
  mya: 'mya.[siteDomain]',
  myServers: 'myservers.[siteDomain]',
  nocfox: 'nocfox.[siteDomain]',
  onboarding: 'onboarding.[siteDomain]',
  onlineStore: 'onlinestore.[siteDomain]',
  payments: 'payments.[siteDomain]',
  photoAlbum: 'photoalbum.[siteDomain]',
  pro: 'pro.[siteDomain]',
  productivity: 'productivity.[siteDomain]',
  qsc: 'qsc.[siteDomain]',
  reseller: 'reseller.[siteDomain]',
  sec: 'sec.[siteDomain]',
  seoServices: 'seoservices.[siteDomain]',
  servicesPws: 'services.pws.[siteDomain]',
  sev: 'searchenginevisibility.[siteDomain]',
  smartline: 'smartline.[siteDomain]',
  sso: 'sso.[siteDomain]',
  start: 'start.[siteDomain]',
  studio: 'studio.[siteDomain]',
  surveysMarketing: 'surveys.marketing.[siteDomain]',
  vDediHostingManager: 'vdedicatedhostingmanager.[siteDomain]',
  visMarketing: 'visibility.marketing.[siteDomain]',
  wcc: 'wcc.[siteDomain]',
  websiteBuilder: 'websitebuilder.[siteDomain]',
  websiteBuilderD: 'websitebuilderd.[reg123Domain]',
  wsba: 'wsba.[siteDomain]',
  wsbd: 'wsbd.[siteDomain]'
};

const urls = {
  ...hosts,
  airoManage: '[start]/?itc=myp_airo_plus',
  appStoreLearnHow: '[siteDomain]/help/a-40150',
  auctionsSetting: '[auctions]/beta/settings',
  bookkeepingLogin: '[bookkeeping]/godaddy/login?accountuid=[productID]',
  buildWebsite: '[activate]/website?domainName=[productDomain]&itc=mya_domain_manager_website_cart',
  businessPayments: '[payments]?businessId=[businessID]',
  cashParking: '[mcc]?account_uid=[productID]&common_name=[productDomain]',
  classicMya: '[mya]/?myp_disable=true',
  classicMyaDomainDefaultsPath: `/settings.aspx?settingstab=buyerprofile`,
  classicMyaPayeesPath: `/settings.aspx?settingstab=payee&showpayee=auctions`,
  commerceHomeCompare: '[commerce]/home/compare?businessId=[businessID]&compare=true',
  commerceHomeStore: '[commerce]/home/store?storeId=[storeID]',
  commerceSaas: '[commerce]/payment-tools/manage-subscriptions/[commerceSubscriptionID]?entitlementId=[productID]&businessId=[businessID]&storeId=[storeID]',
  commerceSaasBusiness: '[commerce]/store-assignment?businessId=[businessID]&subscriptionId=[commerceSubscriptionID]&entitlementId=[productID]',
  conversationsAppleManage: `apps.apple.com/account/subscriptions`,
  conversationsGoogleManage: `play.google.com/store/account/subscriptions`,
  conversationsOverview: `[conversations]/overview/[productID]`,
  conversationsWebInbox: `[conversations]/conversations/[productID]`,
  crmCustomers: '[crm]/customers.aspx?account_uid=[productID]&common_name=[productDomain]',
  dashboardAccount: '[dashboard]/account/[productID]',
  dccBlocks: '[dcc]/control/blocks',
  dccDns: '[dcc]/control/dnsmanagement?domainName=[productDomain]',
  dccFabricApi: '/dccfabric/v1/customers/[customerID]/domains/get',
  dccManage: '[dcc]/control/portfolio',
  dccManageSecureServer: '[dcc]/manage',
  dccO365: '[dcc]/manage/[productDomain]/setup/email/o365?itc=mya_domain_manager_email_cart',
  dccProfessionalEmail: '[dcc]/manage/[productDomain]/setup/email/professional?itc=mya_domain_manager_email_cart',
  dccSetupForwarding: '[dcc]/control/connectdomain/[productDomain]/connect?itc=mcx_mya_products',
  dccSiteBuilder: '[dcc]/manage/[siteDomain]/setup/sitebuilder?itc=mya_domain_manager_website_cart',
  dediHostingAccountList: '[dediHostingManager]/accountlist.aspx?account_uid=[productID]&common_name=[productDomain]',
  domainAppraisals: '[dcc]/Domains/ManageAppraisals.aspx',
  domainAvailCheck: '[fos]/domainsearch/find?checkAvail=1',
  domainAvailCheckReseller: '[fos]/products/domain-registration/find',
  domainBackordering: '[dcc]/default.aspx?activeview=monitoring',
  domainManage: '[dcc]/control/portfolio/[productDomain]/settings?atc=mya',
  domainManageMobile: '[dcc]/domains/domaindetails/[productDomain]/',
  domainResellerManage: '[dcc]/control/[productDomain]/settings?atc=mya',
  emailMarketingLogin: '[gem]/logins?target_uid=[productID]',
  emailMarketingLoginReseller: '[emailMarketing]/logins?target_uid=[productID]',
  expressEmailMarketing: 'app.expressemailmarketing.com?account_uid=[productID]',
  faxCreate: '[fax]/create/newuser?accountUid=[productID]&common_name=[productDomain]',
  fedLoginDedi: '[sso]/fedlogin?path=/panel/products/dedicated',
  fedLoginPanel: '[sso]/fedlogin?path=/panel',
  fedLoginVps: '[sso]/fedlogin?path=/panel/products/vps',
  freeCreditsApi: '/products/api/free-credit',
  gatewayCustomers: '/gateway/v2/customers',
  gatewaySetupOptions: '[gateway]/account/setup_options?regionsite=www&account_type=wordpress&accountuid=[productID]&plid=[privateLabelID]&marketid=[locale]',
  gatewayToAccount: '[gateway]?accountuid=[productID]',
  getFound: '[locu]/godaddy/mya/launch?orion_id=[productID]',
  googlePlay: 'play.google.com/store/account',
  grayDomainIcon: '[img1]/myaccount/images/1/grayDomainIcon.svg',
  help: '[fos]/help',
  hostHosting: '[host]/hosting',
  hostingCPanel: '[host]/webhosting/cpanel/account/[productID]/view',
  hostingLanding: '[hostingManager]/LandingRedirect.aspx?account_Uid=[productID]&common_name=[productDomain]',
  hostingPlesk: '[host]/plesk/account/[productID]/view',
  hostMwpSite: '[host]/mwp/site/[productSiteID]/overview',
  hostMwpSiteEdit: '[host]/mwp/site/[productSiteID]/sso?path=%2Fwp-admin&type=wp',
  hubSites: '[hub]/sites/maintenance-packs?tab=manage',
  inPersonPayments: '[payments]/in-person/devices?businessId=[businessID]',
  instantPageLaunch: '[instantPage]/[locale]/launch?account_uid=[productID]&common_name=[productDomain]',
  instantPageSsoMya: '[instant_page]/sso/mya?account_uid=[productID]&common_name=[productDomain]',
  itunes: 'buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/DirectAction/manageSubscriptions',
  kabbageManage: '[kabbage]/manage/[productID]?refid=prt_godaddy_myproducts',
  launchToAccount: '[launch]/?account_uid=[productID]&itc=mya_myproducts_wam_accordion',
  launchToAccountPlans: '[launch]/?account_uid=[productID]&path=plans',
  launchToAccountWithDomainWBITC: '[launch]/?account_uid=[productID]&domainName=[productDomain]&itc=mya_myproducts_manage_websitebuilder',
  launchToAccountWithDomainWMITC: '[launch]/?account_uid=[productID]&domainName=[productDomain]&itc=mya_myproducts_manage_websiteandmarketing',
  launchToEditor: '[launch]/?account_uid=[productID]&path=edit',
  managementConsole: '[mtMail]/services/manage/email/index.mt?id=[managementConsoleID]',
  marketingHubWebsite: '[marketingHub]/website/[websiteID]',
  mediaSolutions: '[fos]/admanager/campaigns',
  mediaTemple: 'mediatemple.net',
  msoftHostedExchange: '[hxcc]/home.aspx?s_id=[shopperID]&a_id=[productID]',
  muiClientUrl: '[img1]/mui/scripts/muiClient_20130502.js',
  mwcStoresEntitlement: '[mwcStores]/overview?entitlementId=[productID]',
  mwcStoresMySub: '[mwcStores]/my-subscription?entitlementId=[productID]',
  mwp: '[host]/mwp/site/[productSiteID]',
  mwpApi: '/mwp-api/api/v1/mwp/sites',
  mwpOnboarding: 'setup.[siteDomain]/[productSiteID]',
  myhHosting: '[host]',
  myServersList: '[myServers]/list?uid=[productID]',
  onboardingLaunchDomain: '[onboarding]/launch?domainName=[productDomain]&itc=mya_domain_manager_website_cart',
  onboardingServices: 'onboarding.services.[siteDomain]/[productID]',
  onlinestorage: '[wcc]/files?cmd=offplans',
  onlineStoreInit: '[onlineStore]/sso/init?accountUid=[productID]',
  paymentApi: '/payapi/v1/paymentprofiles',
  photoAlbumLaunch: '[photoAlbum]/launch.aspx?account_uid=[productID]&common_name=[productDomain]',
  platapiDomains: '/platapi/v1/domains',
  premiumSupport: '[host]/wpps/subscription/[productID]',
  premiumSupportSetup: '[host]/wpps/setup/[productID]',
  proClients: '[pro]/launch/clients',
  productIconBase: '[img1]/fos/react/icons/92/gd/sprite.svg',
  productivityEmailForwards: '[productivity]/forwards',
  proManageWP: '[pro]/launch/managewp',
  proMarketplaceApi: '/pro-connect-api/shoppers/[customerID]/status',
  proRewards: '[pro]/launch/rewards',
  qscLogin: '[qsc]/ssoInitLogin.hg?accountUid=[productID]&common_name=[productDomain]',
  reactCDN: '[img1]/react/15.3.1/react-with-reactdom.min.js',
  redeemFreeCreditsApi: '/products/api/free-credit/redeem',
  redirectProductivityOptions: '[productivity]?orion_id=[productID]',
  resellerDefault: '[reseller]/?privatelableid=[privateLabelID]',
  secManage: '[sec]/manage?sid=[resourceID]:[productTypeID]',
  secSetup: '[sec]/setup?sid=[resourceID]:[productTypeID]',
  seoServicesSetup: '[seoServices]/setup/[productID]',
  seoServicesSso: '[seoServices]/sso/[productID]',
  setupDomain: '[activate]?domainName=[productDomain]',
  setupEmail: '[activate]/email?domainName=[productDomain]&itc=mya_domain_manager_email_cart',
  setupEmailAndOffice: '[productivity]/addnewuser?orion_id=[productID]',
  setupEmailAndOfficeReseller: '[productivity]/addnewuser?orion_id=[productID]&plid=[privateLabelID]',
  setupEmailGD: '[fos]/next-steps/email?domainName=[productDomain]&itc=mya_domain_manager_email_cart',
  sevManage: '[sev]?shopper_id=[shopperID]&plid=[privateLabelID]&account_uid=[productID]&marketid=[locale]',
  sevNew: '[visMarketing]?userwebsite_id=new&recurring_id=[resourceID]&ShopperID=[shopperID]&privatelabel_id=[privateLabelID]',
  shoppingCartImage: '[img1]/myaccount/images/1/shopCart.svg',
  showInBio: '[showinbioDomain]/editor',
  signInfte: '[login]/?app=fte',
  signInO365: '[sso]/?realm=pass&app=o365',
  signInoff: '[login]/?app=off',
  signInogc: '[login]/?app=ogc',
  signInoxc: '[sso]/?realm=pass&app=email',
  signInsso: '[sso]/?app=account&path=products',
  signInTitan: 'godaddy.titan.email/mail',
  signInwbe: '[email]/?app=wbe',
  siteBuilder: '[dcc]/manage/[productDomain]/setup/sitebuilder?itc=mya_domain_manager_website_cart',
  smartlineMya: '[smartline]?account_uid=[productID]&source=mya_setup',
  sslManage: '[certs]/cert/product?id=[productID]',
  sslSetup: '[certs]/wizard/create/DV_SSL/1/0',
  ssoFederarePartnerPath: '[sso]/federate/partner?path=/accounts',
  ssoProfile: '[sso]/profile',
  studioAccountSettings: '[studio]/account/settings',
  subscriptionApi: '/platapi/v1/subscriptions',
  subscriptionPage: '[account]/subscriptions?itc=gx_products_renew&rid=[resourceID]',
  subscriptionPageDomain: '[account]/subscriptions?itc=gx_products_renew&domain=[productDomain]',
  subscriptionPageRenewalDuda: '[wsba]/plans-and-pricing?eid=[productID]',
  sucuriErrorFallback: 'sucuri.net',
  surveys: '[surveysMarketing]/surveys.aspx?account_uid=[productID]&common_name=[productDomain]',
  telephonyDefault: '[smartline]/findyournumber?account_uid=[productID]',
  trustedSite: '[dcc]/manage/[productDomain]/settings/trustedSite',
  vDediHostingList: '[vDediHostingManager]/accountlist.aspx?account_uid=[productID]&common_name=[productDomain]',
  ventureHome: '[dashboard]/venture?domainName=[productDomain]',
  ventureHomeAccountId: '[dashboard]/venture?accountId=[productID]',
  ventureHomeTermSwitcher: '[dashboard]/venture/upgrade/term?accountId=[productID]',
  ventureHomeWebsiteRollout: '[dashboard]/venture/website?accountId=[productID]',
  vps4: '[host]/vps/servers/[productID]/dashboard',
  wccCalendar: '[wcc]/calendar?cmd=ogcplans',
  wccEmail: '[wcc]/email?cmd=planlistemail&erid=[productID]&locale=[locale]',
  wccEmailPlan: '[wcc]/email?cmd=planlistplan&selectedplan=all&override=1',
  wccFowarding: '[wcc]/email?cmd=planlistforward&erid=[productID]&locale=[locale]',
  websiteApi: '/websitesapi/v2/composite/website-list',
  websiteBuilderDefault: '[websiteBuilder]/launch.aspx?account_uid=[productID]&common_name=[productDomain]',
  websiteBuilderDManage: '[websiteBuilderD]/manage/[productID]',
  websiteCare: 'websitecare.[siteDomain]/website/[websiteID]?ua_placement=shared_header',
  websiteProtection: 'app.websiteprotection.com/mya?account_uid=[productID]&common_name=[productDomain]',
  wsbaManage: '[wsba]/manage/[productID]',
  wsbdManage: '[wsbd]/manage/[productID]'
};

module.exports = {
  urlConfig: urls,
  hostConfigs: hosts
};
