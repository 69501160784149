const PROJECT_GROUPS = require('@venture/constants/build/project-groups');

const MAX_RENDERABLE_VENTURES = 5;

const START_DAY_TO_SHOW_BASKET = 30; // Days before expiration when we should start showing the modal
const LAST_DAY_TO_SHOW_BASKET = -10; // Days after expiration when we should stop showing the modal

const DIGITAL_MARKETING = 'digitalMarketing';

const DOMAIN_ACTIVATION_STATUS = {
  DISCONNECTED: 'DISCONNECTED',
  CONNECTED: 'CONNECTED',
  CONNECTED_DCC: 'CONNECTED_DCC',
  EXTERNALLY_CONNECTED: 'EXTERNALLY_CONNECTED',
  INTERNALLY_CONNECTED_MWP: 'INTERNALLY_CONNECTED_MANAGED_WORDPRESS',
  INTERNALLY_CONNECTED_WEBSITE: 'INTERNALLY_CONNECTED_WEBSITE',
  CPANEL_CONNECTED: 'CPANEL_CONNECTED',
  UNKNOWN: 'UNKNOWN'
};

const PROJECT_TYPES = {
  WEBSITE_TYPE: 'website',
  CONVERSATIONS_TYPE: 'conversations',
  PAYMENTS: 'payments',
  EMAIL: 'email',
  // A local venture "project" type that is not yet supported by Product Graph. We inject a
  // fake webHosting project into the venture when we find connected Web Hosting projects via
  // the Hosting API.
  WEB_HOSTING_TYPE: 'webHosting',
  UNINITIALIZED: 'uninitialized',
  AIROPLUS: 'airoplus'
};

const OFFER_PLANS = {
  LOGO_IMAGE: 'logoImage'
};

const HOSTING_PROVIDERS = {
  GODADDY: 'GODADDY',
  UNKNOWN: 'UNKNOWN', // means there exists an A record with an ip address, but the ip is not owned by GoDaddy
  null: 'null', // there are no A records,
  NOT_FOUND: 'NOT_FOUND'
};

const CORE_PROJECT_GROUPS = [
  PROJECT_GROUPS.DOMAIN,
  PROJECT_GROUPS.WORDPRESS,
  PROJECT_GROUPS.WEBSITES_AND_MARKETING
];

module.exports.DIGITAL_MARKETING = DIGITAL_MARKETING;
module.exports.MAX_RENDERABLE_VENTURES = MAX_RENDERABLE_VENTURES;
module.exports.OFFER_PLANS = OFFER_PLANS;
module.exports.DOMAIN_ACTIVATION_STATUS = DOMAIN_ACTIVATION_STATUS;
module.exports.HOSTING_PROVIDERS = HOSTING_PROVIDERS;
module.exports.PROJECT_TYPES = PROJECT_TYPES;
module.exports.START_DAY_TO_SHOW_BASKET = START_DAY_TO_SHOW_BASKET;
module.exports.LAST_DAY_TO_SHOW_BASKET = LAST_DAY_TO_SHOW_BASKET;
module.exports.CORE_PROJECT_GROUPS = CORE_PROJECT_GROUPS;
