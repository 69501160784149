const { createSelector } = require('reselect');
const telemetry = require('../utilities/telemetry');
const { LOADING_EXPERIMENTS } = require('../constants/action-types');

const experiments = (state = [], action) => {
  switch (action.type) {
    case LOADING_EXPERIMENTS: {
      if (!action?.experiments?.reduce) return state; // defaulting for safety
      return action.experiments.reduce((acc, experiment) => {
        acc[experiment.name] = experiment;
        return acc;
      }, {});
    }
    default: {
      return state;
    }
  }
};

const getExperiment = createSelector([state => state.experiments || {}, (_, props) => props.experimentName], (experiments, experimentName) => {
  return experiments[experimentName];
});

const getExperiments = state => state.normalizedProducts.experiments;

function fireTriggerAndCohortEvents(experimentName, experimentGroup) {
  const inTreatment = experimentGroup === 'on';
  const inControl = experimentGroup === 'off';
  if (inTreatment || inControl) {
    telemetry.logExperimentTriggerEvent(experimentName);
    if (inTreatment) {
      telemetry.logExperimentCohort(experimentName, 'on');
    } else if (inControl) {
      telemetry.logExperimentCohort(experimentName, 'off');
    }
  }
}

module.exports = {
  default: experiments,
  getExperiment,
  getExperiments,
  fireTriggerAndCohortEvents
};
