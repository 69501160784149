const { MUIPOD_LOADED, MUIPOD_LOADING, FAILED_MUIPOD_LOAD } = require('../constants/action-types');

const muipod = (state = { isLoading: true, hasError: false }, action) => {
  switch (action.type) {
    case MUIPOD_LOADING:
      return { ...state, isLoading: true, hasError: false };
    case MUIPOD_LOADED:
      return { ...state, isLoading: false, hasError: false };
    case FAILED_MUIPOD_LOAD:
      return { ...state, isLoading: false, hasError: true };
    default:
      return state;
  }
};

const getIsMuiPodLoading = state => state.muipod.isLoading;

const getMuiPodHasError = state => state.muipod.hasError;

module.exports = {
  default: muipod,
  getIsMuiPodLoading,
  getMuiPodHasError
};
