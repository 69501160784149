const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };

const apiDesc = {
  getCustomerType: {
    url: '/customertypeapi/v1/get-by-shopper-id/:shopperId',
    method: 'GET',
    resourceData: {},
    dataTransform: data => data && data.result,
    options: {
      headers: {
        'Accept': 'application/json',
        'User-Agent': 'myProducts'
      }
    }
  }
};

const api = new Reduxful('customerTypeApi', apiDesc, apiConfig);

module.exports = api;
