module.exports.getFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = 1 + date.getMonth();
  const day = date.getDate();

  return month + '/' + day + '/' + year;
};

const createDate = (daysInFuture = 0) => {
  const date = new Date();
  date.setUTCDate(date.getUTCDate() + daysInFuture);
  date.setUTCHours(7, 0, 0, 0); // Ensure we are on AZ time
  return date;
};
module.exports.createDate = createDate;


/**
 * @param {Date} date1 Date 1
 * @param {Date} date2 Date 2
 * @returns {Number} the result in days of date1 - date2
 */
module.exports.getDaysBetween = (date1, date2) => {
  if (!(date1 instanceof Date)) {
    throw new Error('First argument should be an instance of Date');
  }

  if (!(date2 instanceof Date)) {
    throw new Error('Second argument should be an instance of Date');
  }

  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const timeDifference = date1.getTime() - date2.getTime();
  const sign = Math.sign(timeDifference);

  const daysBetween = Math.ceil(Math.abs(timeDifference) / millisecondsInADay);
  return daysBetween * sign;
};

const normalizeDate = (date) => {
  return date ? new Date(date) : new Date(0);
};
module.exports.normalizeDate = normalizeDate;


// Common testing dates.
// Less common dates can be generated directly in file using createDate function
module.exports.today = createDate(0);
module.exports.tomorrow = createDate(1);
module.exports.yesterday = createDate(-1);
module.exports.thirtyDaysAgo = createDate(-30);
module.exports.thirtyDaysInFuture = createDate(30);
module.exports.aYearAgo = createDate(-365);
module.exports.aYearInFuture = createDate(365);
