const Reduxful = require('reduxful').default;
const { protectionActions } = require('../../constants/domainProtectionConstants');
const FetchAdapter = require('./FetchAdapter');

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };

const condenseData = data => {
  const result = {};
  if (data && data.eligibility && data.eligibility.length) {
    for (const group of data.eligibility) {
      if (group.allowed) {
        // only record actions that are allowed
        for (const domainName of group.domainNames) {
          result[domainName] = result[domainName] || [];
          result[domainName].push(group.action);
        }
      }
    }
  }
  return result;
};

/**
 * resulting data will be in format:
 * {
 *    "domainName1": ["ELIGIBLE_ACTION_A"]
 *    "domainName2": ["ELIGIBLE_ACTION_A", "ELIGIBLE_ACTION_B"]
 * }
 */
const apiDesc = {
  getEligibilities: {
    url: '/dccfabric/v1/customers/:customerId/domains/getActionEligibility',
    method: 'POST',
    options: getState => ({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': getState().config.csrfToken,
        'x-app-key': 'products'
      },
      body: JSON.stringify({
        actions: [
          protectionActions.DOWNGRADE_PROTECTION_PLAN,
          protectionActions.UPGRADE_PROTECTION_PLAN,
          protectionActions.DOP_STANDALONE,
          protectionActions.CANCEL_DOP
        ],
        filter: {
          domainNamesFilter: {
            names: [],
            type: 'EXCLUDE'
          },
          domainStates: ['ACTIVE', 'REDEMPTION']
        }
      })
    }),
    resourceData: [],
    dataTransform: data => condenseData(data)
  }
};

const api = new Reduxful('domainsApi', apiDesc, apiConfig);

module.exports = api;

module.exports.condenseData = condenseData;
