const urlParse = require('url-parse');
const queryString = require('query-string');

const { getIsReseller } = require('../../../../utilities/ResellerHelper.js');
const { sanitizeDomainName, getCookie, getCurrentQueryParams, getGUID } = require('./parsing_utils');
const { is123RegCustomer } = require('../product_config_helpers');

const { urlConfig, hostConfigs } = require('../url_config.js');
const { baseDomainNames } = require('../../../../constants/app');

const persistentQueryParams = ['prog_id', 'pl_id', 'isc'];

// from urlconfig hosts list
const ok123RegHostTokens = ['dcc', 'sso', 'fos', 'account'];
const ok123RegUrlTokens = ['launchToAccountPlans'];
const ok123RegTokens = ok123RegHostTokens.concat(ok123RegUrlTokens);

// these should only ever be godaddy or secureserver
// currently these are signin URLs that always
// have to go to either sso.godaddy or sso.secureserver
const non123RegTokens = ['signInO365', 'signInoxc'];
function isNever123RegDomain(tokenName) {
  if (!urlConfig[tokenName]) {
    return false;
  }
  return non123RegTokens.includes(tokenName);
}


/**
 * determines if a siteDomain value for a 123reg site should be set to secureserver or is ok staying 123-reg
 * @param {string} tokenName name of token in urlconfig
 * @returns {boolean} if there are any keys in the entire parsed url path that make it ok to use 123 reg domain
 */
function isOk123RegDomain(tokenName) {
  // is this a key that is mapped to a url?
  if (urlConfig[tokenName]) {
    // is the key itself set to be ok for 123 reg
    if (ok123RegTokens.includes(tokenName)) {
      return true;
    }

    if (isNever123RegDomain(tokenName)) {
      return false;
    }

    // if not, does it contain tokens that are mapped to urls does the url mapped to the key have tokens?
    const baseString = urlConfig[tokenName];
    const tokens = baseString.match(/\[(.*?)\]/g);
    if (tokens) {
      return tokens.some(token => {
        const tokenName = token.replace(/\[|\]/g, '');
        return isOk123RegDomain(tokenName);
      });
    }
    return false;
  }
  return false;
}




function generateBaseCaseValues(productObj, key) {
  const isDev = window.location.host.includes('dev-');
  const isTest = window.location.host.includes('test-');
  let envPrefix = '';
  if (isDev) envPrefix = 'dev-';
  if (isTest) envPrefix = 'test-';
  let IDPinfo = {};
  try {
    IDPinfo = JSON.parse(getCookie('info_idp'));
  } catch (e) {
    // do nothing
  }

  const privateLabelID = IDPinfo.e2s ? IDPinfo.e2s.plid : IDPinfo.plid || 1;

  const is123Reg = is123RegCustomer(privateLabelID);
  const baseSiteDomain = window.location.hostname.replace('local.cx.', '').replace('account.', '');
  // by default, set siteDomain to secureserver for 123-reg domain unless key has an exception

  const siteDomain =
    is123Reg && !isOk123RegDomain(key)
      ? baseSiteDomain.replace(baseDomainNames['123REG'], baseDomainNames.SECURESERVER)
      : baseSiteDomain;

  return {
    businessID: productObj?.NES?.businessId || 'businessID',
    storeID: productObj?.NES?.storeId || 'storeID',
    customerID: IDPinfo.info_cid || 'customerID',
    imgDomain: `img1.${envPrefix}wsimg.com`,
    locale: getCookie('market') || 'en-US',
    privateLabelID: privateLabelID,
    productDomain: sanitizeDomainName(productObj?.detailedName) || 'productDomain', // todo: calculating productDomain will need more logic
    productID: productObj?.id || 'productID',
    productSiteID: productObj?.mwp?.siteId || 'productSiteID',
    websiteID: productObj?.websiteId || '',
    resourceID: productObj?.CES?.resourceId || 'resourceID',
    commerceSubscriptionID: productObj?.commerceSubscription?.id || 'commerceSubscriptionID',
    shopperID: IDPinfo.info_shopperId || IDPinfo?.e2s?.info_shopperId || '',
    siteDomain: siteDomain,
    reg123Domain: `${envPrefix}123-reg.co.uk`,
    showinbioDomain: `${envPrefix.replace('-', '.')}showinbio.godaddy.com`,
    productTypeID: productObj?.CES?.productTypeId,
    managementConsoleID: productObj?.managementConsole ? getGUID(productObj.managementConsole) : ''
  };
}

function replaceTokens(baseString, baseCaseValues, recursionCount = 1) {
  if (recursionCount > 10) {
    // eslint-disable-next-line no-console
    console.error('error: could not resolve URL tokens for ' + baseString);
    return baseString;
  }
  const tokens = baseString.match(/\[(.*?)\]/g);
  if (!tokens || tokens.length <= 0) {
    return baseString;
  }

  tokens.forEach(token => {
    const tokenName = token.replace(/\[|\]/g, '');
    let replacementResult = null;
    if (hostConfigs[tokenName]) {
      replacementResult = hostConfigs[tokenName];
    } else if (baseCaseValues[tokenName] || baseCaseValues[tokenName] === '') {
      replacementResult = baseCaseValues[tokenName];
    } else if (urlConfig[tokenName]) {
      replacementResult = urlConfig[tokenName];
    }
    baseString = baseString.replace(`[${tokenName}]`, replacementResult);
  });
  return replaceTokens(baseString, baseCaseValues, recursionCount + 1);
}

function makeURL(key, productObj, context = {}) {
  const baseCaseValues = generateBaseCaseValues(productObj, key);

  let URL = urlConfig[key];
  if (!URL) return 'URL KEY MISSING: ' + key;
  const isRelative = URL.substring(0, 1) === '/';
  URL = replaceTokens(URL, baseCaseValues);
  // add protocol if not already defined
  if (!URL.startsWith('http') && !isRelative) {
    URL = 'https://' + URL;
  }
  // pass persistent queryparams through
  const URLobj = urlParse(URL);
  let params = queryString.parse(URLobj.query);
  const currentSiteURLparams = getCurrentQueryParams();
  for (const paramName of persistentQueryParams) {
    if (currentSiteURLparams[paramName]) params[paramName] = currentSiteURLparams[paramName];
  }
  // pass rid (compat with older CES stuff)
  if (productObj?.CES?.resourceID) params.rid = productObj.CES.resourceID;

  if (getIsReseller(context?.plid)) {
    params = { ...params, ...{ plid: context?.plid } };
  }
  URLobj.set('query', params);
  URL = URLobj.toString();
  if (isRelative) { // the urlParse utility will automatically append the host to relative paths and we don't want that
    const firstSlashIndex = URL.indexOf('/');
    if (firstSlashIndex > 0) URL = URL.substring(firstSlashIndex);
  }
  return URL;
}

module.exports = {
  isOk123RegDomain,
  ok123RegTokens,
  generateBaseCaseValues,
  makeURL,
  replaceTokens,
  isNever123RegDomain
};
