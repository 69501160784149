const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');


const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };
const defaultResponse = [];

const productGraphRoute = '/productgraphapi/v1/gql/customer';

const apiDesc = {
  getVentures: {
    url: productGraphRoute,
    resourceData: defaultResponse,
    method: 'POST',
    options: getState => {
      const { config } = getState();
      const { csrfToken } = config;

      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'X-CSRF-Token': csrfToken,
        'x-app-key': 'account-products'
      };

      return ({
        withCredentials: true,
        headers
      });
    }
  }
};

const api = new Reduxful('productgraphapi', apiDesc, apiConfig);

module.exports = api;

// This is how we're going to call the api when we know where we're going to call it

// const productGraphBody = {
//   query: '{ user { ventures { id profile { name } projects { id group domain product { id type ... on WordpressProduct { planName sites { blogTitle siteUid } } ... on WebsiteProduct { planType   isPublished } } } } } }'
// };

// dispatch(ProductGraphApi.actionCreators.getVentures({}, { body: JSON.stringify(productGraphBody) }));
