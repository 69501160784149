const { REQUEST_TO_LOAD_APP } = require('../constants/action-types');

function getCypressConfig() {
  if (window.Cypress) {
    return window.Cypress.cy.cypressConfig;
  }
  return {};
}

const config = (state = {}, action = {}) => {
  switch (action.type) {
    case REQUEST_TO_LOAD_APP:
      return {
        ...state,
        ...getCypressConfig()
      };
    default:
      return state;
  }
};

module.exports = {
  default: config
};
