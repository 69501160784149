const productGroupKeys = require('../../../constants/product-group-keys');
const productTypes = require('../../../constants/nes-product-types');

const {
  SHIM_ENTITLEMENTS_LOADED,
  PRODUCT_GROUPS_FETCH_FINISHED
} = require('../../../constants/action-types');

const initialState = {
  domainCount: 0,
  isDomainer: false
};

const updateDomainCount = (state, addedDomainCount, domainerThreshold) => {
  if (!domainerThreshold) {
    return state;
  }
  const currentDomainCount = state.domainCount;
  const newDomainCount = currentDomainCount + addedDomainCount;
  return {
    ...state,
    domainCount: newDomainCount,
    isDomainer: newDomainCount >= domainerThreshold
  };
};

const domainerInfo = (state = initialState, action = {}) => {
  switch (action.type) {
    case PRODUCT_GROUPS_FETCH_FINISHED: {
      const subscriptionDomains = (action.productGroups?.groups || []).find(obj => obj.productGroupKey === productGroupKeys.DOMAINS);
      if (subscriptionDomains) {
        const subscriptionDomainCount = subscriptionDomains.subscriptionCount;
        return updateDomainCount(state, subscriptionDomainCount, action.domainerThreshold);
      }
      return state;
    }
    case SHIM_ENTITLEMENTS_LOADED: {
      const entitlementDomains = (action.rawEntitlements || [])?.filter(obj => obj.productFamily === productTypes.DOMAIN);
      if (entitlementDomains) {
        const entitlementDomainCount = entitlementDomains.length;
        return updateDomainCount(state, entitlementDomainCount, action.config?.constants?.domainsCountForPremiumService);
      }
      return state;
    }
    default:
      return state;
  }
};

module.exports = {
  default: domainerInfo
};
