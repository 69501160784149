const { configureMakeStore, getOrCreateStore } = require('@gasket/redux');
const { HYDRATE, createWrapper } = require('next-redux-wrapper');
const { qualtricsReloadOnSubsResponse } = require('./reducers/qualtricsMiddleware');
const AppReducers = require('./reducers');
const initialState = require('./constants/initialState');
const merge = require('lodash.merge');

// Basic hydrate reducer for next-redux-wrapper
// @see: https://github.com/kirill-konshin/next-redux-wrapper#usage
const rootReducer = (state, { type, payload }) => type === HYDRATE ? merge({}, state, payload) : state;

const reducers = {
  ...AppReducers
};

const middleware = [qualtricsReloadOnSubsResponse];

const makeStore = configureMakeStore({ rootReducer, initialState, reducers, middleware });
const nextRedux = createWrapper(getOrCreateStore(makeStore));

module.exports = makeStore;
module.exports.nextRedux = nextRedux;
