const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };

const currencyRoute = 'https://gui.:host/pcjson/currency/display/?plid=:plid';

// Only return the currencytype field
const extractCurrencyType = data => {
  const filteredCurrencies = data.filter(currency => currency.currencytype !== '-');
  return filteredCurrencies.map(currency => currency.currencytype);
};

const apiDesc = {
  getCurrencies: {
    url: currencyRoute,
    method: 'GET',
    resourceData: [],
    dataTransform: data => extractCurrencyType(data)
  }
};

const api = new Reduxful('currencyApi', apiDesc, apiConfig);

module.exports = api;

module.exports.extractCurrencyType = extractCurrencyType;
