const protectionPlan = {
  GOOD: 'GOOD',
  BETTER: 'BETTER',
  BEST: 'BEST'
};

const protectionActions = {
  DOWNGRADE_PROTECTION_PLAN: 'DOWNGRADE_PROTECTION_PLAN',
  UPGRADE_PROTECTION_PLAN: 'UPGRADE_PROTECTION_PLAN',
  DOP_STANDALONE: 'DOP_STANDALONE',
  CANCEL_DOP: 'CANCEL_DOP'
};

module.exports = {
  protectionPlan,
  protectionActions
};
