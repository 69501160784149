const AUCTIONS = [
  '743',
  '1678388',
  '1678389',
  '1678390',
  '1678391',
  '1678392',
  '1678393',
  '1678394',
  '1678395',
  '1678396',
  '1678397',
  '1678398',
  '1678399',
  '1678400',
  '1678401',
  '1678402',
  '1678403',
  '1678404',
  '1678405',
  '1678406',
  '1678407'
];

const BROKERAGE_PFIDS = [
  '850'
];

const CONVERSATIONS = [
  '1502958',
  '1502960',
  '1502962',
  '1502964',
  '1502966',
  '1502968',
  '1502959',
  '1502961',
  '1502963',
  '1502965',
  '1502967',
  '1502969',
  '1628029',
  '1628031',
  '1628033',
  '1628035',
  '1628037',
  '1677550',
  '1678408',
  '1678409',
  '1528458'
];

const CONVERSATIONS_GOOGLE = [
  '1524768', // Conversations Monthly via Google Play - Free Trial
  '1522439', // Conversations Monthly via Google Play - First Monthly paid
  '1522440' // Conversations Monthly via Google Play - Subsequent renewals
];

const CONVERSATIONS_IOS = [
  '1524766', // Conversations Monthly via App Store - Free Trial
  '1523557', // Conversations Monthly via App Store - First Monthly paid
  '1523558' // Conversations Monthly via App Store - Subsequent renewals
];

// product group: website design services
// website design for e-store (duda)
const DUDA = [
  '1403003',
  '1403004',
  '1403005'
];

// website design service for w+m
// international team created these and can be treated differently than regular DIFY
// eslint-disable-next-line no-unused-vars
const DIFY_APAC = [
  '1403006',
  '1403007',
  '1403008'
].concat(DUDA);

const DIGITAL_MARKETING = [
  '1667612',
  '1667613',
  '1667615',
  '1667616',
  '1667618',
  '1667619',
  '1667621',
  '1667622',
  '1667624',
  '1667625',
  '1667627',
  '1667628',
  '1667614',
  '1667630',
  '1667617',
  '1667632',
  '1667620',
  '1667634',
  '1667623',
  '1667636',
  '1667626',
  '1667638',
  '1667629',
  '1667640',
  '1667631',
  '1667642',
  '1667633',
  '1667643',
  '1667635',
  '1667644',
  '1667637',
  '1667645',
  '1667639',
  '1667646',
  '1667641',
  '1667647',
  '1687078',
  '1687079',
  '1687080',
  '1687081',
  '1687082',
  '1687083',
  '1687084',
  '1687085',
  '1687086',
  '1687087',
  '1687088',
  '1687089'
];

const DOMAIN_OWNERSHIP_PROTECTION = ['900'];

const EMAIL_FORWARDS_PACK = [
  '1499752',
  '1499755',
  '1499758',
  '1499761'
];

const FREE_CREDITS_EOL = ['1701', '11701'];

const GET_FOUND_DESIGN_SERVICES = [
  '1012442',
  '1012446',
  '1012448',
  '1012444',
  '1012450',
  '1012452',
  '1012454',
  '1012456'
];

const GODADDY_IT_SERVICES = [
  '1306171',
  '1306172',
  '1306174',
  '1306175',
  '1306225',
  '1306226',
  '1306227',
  '1306228',
  '1306229',
  '1306230',
  '1306232',
  '1306233',
  '1306234',
  '1306235'
];

const GD_SOCIAL = [
  '1224370',
  '1224377',
  '1224349',
  '1224350',
  '1224362',
  '1224369',
  '1022644',
  '1022645',
  '1225344',
  '1224351',
  '1224361'
];

const GD_STUDIO = [
  '1323091',
  '1323092',
  '1323093',
  '1323094',
  '1323095',
  '1323102',
  '1323118',
  '1323120',
  '1327431',
  '1327432',
  '1327433',
  '1327434',
  '1327435',
  '1327437'
];
  // productFamily: partnerProducts, productType: hostEurope
const HOST_EUROPE_DEDICATED = [
  '1303807',
  '1303810',
  '1303808',
  '1303811'
];

// no productFamily info
const HOST_EUROPE_PANEL = [
  '1303815',
  '1303816'
];

// productFamily: partnerProducts, productType: hostEurope
const HOST_EUROPE_VPS = [
  '1303809',
  '1303812',
  '1303814'
];

const INSTANT_PAGE_MONTHLY_FREEMIUM = [
  '891787'
];

const KABBAGE = [
  '1296908'
];

const MWP_MULTISITE = [
  '32790',
  '32792',
  '32794',
  '32796',
  '32798',
  '32800',
  '1073567',
  '1073569',
  '1073570',
  '1073578',
  '1073592',
  '1073608',
  '1073624',
  '1213980',
  '1213983',
  '1213985',
  '1213986',
  '1213988',
  '1213990',
  '1213992',
  '1213994',
  '1213996',
  '1213998',
  '1214001',
  '1214003',
  '1214004',
  '1214006',
  '1214008',
  '1214010',
  '1214012',
  '1214014',
  '1214016',
  '1214019',
  '1214021',
  '1214022',
  '1214024',
  '1214026',
  '1214028',
  '1214030',
  '1214034',
  '1214039',
  '1214040',
  '1214042',
  '1214044',
  '1214046',
  '1214048',
  '1214050',
  '1505383',
  '1505385',
  '1505386',
  '1505388',
  '1505389',
  '1505391',
  '1505392',
  '1505394',
  '1505397',
  '1505398',
  '1505400',
  '1505401'
];

const ONLINE_MARKETING = [
  // GoCentral Digital Marketing Suite
  '1082994',
  '1082997',
  '1083000',
  '1083003',
  '1083006',
  '1083009',
  '1082992',
  '1082995',
  '1082998',
  '1083001',
  '1083004',
  '1083007',
  // GoCentral SEO (US)
  '1208730',
  '1208731',
  '1208734',
  '1208735',
  '1208736',
  '1208737',
  '1208732',
  '1208733',
  '1208738',
  '1208739',
  '1208740',
  '1208741',
  '1208742',
  '1208743',
  '1208744',
  '1208745',
  '1208746',
  '1208747',
  // GoCentral SEO (INTL)
  '1200235',
  '1200237',
  '1200239',
  '1200241',
  '1200243',
  '1200245',
  '1200247',
  '1200249',
  '1200251'
];

// https://godaddy-corp.atlassian.net/wiki/spaces/EPW/pages/106368789/Open+XChange+-+Workspace+Email
// productFamily: productivity
// productType: professionalEmail
// productGroupKey: openXchange .. maybe emailAndOffice
// namespace: prtnremail
const OPEN_XCHANGE = [
  '1192187',
  '1192189',
  '1192190',
  '1192192',
  '1192194',
  '1192195',
  '1192197',
  '1192198',
  '1192199',
  '1192201',
  '1192202',
  '1192204',
  '1192206',
  '1192209',
  '1192211',
  '1192212',
  '1192214',
  '1192216',
  '1192218',
  '1192221',
  '1192223',
  '1192224',
  '1192226',
  '1192228',
  '1192230',
  '1192233',
  '1192235',
  '1192236',
  '1192238',
  '1192240',
  '1336677',
  '1494011',
  '1494013',
  '1494014',
  '1494016',
  '1494018',
  '1494019',
  '1494021',
  '1494022',
  '1494023',
  '1494025',
  '1494026',
  '1494028',
  '1494030',
  '1494033',
  '1494035',
  '1494036',
  '1494038',
  '1494040',
  '1494042',
  '1494045',
  '1494047',
  '1494048',
  '1494050',
  '1494052',
  '1494054',
  '1494057',
  '1494059',
  '1494060',
  '1494062',
  '1494064',
  '1494066',
  '1494069',
  '1494071',
  '1494072',
  '1494074',
  '1494076'
];

const PHOTOSHOOT = [
  '1350490',
  // Pfid 1215238 is for the deprecated photoshoot product. We will continue to include it until it is confirmed the product has been terminated
  '1215238'
];

// eslint-disable-next-line id-length
const SEARCH_ENGINE_VISIBILITY_LEGACY = [
  '1401',
  '1405',
  '1412',
  '1413',
  '1414',
  '1415',
  '1416',
  '1417',
  '1418',
  '1419',
  '1420',
  '271401',
  '271412',
  '271413',
  '271415',
  '271419',
  '271420'
];

const SHOWINBIO = [
  '1659722',
  '1659723',
  '1668175',
  '1668176',
  '1668177',
  '1668178',
  '1668179',
  '1668180',
  '1668181',
  '1668182'
];

const SMARTLINE_ANDROID_PFIDS = [
  '1084582',
  '1084584',
  '1084586',
  '1303243'
];

const SMARTLINE_IOS_PFIDS = [
  '1084581',
  '1084583',
  '1084585',
  '1303241'
];

// these use onlineStore productGroup
// AKA webDesignServices
const STANDARD_OLS = [
  '965763',
  '965765',
  '965767'
];

const SUPER_RESELLER_PFIDS = [
  '18891'
];

const WEBSITE_BACKUP = [
  '1084911',
  '1084916',
  '1084919',
  '1084914',
  '1084923',
  '1084926',
  '1084929',
  '1084932',
  '1084913',
  '1084918',
  '1084921',
  '1084922',
  '1084925',
  '1084928',
  '1084931',
  '1084934',
  '1084936',
  '1084939',
  '1084941',
  '1084942',
  '1084944',
  '1084946',
  '1084948',
  '1084950',
  '1084952',
  '1084955',
  '1084957',
  '1084958',
  '1084960',
  '1084962',
  '1084964',
  '1084966',
  '1084968',
  '1084971',
  '1084973',
  '1084974',
  '1084976',
  '1084978',
  '1084980',
  '1084982',
  '1084984',
  '1084987',
  '1084989',
  '1084990',
  '1084992',
  '1084994',
  '1084996',
  '1084998'
];

const WDS_GOCENTRAL_OLS = [
  '1208850'
];

const WDS_MWP = [
  '845478',
  '845480',
  '845482',
  '845484',
  '845486',
  '954398',
  '1069948',
  '1069950',
  '1069951',
  '1069953',
  '1069955',
  '1069956',
  '1069958',
  '1069959',
  '1069960',
  '1069962'
];

// WDS Standard Store (GC/OLS) (W+M Ecomm)
// WDS in this context means Website Design Services (NOT wordpress)
const WDS_STANDARD_STORE = [
  // Websites + Marketing - with build
  '1208796',
  '1208801',
  '1208804',
  '1208799',
  '1208808',
  '1208811',
  '1208814',
  '1208817',
  '1208820'
].concat(WDS_GOCENTRAL_OLS);

const WDS_WEBSITE_CARE = [
  '1303757',
  '1303758',
  '1303762',
  '1303763',
  '1303765',
  '1303766',
  '1303760',
  '1303761',
  '1303769',
  '1303770',
  '1303759',
  '1303772',
  '1303764',
  '1303775',
  '1303767',
  '1303777',
  '1303768',
  '1303774',
  '1303771',
  '1303780',
  '1303773',
  '1303782',
  '1303776',
  '1303785',
  '1303778',
  '1303787',
  '1303779',
  '1303784',
  '1303781',
  '1303790',
  '1303783',
  '1303792',
  '1303786',
  '1303795',
  '1303788',
  '1303797',
  '1303789',
  '1303794',
  '1303791',
  '1303800',
  '1303793',
  '1303802',
  '1303796',
  '1303804',
  '1303798',
  '1303805',
  '1303799',
  '1303803',
  '1303801',
  '1303806'
];

const WORDPRESS_DESIGN_SERVICES = [
  '652',
  '925',
  '845479',
  '845481',
  '845483',
  '845485',
  '845487',
  '845488',
  '954399',
  '954400',
  '1068031',
  '1069949',
  '1069952',
  '1069954',
  '1069957',
  '1069961',
  '1069963',
  '1069964',
  '1069965',
  '1069966',
  '1069967',
  '1069968',
  '1069969',
  '1071851',
  '1071853',
  '1071855',
  '1071857',
  '1071859',
  '1071861',
  '1208797',
  '1208800',
  '1208802',
  '1208805',
  '1208809',
  '1208812',
  '1208815',
  '1208818',
  '1208821',
  '1320015',
  '1320016',
  '1320017',
  '1320018',
  '1320019',
  '1320020',
  '1320021',
  '1320022',
  '1374913',
  '1374914',
  '1374915',
  '1374916',
  '1374917',
  '1374918',
  '1374919',
  '1374920',
  '1374921',
  '1374922',
  '1374923',
  '1374924',
  '1374925',
  '1374926',
  '1374927',
  '1374928',
  '1374929',
  '1374930'
].concat(WDS_MWP);

const WSB_MAKEOVER = [
  '1071845',
  '1071847',
  '1071849'
];

// Marketing Services DIFY Attachment PFIDs
const MS_DIFY_ATTACHMENT_PFIDS = {
  // Marketing Services Basic W+M
  WM_BASIC: [
    '1323535',
    '1323540',
    '1323543',
    '1323538',
    '1323547',
    '1323550',
    '1323553',
    '1323556'
  ],
  // Marketing Services Standard W+M
  WM_STANDARD: [
    '1323537',
    '1323542',
    '1323545',
    '1323546',
    '1323549',
    '1323552',
    '1323555',
    '1323558'
  ],
  // Marketing Services Premium W+M
  WM_PREMIUM: [
    '1374971',
    '1374974',
    '1374976',
    '1374979'
  ],
  // Marketing Services Standard DMS
  DMS_STANDARD: [
    '1323560',
    '1323563',
    '1323565',
    '1323566',
    '1323568',
    '1323570',
    '1323572',
    '1323574'
  ],
  DMS_PREMIUM: [
    // Marketing Services Premium DMS
    '1374973',
    '1374978',
    '1374981',
    '1374982'
  ],
  MWP_BASIC: [
    '1329817',
    '1329822',
    '1329825',
    '1329820',
    '1329829'
  ],
  MWP_BASIC_RENEWAL: [
    '1329818',
    '1329823',
    '1329826',
    '1329821',
    '1329830'
  ],
  MWP_STANDARD: [
    '1329819',
    '1329824',
    '1329827',
    '1329828',
    '1329831'
  ],
  MWP_STANDARD_RENEWAL: [
    '1329832',
    '1329835',
    '1329837',
    '1329834',
    '1329840'
  ],
  MWP_PREMIUM: [
    '1374939',
    '1374944',
    '1374947',
    '1374942'
  ],
  STANDALONE_STANDARD: [
    '1329833',
    '1329836',
    '1329838',
    '1329839',
    '1329841'
  ],
  STANDALONE_STANDARD_RENEWAL: [
    '1329842',
    '1329844',
    '1329845',
    '1329843',
    '1329846'
  ],
  STANDALONE_PREMIUM: [
    '1374941',
    '1374946',
    '1374949',
    '1374950'
  ]
};

const CUSTOM_PFID = {
  DUDA,
  MS_DIFY_ATTACHMENT_PFIDS,
  WDS_C3_ONLY_OPTIONS: WDS_MWP
    .concat(WDS_GOCENTRAL_OLS)
    .concat(WDS_WEBSITE_CARE)

};

module.exports = {
  AUCTIONS,
  BROKERAGE_PFIDS,
  CONVERSATIONS,
  CONVERSATIONS_GOOGLE,
  CONVERSATIONS_IOS,
  CUSTOM_PFID,
  DIGITAL_MARKETING,
  DOMAIN_OWNERSHIP_PROTECTION,
  EMAIL_FORWARDS_PACK,
  FREE_CREDITS_EOL,
  GD_SOCIAL,
  GET_FOUND_DESIGN_SERVICES,
  GODADDY_IT_SERVICES,
  GD_STUDIO,
  HOST_EUROPE_DEDICATED,
  HOST_EUROPE_PANEL,
  HOST_EUROPE_VPS,
  INSTANT_PAGE_MONTHLY_FREEMIUM,
  KABBAGE,
  MWP_MULTISITE,
  ONLINE_MARKETING,
  OPEN_XCHANGE,
  PHOTOSHOOT,
  // eslint-disable-next-line id-length
  SEARCH_ENGINE_VISIBILITY_LEGACY,
  SHOWINBIO,
  SMARTLINE_ANDROID_PFIDS,
  SMARTLINE_IOS_PFIDS,
  STANDARD_OLS,
  SUPER_RESELLER_PFIDS,
  WEBSITE_BACKUP,
  WDS_STANDARD_STORE,
  WORDPRESS_DESIGN_SERVICES,
  WSB_MAKEOVER
};
