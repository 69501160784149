const buttonType = {
  DNS: 'dns',
  EDIT_PLAN: 'editPlan',
  PRIVACY: 'privacy',
  PROTECTION: 'protection',
  SEE_PLAN: 'see_plan',
  SETUP: 'setup',
  SIGNIN: 'signin',
  MANAGE: 'manage',
  MOBILE_MANAGE: 'mobile_manage',
  MOBILE_SIGNIN: 'mobile_signin',
  RENEW: 'renew',
  PENDING: 'pending',
  WEB_INBOX: 'web_inbox',
  GET_NUMBER: 'get_number',
  GET_STARTED: 'get_started',
  COMPARE_ALL: 'compare_all'
};

const buttonStyle = {
  CRITICAL: 'critical',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INLINE: 'inline'
};

const buttonIconTypes = {
  ADD_ICON: 'addIcon'
};

const BUTTON_ACTIONS = {
  MUIPOD: 'muipod',
  PLANUPGRADE: 'planUpgrade',
  PLANUPGRADE_UNIVERSAL: 'planUpgrade_universal',
  GO_TO_LAUNCH_PAGE: 'go_to_launch_page',
  MUI_UPP_EXPERIMENT_M365: 'mui_upp_experiment_m365',
  UPP_EXPERIMENT_M365: 'upp_experiment_m365',
  PANEL_REDIRECT_EXP: 'panel_redirect_exp'
};


module.exports = {
  buttonType,
  buttonStyle,
  buttonIconTypes,
  BUTTON_ACTIONS
};
