const {
  normalizeSubscriptions,
  normalizeShimEntitlements,
  normalizeShimSubscriptions,
  normalizeDomainsDCC,
  normalizeDomainEligibility,
  normalizeManagedWordpress,
  normalizeWebsiteData,
  normalizeSecondaryValues,
  normalizeCategorizationData,
  normalizeCommerceSubscriptions
} = require('../utilities/normalizer/Normalizer');
const { parseSubscriptionsBundles } = require('../utilities/normalizer/NormalizerHelper');
const domainerInfoReducer = require('./domainerInfo').default;

// CONSTANTS
const {
  SUBSCRIPTIONS_LOADED,
  SHIM_ENTITLEMENTS_LOADED,
  GET_DOMAINS_STATUS_LOADED,
  WORDPRESS_SITES_LOADED,
  TOGGLE_ACCORDION_COLLAPSE,
  SET_ACCORDION_COLLAPSE,
  LOADING_EXPERIMENTS,
  DISPATCH_CONFIG,
  SHIM_SUBSCRIPTIONS_LOADED,
  PRODUCT_GROUPS_FETCH_FINISHED,
  SSL_CREDITS_LOADED
} = require('../../../constants/action-types');

const initialState = {
  products: {},
  apiData: {
    cesSubscriptions: [],
    entitlements: [],
    dccFabric: {},
    domainEligibility: {},
    mwpSites: [],
    websiteData: [],
    mockedNPO: {},
    customerType: null,
    shimSubscriptions: [],
    commerceSubscriptions: []
  },
  categorizationData: [],
  config: {
    plid: null,
    switchboard: {}
  },
  domainerInfo: {
    domainCount: 0,
    isDomainer: false
  },
  experiments: []
};

function normalizedProducts(state = initialState, action = {}) {
  switch (action.type) {
    // primary info
    case SUBSCRIPTIONS_LOADED: {
      const parsedSubscriptionsBundles = parseSubscriptionsBundles(action.subscriptions);
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          cesSubscriptions: [...state.apiData.cesSubscriptions, ...parsedSubscriptionsBundles]
        }
      };

      normalizeSubscriptions(newState, action.config);

      return newState;
    }

    case SSL_CREDITS_LOADED: {
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          sslCredits: [...action.sslCredits]
        }
      };
      normalizeSubscriptions(newState, action.config);
      return newState;
    }

    case SHIM_ENTITLEMENTS_LOADED: {
      const domainerInfo = domainerInfoReducer(state.domainerInfo, action);
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          entitlements: [...state.apiData.entitlements, ...action.rawEntitlements]
        },
        domainerInfo
      };

      normalizeShimEntitlements(newState, action.config);
      return newState;
    }

    // // // secondary info
    case SHIM_SUBSCRIPTIONS_LOADED: {
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          shimSubscriptions: action.rawShimSubscriptions
        }
      };

      normalizeShimSubscriptions(newState, action.config);
      return newState;
    }

    case GET_DOMAINS_STATUS_LOADED: {
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          dccFabric: {
            ...state.apiData.dccFabric,
            ...action.domainActivator
          }
        }
      };

      normalizeDomainsDCC(newState, action.config);
      return newState;
    }

    case 'domainsApi_getEligibilities_SUCCESS': {
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          domainEligibility: action.payload
        }
      };

      normalizeDomainEligibility(newState, action.config);
      return newState;
    }

    case WORDPRESS_SITES_LOADED: {
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          mwpSites: action.managedWordpress
        }
      };

      normalizeManagedWordpress(newState, action.config);
      return newState;
    }

    case 'websitesapi_getWebsiteData_SUCCESS': {
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          websiteData: action.payload.raw
        }
      };

      normalizeWebsiteData(newState, action.config);
      return newState;
    }

    case 'customerTypeApi_getCustomerType_SUCCESS': {
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          customerType: action.payload?.customerType || null
        }
      };

      normalizeSecondaryValues(newState, action.config);
      normalizeCategorizationData(newState);
      return newState;
    }

    case 'commapi_getCommerceSubscriptions_SUCCESS': {
      const newState = {
        ...state,
        products: { ...state.products },
        apiData: {
          ...state.apiData,
          commerceSubscriptions: action.payload || null
        }
      };

      normalizeCommerceSubscriptions(newState, action.config);
      return newState;
    }

    // doesn't call normalizeProductData
    case TOGGLE_ACCORDION_COLLAPSE:
    case SET_ACCORDION_COLLAPSE:
      return {
        ...state,
        categorizationData: categorizationDataReducer(state.categorizationData, action)
      };

    case LOADING_EXPERIMENTS: {
      const newState = {
        ...state,
        experiments: [...action.experiments]
      };
      return newState;
    }

    case DISPATCH_CONFIG: {
      const newState = {
        ...state,
        config: action.payload.config
      };
      return newState;
    }

    case PRODUCT_GROUPS_FETCH_FINISHED: {
      const domainerInfo = domainerInfoReducer(state.domainerInfo, action);
      return {
        ...state,
        domainerInfo
      };
    }

    default:
      return state;
  }
}

function categorizationDataReducer(state = [], action) {
  switch (action.type) {
    case TOGGLE_ACCORDION_COLLAPSE: {
      const categorizationIndex = state.findIndex(categorization => categorization.id === action.id);
      if (categorizationIndex >= 0) {
        const categorization = state[categorizationIndex];
        const newCategorization = {
          ...categorization,
          isCollapsed: !categorization.isCollapsed
        };

        const newState = [...state];
        newState[categorizationIndex] = newCategorization;
        return newState;
      }
      return state;
    }

    case SET_ACCORDION_COLLAPSE: {
      const categorizationIndex = state.findIndex(categorization => categorization.id === action.id);

      if (categorizationIndex >= 0) {
        const categorization = state[categorizationIndex];
        const newCategorization = {
          ...categorization,
          isCollapsed: action.shouldBeCollapsed
        };

        const newState = [...state];
        newState[categorizationIndex] = newCategorization;
        return newState;
      }
      return state;
    }
    default:
      return state;
  }
}

module.exports = {
  default: normalizedProducts,
  initialState
};
