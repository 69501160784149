const { createIntl, createIntlCache } = require('react-intl');

/*
  The getIntlCache fn is intended to be used when intl is required
  outside of a react component.

  Specially if that file requires intl in multiple functions which require
  re-creating an intl obj.

  The idea behind the getIntlCache function is to
    - cache intl objs for each market requested.
      And avoid re-creating it if it has been requested before.
    - handle error logic and special cases when it comes to
      creating the intl obj and importing translation files
*/

const DEFAULT_MARKET = 'en-US';

function getIntlMessages(market) {
  const intlMessage = require(`../../../public/locales/${market}.json`);
  return intlMessage;
}

function getMarketIntl(market) {
  const cache = createIntlCache();
  try {
    const intlMessages = getIntlMessages(market);
    const intl = createIntl({ locale: market, messages: intlMessages }, cache);
    return intl;
  } catch (e) {
    const enUSIntlMessages = getIntlMessages(DEFAULT_MARKET);
    const defaultIntl = createIntl({ locale: DEFAULT_MARKET, messages: enUSIntlMessages }, cache);
    return defaultIntl;
  }
}

function getIntlCache() {
  const intlMarkets = {};

  const intlCache = (market) => {
    if (intlMarkets[market]) {
      return intlMarkets[market];
    }

    const intl = getMarketIntl(market);
    intlMarkets[market] = intl;

    return intl;
  };

  return intlCache;
}

module.exports = {
  getIntlMessages,
  getMarketIntl,
  getIntlCache
};
