const {
  APP_STATE_LOADING,
  SUBSCRIPTIONS_LOADING,
  SHOW_SHIM_BANNER_ERROR,
  FAILED_SUBSCRIPTIONS_LOAD,
  PRODUCT_GROUPS_FETCH_STARTED,
  PRODUCT_GROUPS_FETCH_FINISHED,
  SET_VNEXT_UPGRADE_ACCOUNT,
  FAILED_PRODUCT_GROUPS_FETCH
} = require('../constants/action-types');

const initialState = {
  areProductGroupsLoading: false,
  hasNonDomainNesSubs: false,
  isAppStateLoading: false,
  isEmptyResponse: true,
  isError: false,
  isSubscriptionError: false,
  isSubscriptionLoading: false,
  productGroups: {},
  showBanner: false,
  currentVNextUpgradeAccount: '',
  currentVNextUpgradeFlow: ''
};

// eslint-disable-next-line complexity
const subscriptions = (state = initialState, action = {}) => {
  switch (action.type) {
    case APP_STATE_LOADING:
      return {
        ...state,
        isAppStateLoading: true
      };
    case SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        isSubscriptionLoading: true,
        isSubscriptionError: false,
        isEmptyResponse: false
      };
    case SHOW_SHIM_BANNER_ERROR:
      return {
        ...state,
        showBanner: action.showBanner
      };
    case FAILED_SUBSCRIPTIONS_LOAD:
      return {
        ...state,
        isSubscriptionLoading: false,
        isSubscriptionError: true,
        isEmptyResponse: false,
        isAppStateLoading: false,
        showBanner: action.showBanner,
        error: action.error
      };
    case PRODUCT_GROUPS_FETCH_STARTED:
      return { ...state, areProductGroupsLoading: true, isError: false };
    case PRODUCT_GROUPS_FETCH_FINISHED:
      return {
        ...state,
        productGroups: action.productGroups,
        areProductGroupsLoading: false,
        isError: action.isError,
        error: action.error
      };
    case SET_VNEXT_UPGRADE_ACCOUNT:
      return {
        ...state,
        currentVNextUpgradeAccount: action.accountId || '',
        currentVNextUpgradeFlow: action.flow || ''
      };
    case FAILED_PRODUCT_GROUPS_FETCH:
      return {
        ...state,
        error: action.error,
        showBanner: action.showBanner
      };
    default:
      return state;
  }
};

module.exports = {
  default: subscriptions
};
