module.exports = {
  domainActivator: {},
  shopper: {},
  productGroups: {
    totalProducts: 0,
    groups: []
  },
  config: {
    loaded: false,
    policy: {
      restrictedMarket: false
    }
  },
  experiments: {},
  app: {
    isLoading: true,
    hasError: false
  },
  publicTokens: {}
};
