const app = {
  ACTIVE_NR: 'ACTIVE-NR',
  ACTIVE: 'Active',
  DELUXE_PFID: '84',
  DELUXE: 'deluxe',
  MWP_SITES_GROUPING: 'mwpSitesGrouping',
  PAID: 'PAID',
  PRIVATE_PFID: '7001',
  PRIVATE: 'private',
  PRODUCT_NOT_SETUP: 'new account',
  PROTECTED_PFID: '766001',
  PROTECTED: 'protected',
  REFERENCE_DISPLAY_TYPE: 'reference',
  RELATION_DISPLAY_TYPE: 'relation',
  SUPER_RESELLER_TYPE_ID: 5,
  EXPIRED: 'expired',
  FAILED_BILLING: 'failed_billing',
  TITANTEST: '(Titan Email)'
};

const EXPIRATION_DATE_STATUS = {
  DISTANT: 'distant',
  SOON: 'soon',
  NOW: 'now',
  PASSED: 'passed'
};

const SPECIAL_STATUS = {
  NONE: 'none',
  TRIAL: 'TRIAL',
  FREE: 'FREE'
};

const PUBLISH_STATUS = {
  PUBLISHED: 'published'
};

const PRODUCT_NAMES = {
  GODADDY_PAYMENTS: 'godaddy payments'
};

const baseDomainNames = {
  '123REG': '123-reg.co.uk',
  'GODADDY': 'godaddy.com',
  'SECURESERVER': 'secureserver.net'
};

const brands = {
  '123REG': '123reg',
  'GODADDY': 'godaddy',
  'SECURESERVER': 'secureserver'
};

const HOSTING_PROVIDERS = {
  GODADDY: 'GODADDY',
  UNKNOWN: 'UNKNOWN', // means there exists an A record with an ip address, but the ip is not owned by GoDaddy
  null: 'null', // there are no A records,
  NOT_FOUND: 'NOT_FOUND'
};

module.exports = {
  ...app,
  baseDomainNames,
  HOSTING_PROVIDERS,
  SPECIAL_STATUS,
  PUBLISH_STATUS,
  PRODUCT_NAMES,
  EXPIRATION_DATE_STATUS,
  brands
};
