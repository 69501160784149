const Reduxful = require('reduxful').default;
const FetchAdapter = require('./FetchAdapter');

const NES_ENTITLEMENTS_PAGE_SIZE = 250; // max possible
const subscriptionsPerPage = 250; // max possible

const getEntitlementsUrl = state => {
  const { gatewayUrl, customerId, market } = state.config;
  const apiUrl = `${gatewayUrl}/${customerId}/subscriptions-shim/entitlements?productFamilies=:productFamilies&excludes=CES&marketId=${market}&limit=${NES_ENTITLEMENTS_PAGE_SIZE}&offset=:offset`;
  return apiUrl;
};

const getSubscriptionsUrl = state => {
  const { gatewayUrl, customerId, market } = state.config;
  const productFamiliesList = [];
  if (state?.isWsbURL) productFamiliesList.push('websiteBuilder');
  if (state?.isProductivityURL) productFamiliesList.push('productivity');
  const productFamilies = productFamiliesList.join();

  const apiUrl = `${gatewayUrl}/${customerId}/subscriptions?productFamilies=${productFamilies?.length > 0 ? productFamilies : ':productFamilies'}&marketId=${market}&limit=${subscriptionsPerPage}&offset=0`;
  return apiUrl;
};

const getUPPSubscriptionsUrl = state => getSubscriptionsUrl({ ...state, isWsbURL: true, isProductivityURL: true });

const getProductFamiliesUrl = state => {
  const { gatewayUrl, customerId } = state.config;
  const apiUrl = `${gatewayUrl}/${customerId}/productFamilies?excludes=ces&includes=third-party`;
  return apiUrl;
};

const requestAdapter = new FetchAdapter();
const apiConfig = { requestAdapter };
const defaultResponse = [];
const apiHeaders = {
  'X-App-Key': 'products'
};

const apiDesc = {
  getEntitlements: {
    url: getState => getEntitlementsUrl(getState()),
    method: 'GET',
    resourceData: defaultResponse,
    options: {
      headers: apiHeaders
    }
  },
  getProductFamilies: {
    url: getState => getProductFamiliesUrl(getState()),
    method: 'GET',
    resourceData: defaultResponse,
    options: {
      headers: apiHeaders
    }
  },
  getWSBSubscriptions: {
    url: getState => getUPPSubscriptionsUrl(getState()),
    method: 'GET',
    resourceData: defaultResponse,
    options: {
      headers: apiHeaders
    }
  },
  getSubscriptions: {
    url: getState => getSubscriptionsUrl(getState()),
    method: 'GET',
    resourceData: defaultResponse,
    options: {
      headers: apiHeaders
    }
  }
};

const api = new Reduxful('shimApi', apiDesc, apiConfig);
module.exports = api;

module.exports.getEntitlementsUrl = getEntitlementsUrl;
module.exports.getSubscriptionsUrl = getSubscriptionsUrl;
module.exports.getUPPSubscriptionsUrl = getUPPSubscriptionsUrl;
module.exports.getProductFamiliesUrl = getProductFamiliesUrl;
module.exports.NES_ENTITLEMENTS_PAGE_SIZE = NES_ENTITLEMENTS_PAGE_SIZE;
