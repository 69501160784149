const {
  GET_DOMAINS_STATUS_LOADING,
  GET_DOMAINS_STATUS_LOADED,
  GET_DOMAINS_STATUS_FAILED,
  CHANGE_DOMAIN_ACTIVATOR_ID
} = require('../constants/action-types');

const initialState = {
  err: null,
  isDomainStatusLoading: false,
  isDomainStatusLoaded: false,
  domains: {},
  id: null
};

const domainActivator = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DOMAINS_STATUS_LOADING:
      return {
        ...state,
        isDomainStatusLoading: true,
        err: null
      };

    case GET_DOMAINS_STATUS_LOADED:
      return {
        ...state,
        domains: action.domainActivator,
        isDomainStatusLoaded: true,
        isDomainStatusLoading: false,
        err: null
      };

    case GET_DOMAINS_STATUS_FAILED:
      return {
        ...state,
        err: action.err,
        isDomainStatusLoading: false
      };

    case CHANGE_DOMAIN_ACTIVATOR_ID:
      return {
        ...state,
        id: action.id
      };

    default:
      return state;
  }
};

module.exports = {
  default: domainActivator,
  initialState
};
