const LOADING_EXPERIMENTS = 'my_products/LOADING_EXPERIMENTS';
const PRODUCT_GROUPS_FETCH_STARTED = 'my_products/PRODUCT_GROUPS_FETCH_STARTED';
const PRODUCT_GROUPS_FETCH_FINISHED = 'my_products/PRODUCT_GROUPS_FETCH_FINISHED';
const FAILED_PRODUCT_GROUPS_FETCH = 'my_products/FAILED_PRODUCT_GROUPS_FETCH';
const REQUEST_TO_LOAD_APP = 'my_products/REQUEST_TO_LOAD_APP';
const APP_LOADED = 'my_products/APP_LOADED';
const FAILED_APP_LOAD = 'my_products/FAILED_APP_LOAD';
const APP_LANGUAGE_LOADED = 'my_products/APP_LANGUAGE_LOADED';
const FAILED_LANGUAGE_LOAD = 'my_products/FAILED_LANGUAGE_LOAD';
const FAILED_CONFIG_LOAD = 'my_products/FAILED_CONFIG_LOAD';
const APP_STATE_LOADING = 'my_products/APP_STATE_LOADING';
const SUBSCRIPTIONS_LOADING = 'my_products/SUBSCRIPTIONS_LOADING';
const SUBSCRIPTIONS_LOADED = 'my_products/SUBSCRIPTIONS_LOADED';
const SSL_CREDITS_LOADED = 'my_products/SSL_CREDITS_LOADED';
const DISPATCH_CONFIG = 'my_products/DISPATCH_CONFIG';
const FAILED_SUBSCRIPTIONS_LOAD = 'my_products/FAILED_SUBSCRIPTIONS_LOAD';
const MUIPOD_LOADING = 'my_products/MUIPOD_LOADING';
const MUIPOD_LOADED = 'my_products/MUIPOD_LOADED';
const FAILED_MUIPOD_LOAD = 'my_products/FAILED_MUIPOD_LOAD';
const RESET_APP_ERRORS = 'my_products/RESET_APP_ERRORS';
const FREECREDITS_LOADED = 'my_products/FREECREDITS_LOADED';
const FAILED_FREECREDITS_LOADED = 'my_products/FAILED_FREECREDITS_LOADED';
const PAYMENTMETHODS_LOADING = 'my_products/PAYMENTMETHODS_LOADING';
const PAYMENTMETHODS_LOADED = 'my_products/PAYMENTMETHODS_LOADED';
const PAYMENTMETHODS_LOAD_FAILED = 'my_products/PAYMENTMETHODS_LOAD_FAILED';
const PRO_LOAD = 'my_products/PRO_LOAD';
const PRO_LOADED = 'my_products/PRO_LOADED';
const PRO_MARKETPLACE_LOAD = 'my_products/PRO_MARKETPLACE_LOAD';
const PRO_MARKETPLACE_LOADED = 'my_products/PRO_MARKETPLACE_LOADED';
const FREECREDITS_REDEEM_LOADED = 'my_products/FREECREDITS_REDEEM_LOADED';
const FAILED_FREECREDITS_REDEEM = 'my_products/FAILED_FREECREDITS_REDEEM';
const TOGGLE_SHOW_FREE_CREDITS = 'my_products/TOGGLE_SHOW_FREE_CREDITS';
const FREECREDITS_LOADING = 'my_products/FREECREDITS_LOADING';
const FREECREDITS_REDEEM_LOADING = 'my_products/FREECREDITS_REDEEM_LOADING';
const TOGGLE_REDEEM_INITIATED = 'my_products/TOGGLE_REDEEM_INITIATED';
const GET_DOMAINS_STATUS_LOADED = 'my_products/GET_DOMAINS_STATUS_LOADED';
const GET_DOMAINS_STATUS_FAILED = 'my_products/GET_DOMAINS_STATUS_FAILED';
const CHANGE_DOMAIN_ACTIVATOR_ID = 'my_products/CHANGE_DOMAIN_ACTIVATOR_ID';
const GET_DOMAINS_STATUS_LOADING = 'my_products/GET_DOMAINS_STATUS_LOADING';
const WORDPRESS_SITES_FAILED = 'my_products/WORDPRESS_SITES_FAILED';
const WORDPRESS_SITES_LOADED = 'my_products/WORDPRESS_SITES_LOADED';
const RESELLER_STOREFRONT_LOADING = 'my_products/RESELLER_STOREFRONT_LOADING';
const RESELLER_STOREFRONT_LOADED = 'my_products/RESELLER_STOREFRONT_LOADED';
const RESELLER_STOREFRONT_FAILED = 'my_products/RESELLER_STOREFRONT_FAILED';
const MODAL_DISMISS = 'my_products/MODAL_DISMISS';
const MODAL_OPEN = 'my_products/MODAL_OPEN';
const PRODUCT_FAMILIES_LOADING = 'my_products/PRODUCT_FAMILIES_LOADING';
const PRODUCT_FAMILIES_LOADED = 'my_products/PRODUCT_FAMILIES_LOADED';
const SET_VNEXT_UPGRADE_ACCOUNT = 'my_products/SET_VNEXT_UPGRADE_ACCOUNT';
const SHIM_ENTITLEMENTS_LOADING = 'my_products/SHIM_ENTITLEMENTS_LOADING';
const SHIM_ENTITLEMENTS_LOADED = 'my_products/SHIM_ENTITLEMENTS_LOADED';
const SHIM_SUBSCRIPTIONS_LOADING = 'my_products/SHIM_SUBSCRIPTIONS_LOADING';
const SHIM_SUBSCRIPTIONS_LOADED = 'my_products/SHIM_SUBSCRIPTIONS_LOADED';
const SHOW_SHIM_BANNER_ERROR = 'my_products/SHOW_SHIM_BANNER_ERROR';
const TOGGLE_ACCORDION_COLLAPSE = 'my_products/TOGGLE_ACCORDION_COLLAPSE';
const SET_ACCORDION_COLLAPSE = 'my_products/SET_ACCORDION_COLLAPSE';
const VENTURE_DATA = 'my_products/VENTURE_DATA';
const DOMAIN_HOSTING_LOADING = 'my_products/DOMAIN_HOSTING_LOADING';
const DOMAIN_HOSTING_LOADED = 'my_products/DOMAIN_HOSTING_LOADED';
const DOMAIN_HOSTING_FAILED = 'my_products/DOMAIN_HOSTING_FAILED';
const RESELLER_ECOMM_LOADING = 'my_products/RESELLER_ECOMM_LOADING';
const RESELLER_ECOMM_LOADED = 'my_products/RESELLER_ECOMM_LOADED';
const RESELLER_ECOMM_FAILED = 'my_products/RESELLER_ECOMM_FAILED';
const DIRECT_TO_CART_LOADING = 'DIRECT_TO_CART_LOADING';
const DIRECT_TO_CART_LOADED = 'DIRECT_TO_CART_LOADED';
const DIRECT_TO_CART_FAILURE = 'DIRECT_TO_CART_FAILURE';
const PRODUCT_RENEWAL_SUCCESS = 'PRODUCT_RENEWAL_SUCCESS';
const VENTURES_LOADING = 'VENTURES_LOADING';
const VENTURES_LOADED = 'VENTURES_LOADED';
const VENTURES_FAILED = 'VENTURES_FAILED';
const DETAILED_VENTURE_LOADING = 'DETAILED_VENTURE_LOADING';
const DETAILED_VENTURE_LOADED = 'DETAILED_VENTURE_LOADED';
const DETAILED_VENTURE_FAILED = 'DETAILED_VENTURE_FAILED';
const ADD_FAILED_VENTURE_ID = 'ADD_FAILED_VENTURE_ID';

module.exports = {
  SHIM_SUBSCRIPTIONS_LOADING,
  SHIM_SUBSCRIPTIONS_LOADED,
  LOADING_EXPERIMENTS,
  PRODUCT_GROUPS_FETCH_STARTED,
  PRODUCT_GROUPS_FETCH_FINISHED,
  FAILED_PRODUCT_GROUPS_FETCH,
  REQUEST_TO_LOAD_APP,
  APP_LOADED,
  FAILED_APP_LOAD,
  APP_LANGUAGE_LOADED,
  FAILED_LANGUAGE_LOAD,
  FAILED_CONFIG_LOAD,
  APP_STATE_LOADING,
  SUBSCRIPTIONS_LOADING,
  SUBSCRIPTIONS_LOADED,
  SSL_CREDITS_LOADED,
  PRODUCT_FAMILIES_LOADING,
  PRODUCT_FAMILIES_LOADED,
  SHIM_ENTITLEMENTS_LOADING,
  SHIM_ENTITLEMENTS_LOADED,
  SHOW_SHIM_BANNER_ERROR,
  FAILED_SUBSCRIPTIONS_LOAD,
  MUIPOD_LOADING,
  MUIPOD_LOADED,
  FAILED_MUIPOD_LOAD,
  RESET_APP_ERRORS,
  FREECREDITS_LOADED,
  FAILED_FREECREDITS_LOADED,
  PAYMENTMETHODS_LOADING,
  PAYMENTMETHODS_LOADED,
  PAYMENTMETHODS_LOAD_FAILED,
  PRO_LOAD,
  PRO_LOADED,
  PRO_MARKETPLACE_LOAD,
  PRO_MARKETPLACE_LOADED,
  FREECREDITS_REDEEM_LOADED,
  FAILED_FREECREDITS_REDEEM,
  TOGGLE_SHOW_FREE_CREDITS,
  FREECREDITS_LOADING,
  FREECREDITS_REDEEM_LOADING,
  TOGGLE_REDEEM_INITIATED,
  GET_DOMAINS_STATUS_LOADED,
  GET_DOMAINS_STATUS_FAILED,
  CHANGE_DOMAIN_ACTIVATOR_ID,
  GET_DOMAINS_STATUS_LOADING,
  WORDPRESS_SITES_FAILED,
  WORDPRESS_SITES_LOADED,
  RESELLER_STOREFRONT_LOADING,
  RESELLER_STOREFRONT_LOADED,
  RESELLER_STOREFRONT_FAILED,
  MODAL_DISMISS,
  MODAL_OPEN,
  SET_VNEXT_UPGRADE_ACCOUNT,
  TOGGLE_ACCORDION_COLLAPSE,
  SET_ACCORDION_COLLAPSE,
  DISPATCH_CONFIG,
  VENTURE_DATA,
  DOMAIN_HOSTING_LOADED,
  DOMAIN_HOSTING_LOADING,
  DOMAIN_HOSTING_FAILED,
  RESELLER_ECOMM_LOADING,
  RESELLER_ECOMM_LOADED,
  RESELLER_ECOMM_FAILED,
  DIRECT_TO_CART_LOADING,
  DIRECT_TO_CART_LOADED,
  DIRECT_TO_CART_FAILURE,
  PRODUCT_RENEWAL_SUCCESS,
  VENTURES_LOADING,
  VENTURES_LOADED,
  VENTURES_FAILED,
  DETAILED_VENTURE_LOADED,
  DETAILED_VENTURE_FAILED,
  ADD_FAILED_VENTURE_ID,
  DETAILED_VENTURE_LOADING
};
