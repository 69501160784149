/* eslint-disable max-statements, no-console */
const getErrorSummary = err => {
  if (!err.response || !err.config) return null;
  return JSON.stringify({
    url: err.config.url,
    method: err.config.method,
    xsrf: err.config.headers && err.config.headers['X-XSRF-Token'],
    csrf: err.config.headers && err.config.headers['X-CSRF-Token'],
    status: err.response.status,
    statusText: err.response.statusText,
    message: err.response.data && err.response.data.message,
    code: err.response.data && err.response.data.code
  });
};

const logTrafficEventLocally = (win, str) => {
  if (win) {
    win.pastTrafficEvents = win.pastTrafficEvents || [];
    if (str) win.pastTrafficEvents.push(str);
    if (!win.searchPastTrafficEvents) {
      win.searchPastTrafficEvents = searchPattern => {
        win.pastTrafficEvents.forEach(eid => {
          if (!searchPattern) {
            console.log(eid);
            return;
          }
          if (eid.search(searchPattern) > -1) console.log(eid);
        });
      };
    }
    if (win.logNewTrafficEvents) {
      console.log('EID', str);
    }
  }
};

/**
 * formats/encodes a location correctly for dataLayer
 *
 * @param {String} location - the location value
 * @param {String} defaultValue - the default value to use if no location value is provided
 * @param {String} detail - the detail of the location to append to the location
 * @returns {String} properly formatted and encoded location component of an eid
 */
const getLocation = (location, defaultValue, detail) => {
  let result = (location || defaultValue).replace(/\./g, '_');
  if (detail) result = `${result}_${detail.replace(/\./g, '_')}`;
  return result;
};

/**
 * formats/encodes an eid for dataLayer
 *
 * @param {String} component - the component value of the eid
 * @param {String} location - the location value of the eid
 * @param {String} action - the action that this event is associated with
 * @param {Object} thisWindow - the window object
 * @returns {String} properly formatted and encoded eid
 */
const formatEid = (component, location, action, thisWindow) => {
  const site = thisWindow.location.pathname.includes('profile') ? 'profile' : 'products';
  return `account.${site}.${component}.${location}.${action}`
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/__/g, '_')
    .replace(/-/g, '_')
    .replace(/&/g, 'and');
};

/**
 * sends a traffic2 event
 *
 * @param {String} eid - the full event ID for the event
 * @param {String} [action = impression] - the type of event, either impression or click
 * @param {Array} [data] - extra array of event data to push
 * @param {Object} [thisWindow = window] - the window object
 */
const logPageEvent = (eid, action = 'impression', data, thisWindow = window) => {
  if (!eid) return;
  if (!thisWindow) return;

  logTrafficEventLocally(thisWindow, eid);
  const { _expDataLayer: expDataLayer = [] } = thisWindow;
  let customProps = {};
  if (data && Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      customProps[data[i][0]] = data[i][1];
    }
  } else {
    customProps = data ? data : {};
  }
  expDataLayer.push({ schema: 'add_event', version: 'v2',
    data: {
      eid: eid,
      type: action,
      custom_properties: customProps
    }
  });
};




// sends a traffic event to RUM instead of the "regular" place
// USE VERY SPARINGLY
// ... only intended as a temporary solution for events that HAVE to include 123-reg,
// since those traffic EID events aren't currently going to our regular / cxclients traffic kibana
const logEventToRum = (eventId, thisWindow = window) => {
  if (!eventId) return;
  if (!thisWindow) return;
  const { _expDataLayer: expDataLayer = [] } = thisWindow;

  expDataLayer.push({
    schema: 'add_perf',
    version: 'v1',
    data: {
      custom_properties: {
        myproducts_event: eventId
      },
      type: 'component_page_perf'
    }
  });
};

const convertMetaData = (metadata = {}) => {
  const data = Object.keys(metadata)
    .reduce(
      (all, key) => {
        all.push(`${encodeURIComponent(key)},${encodeURIComponent(metadata[key])}`);
        return all;
      },
      []
    );
  return data.join('^');
};

const telemetry = {
  /**
   * sends a traffic2 event
   *
   * @param {String} component - a nickname for the react component sending the event
   * @param {String} [location = on_load] - the time/location/hook/event inside the component where the event comes from or why
   * @param {String} [detail] - a means for passing in unique runtime info, such as PFIDs, or unique flags on reused components
   * @param {String} [action] - the type of event, either impression or click
   * @param {Array} [data] - extra array of event data to push
   * @param {Object} [thisWindow = window] - the window object
   */
  // eslint-disable-next-line max-params
  send: (component, location, detail, action, data, thisWindow) => {
    if (!component) return;
    if (!thisWindow && typeof (window) === 'undefined') return;

    const eventAction = action || 'impression';
    if (data) {
      if (!(data[0] && data[0][0] && data[0][0] === 'ns')) data.unshift(['ns', 'gxp']);
    }
    const eid = formatEid(component, getLocation(location, 'on_load', detail), eventAction, thisWindow || window);

    logPageEvent(eid, eventAction, data, thisWindow || window);
  },

  /**
   * sends an api traffic2 event
   *
   * @param {String} name - the name of the api call
   * @param {String} [detail] - extra information about the call
   * @param {Object} [err] - the error object, if there is one
   * @param {Array} [extraData] - any extra items to put into the eventData
   * @param {Object} [thisWindow = window] - the window object
   */
  // eslint-disable-next-line max-params
  api: (name, detail, err, extraData, thisWindow) => {
    if (!name) {
      return;
    }

    if (!thisWindow && typeof (window) === 'undefined') return;

    name = name.replace(/\./g, '_');
    if (detail) name = name + '_' + detail.replace(/\./g, '_');

    let data = null;
    const windowRef = thisWindow || window || {};

    if (err || extraData) {
      data = [['ns', 'gxp']];
      if (err) {
        const errorSummary = getErrorSummary(err);
        if (errorSummary) {
          data.push(['errorSummary', errorSummary]);
        }
      }
      if (extraData) data = data.concat(extraData);
    }

    const eid = `account.products.account_products.${name}.apicall`
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/__/g, '_');

    const customProps = {};
    if (data) {
      for (let i = 0; i < data.length; i++) {
        customProps[data[i][0]] = data[i][1];
      }
    }

    // If traffic hasn't initialized, ensure the queue is initialized for processing once it does
    if (!windowRef._expDataLayer) {
      windowRef._expDataLayer = [];
    }

    const { _expDataLayer: expDataLayer } = windowRef;
    expDataLayer.push({ schema: 'add_event', version: 'v1',
      data: {
        eid: eid,
        type: 'apicall',
        custom_properties: customProps
      }
    });
  },

  /**
   * generates a data-eid string for a button or link
   *
   * @param {String} component - a nickname for the react component generating the eid
   * @param {String} [location = on_click] - the time/location/hook/event inside the component where the eid comes from or why
   * @param {String} [detail] - a means for passing in unique runtime info, such as PFIDs, or unique flags on reused components
   * @param {Object} [thisWindow = window] - the window object
   * @returns {String} - the eid to put on the button or link
   */
  btn: (component, location, detail, thisWindow) => {
    if (!component) return;
    if (!thisWindow && typeof (window) === 'undefined') return;
    return formatEid(component, getLocation(location, 'on_click', detail), 'click', thisWindow || window);
  },

  /**
   * sends a pageRequest event
   *
   * @param {String} path - the path to log
   * @param {Object} [thisWindow = window] - the window object
   */
  route: (path, thisWindow) => {
    if (!path) return;
    if (!thisWindow && typeof (window) === 'undefined') return;

    const { _expDataLayer } = thisWindow || window || {};
    _expDataLayer && _expDataLayer.push({
      schema: 'add_page_request',
      version: 'v1',
      data: {
        virtual_path: path.toLowerCase()
      }
    });
  },

  // logs an eid like this: account.products.experiment.jroig.impression
  logExperimentTriggerEvent: (experimentName, data = {}) => {
    const EID = `account.products.experiment.${experimentName}.exposure`;
    logPageEvent(EID, 'impression', data);
  },

  // account.products.experiment.jroig.on.impression
  logExperimentCohort: (experimentName, cohort) => {
    const EID = `account.products.experiment.${experimentName}_${cohort}.exposure`;
    logPageEvent(EID, 'impression');
  },

  logCustomerType: function (customerType) {
    if (customerType) {
      const cleanedType = customerType.replace(/[^\w]/g, '').toLowerCase();
      // eid account.products.type_{customertype}.on_load.impression
      this.send(`type_${cleanedType}`);
    } else {
      // eid account.products.type_unknown.on_load.impression
      this.send('type_unknown');
    }
  },

  /**
   * Marks the page as being visually complete when called (ready to be rendered, right after spinner disappears).
   * Only expected to be called once.
   */
  markVisualComplete({ performance, _expDataLayer } = window) {
    // Manually trigger a visual complete event if configured
    if (_expDataLayer) {
      performance.mark && performance.mark('visual_complete');
      _expDataLayer.push({ schema: 'add_page_perf', version: 'v1' });
    }
  },

  generateHtmlProperties({ component, location, detail, metaData } = {}, thisWindow = window) {
    if (!component) return;

    return {
      'data-eid': formatEid(component, getLocation(location, 'on_click', detail), 'click', thisWindow),
      'data-tdata': convertMetaData(metaData)
    };
  }
};

module.exports = telemetry;
module.exports.getErrorSummary = getErrorSummary;
module.exports.logPageEvent = logPageEvent;
module.exports.logEventToRum = logEventToRum;
