const queryString = require('query-string');
const urlParse = require('url-parse');

function sanitizeDomainName(name) {
  return (
    name &&
    name
      .replace(/https:\/\//, '')
      .replace(/http:\/\//, '')
      .replace(/\/$/, '')
  );
}

function getCookie(cookieName) {
  // https://www.w3schools.com/js/js_cookies.asp
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function getCurrentQueryParams() {
  return queryString.parse(urlParse(window.location));
}

function getGUID(URL) {
  const URLParams = new URLSearchParams(URL.split('?')[1]);
  return URLParams.getAll('id');
}

module.exports = {
  sanitizeDomainName,
  getCurrentQueryParams,
  getCookie,
  getGUID
};
