const { PRO_LOAD, PRO_LOADED } = require('../constants/action-types');
const initialState = { isPro: false, isLoaded: false, likelyProScore: 0 };

const selectIsPro = state => state.proData?.isPro;

const proData = (state = initialState, action = {}) => {
  switch (action.type) {
    case PRO_LOAD:
      return { ...state };
    case PRO_LOADED:
      return { ...state, isPro: action.isPro, likelyProScore: action.likelyProScore, isLoaded: action.isLoaded };
    default:
      return state;
  }
};

module.exports = {
  default: proData,
  selectIsPro
};
