const {
  amendWithCESsubsApi,
  amendWithShimEntitlements,
  amendWithDomainEligibility,
  amendWithDCC,
  amendWithMWP,
  amendWithWebsiteData,
  amendWithNesShimSub,
  amendWithCommerceSubscriptions,
  amendWithSslCredits
} = require('./NormalizerAmendFunctions').default;
const { correctDomainActivatedField, isEligibleForVentureHome } = require('./NormalizerHelper');
const { getIsReseller } = require('../../../../utilities/ResellerHelper');
const { calculateSecondaryValues, generateCategorizationData } = require('../../config/parsers/product_config_parser');
const { selectSwitchboard } = require('../../../../selectors/selectorsConfig');
/* --------------------------------------------------------------------------
  normalized products have the following format:
  {
    "type": "email",
    "name": "Professional Email - Individual - 1 year(s)",
    "detailedName": "professional@domain.com",
    "activated": true,
    "isPending": false,
    "createdAt": "2020-01-24T23:57:25.747Z",
    "paidThroughDate": "2021-01-24T07:00:00.000Z",
    "autoRenewEnabled": false,
    "id": "438660d6-3f05-11ea-81c2-3417ebe7253b",
    "isUpgradable": false,
    "billing": {
      "paidThroughDateProximity": 100,
      "paidThroughDateStatus": "distant",
      "payStatus": "PAID",
      "specialStatus": "none",
      "isActiveNonRenewable": false,
      "isRenewable": true,
      "thirdParty": "IOS"
    },
    "domain": {
      "tld": "com",
      "protection": "GOOD",
      "allowedGBBActions": []
    },
    "mwp": [
      {
        "domain": "hello.com",
        "siteId": "1234"
      }
    ],
    CES: {
      "resourceId": 56,
      "productTypeId": 767,
      "pfid": 101,
      "relations": { "parent": "297166347:2", "children": [] }, // 5pack only
      "addons": [{ pfid: '1234' }],
      "status": 'ACTIVE'
    },
    isHardBundled: false, // For NES -> is not a domain or wam entitlement, CES -> doesn't have hard bundles
    relations: {
      parentId: null,
      childrenIds: []
    }
  }
--------------------------------------------------------------------------*/
let context = {
  locale: 'en-US'
};

function normalizeSubscriptions(state, config) {
  const cesSubscriptions = state.apiData.cesSubscriptions;
  const sslCredits = state.apiData.sslCredits;

  amendWithCESsubsApi({ cesSubscriptions, products: state.products }, config);
  amendWithSslCredits({ sslCredits, products: state.products });
  amendWithSecondaryApis(state, config);
  normalizeSecondaryValues(state, config);
  normalizeCategorizationData(state);
}

function normalizeShimEntitlements(state, config) {
  const entitlements = state.apiData.entitlements;

  amendWithShimEntitlements({ entitlements, products: state.products }, config);
  amendWithSecondaryApis(state, config);
  normalizeSecondaryValues(state, config);
  normalizeCategorizationData(state);
}

// Secondary APIs normalization
function normalizeShimSubscriptions(state, config) {
  const shimSubscriptions = state.apiData.shimSubscriptions;

  amendWithNesShimSub({ shimSubscriptions, products: state.products }, config);
  normalizeSecondaryValues(state, config);
  normalizeCategorizationData(state);
}

function normalizeDomainsDCC(state, config) {
  const dccFabric = state.apiData.dccFabric;

  amendWithDCC({ dccFabric, products: state.products });
  correctDomainActivatedField(state.products);
  normalizeSecondaryValues(state, config);
  normalizeCategorizationData(state);
}

function normalizeDomainEligibility(state, config) {
  const domainEligibility = state.apiData.domainEligibility;

  amendWithDomainEligibility({ domainEligibility, products: state.products });
  normalizeSecondaryValues(state, config);
  normalizeCategorizationData(state);
}

function normalizeManagedWordpress(state, config) {
  const mwpSites = state.apiData.mwpSites;

  amendWithMWP({ sites: mwpSites, products: state.products });
  normalizeSecondaryValues(state, config);
  normalizeCategorizationData(state);
}

function normalizeWebsiteData(state, config) {
  const websiteData = state.apiData.websiteData;

  amendWithWebsiteData({ websiteData, products: state.products });
  normalizeSecondaryValues(state, config);
  normalizeCategorizationData(state);
}

function normalizeSecondaryValues(state, config) {
  const {
    window: {
      document: { dir: windowDir = 'auto' }
    }
  } = global;
  // define extra params that may be needed by the product_config generation funcs
  const switchboard = state?.config?.switchboard;
  const productCount = Object.keys(state?.products || {})?.length;
  const products = Object.values(state?.products || {}) || [];
  const isReseller = getIsReseller(state?.config?.plid);
  const isBiDi = windowDir === 'rtl';
  const domainerInfo = state.domainerInfo;

  const isEligibleForVH = isEligibleForVentureHome(switchboard, products, isReseller, isBiDi, domainerInfo);

  context = {
    isReseller,
    locale: config ? config?.market : context.locale,
    productCount,
    customerType: state.apiData.customerType,
    isBiDi,
    experiments: state.experiments,
    plid: state.config?.plid,
    isEligibleForVentureHome: isEligibleForVH,
    featureFlags: selectSwitchboard(state),
    products
  };

  for (const [id] of Object.entries(state.products)) {
    const productObj = state.products[id];
    // calculate and add all secondary values for each NPO
    calculateSecondaryValues(productObj, context);
  }
}

function normalizeCategorizationData(state) {
  // preserve isCollapsed variables
  const collapsedness = {};
  for (const key of Object.keys(state.categorizationData)) {
    collapsedness[key] = state.categorizationData[key].isCollapsed;
  }

  // generate the categorizationData object, which will drive the accordions
  state.categorizationData = generateCategorizationData(state.products, { plid: state.config.plid });

  // restore isCollapsed vars
  for (const key of Object.keys(collapsedness)) {
    if (state.categorizationData[key]) {
      state.categorizationData[key].isCollapsed = collapsedness[key];
    }
  }

  return state.categorizationData;
}

function normalizeCommerceSubscriptions(state, config) {
  const products = state.products;
  const commerceSubscriptions = state.apiData.commerceSubscriptions;

  amendWithCommerceSubscriptions({ products, commerceSubscriptions });
  normalizeSecondaryValues(state, config);
}

function amendWithSecondaryApis(state, config) {
  const dccFabric = state.apiData.dccFabric || {};
  const domainEligibility = state.apiData.domainEligibility;
  const mwpSites = state.apiData.mwpSites;
  const websiteData = state.apiData.websiteData;
  const shimSubscriptions = state.apiData.shimSubscriptions;
  const commerceSubscriptions = state.apiData.commerceSubscriptions;
  const products = state.products;

  // UPP Eligibility must happen after every CES/NES subscriptions load
  amendWithNesShimSub({ shimSubscriptions, products }, config);
  // DCC
  amendWithDCC({ dccFabric, products });
  correctDomainActivatedField(state.products);

  amendWithDomainEligibility({ domainEligibility, products });
  amendWithMWP({ sites: mwpSites, products });
  amendWithWebsiteData({ websiteData, products });
  amendWithCommerceSubscriptions({ products, commerceSubscriptions });
}

module.exports = {
  normalizeSubscriptions,
  normalizeShimEntitlements,
  normalizeShimSubscriptions,
  normalizeDomainsDCC,
  normalizeDomainEligibility,
  normalizeManagedWordpress,
  normalizeWebsiteData,
  normalizeSecondaryValues,
  normalizeCategorizationData,
  normalizeCommerceSubscriptions
};
