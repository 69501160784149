const SET_WINDOW_SIZE = require('./actions').SET_WINDOW_SIZE;

const PHABLET_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 992;
const XL_BREAKPOINT = 1200;

const XL_HEIGHT_BREAKPOINT = 1080;
const PHABLET_HEIGHT_BREAKPOINT = 775;

function getIsMobile(width) {
  // Unknown width, maybe SSR
  if (!width) return;

  // Based on phablet breakpoint in @ux/component/breakpoints
  return width < PHABLET_BREAKPOINT;
}

module.exports = {
  // Reducers
  default: (state = {}, action = {}) => {
    switch (action.type) {
      case SET_WINDOW_SIZE: {
        return {
          ...state,
          ...(action.windowSize || {})
        };
      }
      default: {
        return state;
      }
    }
  },
  getIsMobile,
  // Selectors
  isMobile: (state = {}) => {
    const { windowSize = {} } = state;
    const { width } = windowSize;

    return getIsMobile(width);
  },

  isTablet: (state = {}) => {
    const { windowSize = {} } = state;
    const { width } = windowSize;

    // Unknown size, maybe SSR
    if (typeof width === 'undefined') {
      return;
    }

    // Based on tablet breakpoint in @ux/component/breakpoints
    return width > PHABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT;
  },

  selectIsXlHeight: (state = {}) => {
    const { windowSize = {} } = state;
    const { height } = windowSize;

    // Unknown size, maybe SSR
    if (typeof height === 'undefined') {
      return;
    }

    return height >= XL_HEIGHT_BREAKPOINT;
  },
  selectIsSmallHeight: (state = {}) => {
    const { windowSize = {} } = state;
    const { height } = windowSize;

    // Unknown size, maybe SSR
    if (typeof height === 'undefined') {
      return;
    }

    return height <= PHABLET_HEIGHT_BREAKPOINT;
  },

  selectIsXlScreen: (state = {}) => {
    const { windowSize = {} } = state;
    const { width } = windowSize;

    // Unknown size, maybe SSR
    if (typeof width === 'undefined') {
      return;
    }

    // Based on tablet breakpoint in @ux/component/breakpoints
    return width >= XL_BREAKPOINT;
  }
};
