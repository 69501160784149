/* eslint-disable complexity */
const {
  TOGGLE_SHOW_FREE_CREDITS,
  FREECREDITS_LOADING,
  FREECREDITS_LOADED,
  FAILED_FREECREDITS_LOADED,
  FREECREDITS_REDEEM_LOADED,
  FAILED_FREECREDITS_REDEEM,
  TOGGLE_REDEEM_INITIATED,
  FREECREDITS_REDEEM_LOADING
} = require('../constants/action-types');

const GOCENTRAL_PFID = '891787';
const GOCENTRAL_PFID_PRODUCT_NAME = 'InstantPage (GoCentral)';

const initialFreeCreditsState = {
  showFreeCredits: false,
  isFreeCreditsListCached: false,
  freeCreditsModalData: {},
  isFreeCreditsLoading: false,
  isFreeCreditsError: false,
  isFreeCreditsRedeemLoading: false,
  isFreeCreditsRedeemError: false
};

const normalize = freeCreditData => {
  return freeCreditData.map(item => {
    if (item.productPfid) item.productPfid = item.productPfid.toString();
    return item.productPfid === GOCENTRAL_PFID ? { ...item, productName: GOCENTRAL_PFID_PRODUCT_NAME } : { ...item };
  });
};

const freeCredits = (state = initialFreeCreditsState, action = {}) => {
  switch (action.type) {
    case TOGGLE_SHOW_FREE_CREDITS:
      return { ...state, showFreeCredits: !action.showFreeCredits };
    case FREECREDITS_LOADING:
      return { ...state, isFreeCreditsLoading: true };
    case FREECREDITS_LOADED:
      return {
        ...state,
        freeCreditsData: normalize(action.payload.data),
        isFreeCreditsListCached: true,
        isFreeCreditsLoading: false,
        isFreeCreditsError: false
      };
    case FAILED_FREECREDITS_LOADED:
      return {
        ...state,
        isFreeCreditsLoading: false,
        isFreeCreditsError: true
      };
    case FREECREDITS_REDEEM_LOADED:
      return {
        ...state,
        isFreeCreditsRedeemLoading: false,
        isFreeCreditsRedeemError: false,
        freeCreditsRedeemData: {
          ...action.data,
          ...action.payload.data.body
        }
      };
    case FAILED_FREECREDITS_REDEEM:
      return {
        ...state,
        ...action.payload,
        isFreeCreditsRedeemLoading: false,
        isFreeCreditsRedeemError: true
      };
    case TOGGLE_REDEEM_INITIATED:
      return {
        ...state,
        freeCreditsRedeemInitiated: !action.freeCreditsRedeemInitiated,
        isFreeCreditsRedeemError: false,
        freeCreditsRedeemData: null
      };
    case FREECREDITS_REDEEM_LOADING:
      return {
        ...state,
        isFreeCreditsRedeemLoading: true
      };
    default:
      return state;
  }
};

module.exports = {
  default: freeCredits,
  normalize,
  GOCENTRAL_PFID,
  GOCENTRAL_PFID_PRODUCT_NAME
};
