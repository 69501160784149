const {
  WORDPRESS_SITES_LOADED,
  WORDPRESS_SITES_FAILED
} = require('../constants/action-types');

const managedWordpress = (state = {}, action = {}) => {
  switch (action.type) {
    case WORDPRESS_SITES_LOADED:
      return { ...state, sites: action.managedWordpress, error: null };
    case WORDPRESS_SITES_FAILED:
      return { ...state, error: action.err, sites: [] };
    default:
      return state;
  }
};

module.exports = {
  default: managedWordpress
};
