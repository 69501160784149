const {
  SPECIAL_STATUS: { NONE, TRIAL },
  PUBLISH_STATUS: { PUBLISHED },
  PRODUCT_NAMES: { GODADDY_PAYMENTS },
  EXPIRATION_DATE_STATUS: { PASSED, NOW, SOON }
} = require('../constants/app');
const { freemiumPfids } = require('../constants/freemiumPfids');
const { productTypes } = require('../src/account-products/config/constants');
const PFIDs = require('../src/account-products/config/pfids');
const {
  HOSTING,
  CPANEL,
  WEBSITE_BUILDER,
  WORDPRESS,
  EMAIL_AND_OFFICE,
  OPEN_XCHANGE,
  SSL_CERTS,
  DOMAINS,
  DOMAIN,
  PRODUCTIVITY
} = require('../constants/product-group-keys');

function getIsTrial(product = {}) {
  return product.billing?.specialStatus === TRIAL;
}

function getIsExpiring(product = {}) {
  return [NOW, SOON].includes(product.billing?.paidThroughDateStatus);
}

function getIsExpired(product = {}) {
  return product.billing?.paidThroughDateStatus === PASSED;
}

function getIsFreemium(product = {}) {
  const isTrial = getIsTrial(product);
  const isExpired = getIsExpired(product);
  const hasFreemiumPFID = freemiumPfids.includes(product.CES?.pfid);
  return (isTrial && isExpired) || hasFreemiumPFID || !!product?.billing?.freemium;
}

function getIsWAM(product = {}) {
  const { WEBSITE_BUILDER, WEBSITES_AND_MARKETING } = productTypes;
  return (
    [WEBSITE_BUILDER, WEBSITES_AND_MARKETING].includes(product.type) &&
    !PFIDs.ONLINE_MARKETING.includes(product.CES?.pfid) &&
    !PFIDs.DIGITAL_MARKETING.includes(product.CES?.pfid)
  );
}

function getIsDigitalMarketing(product = {}) {
  const { WEBSITE_BUILDER, WEBSITES_AND_MARKETING } = productTypes;
  return (
    PFIDs.DIGITAL_MARKETING.includes(product.CES?.pfid) ||
    // NOTE(rbenedum): This handles the premierFreeTrial plan being setup on NES while the other ones are on CES
    ([WEBSITE_BUILDER, WEBSITES_AND_MARKETING].includes(product.type) && product.plan === 'premierFreeTrial')
  );
}

function getIsPaidProduct(product = {}) {
  return product.billing.specialStatus === NONE;
}

function getHasECRenewableType(product = {}) {
  return [
    DOMAIN,
    DOMAINS,
    WORDPRESS,
    WEBSITE_BUILDER,
    PRODUCTIVITY,
    EMAIL_AND_OFFICE,
    OPEN_XCHANGE,
    SSL_CERTS,
    HOSTING,
    CPANEL
  ].includes(product.type);
}

function getHasVTEligibleRenewableType(product = {}) {
  return [
    WORDPRESS,
    WEBSITE_BUILDER,
    PRODUCTIVITY,
    EMAIL_AND_OFFICE,
    OPEN_XCHANGE
  ].includes(product.type);
}

function getIsDomain(product = {}) {
  return [DOMAINS, DOMAIN, productTypes.LEASED_DOMAIN].includes(product.type);
}

function getHasExcludedPfid(product = {}, excludedPfids = []) {
  return (
    excludedPfids.includes(product.CES?.pfid) ||
    product.CES?.addons?.some(addon => excludedPfids.includes(addon.pfid))
  );
}

function getProductCount(NPOs = {}) {
  return (Object.values(NPOs) || []).length;
}

// eslint-disable-next-line valid-jsdoc
/**
 * Exclude certain products from being counted for eligibility threshold:
 * GD Payments (any kind of activation state)
 * Freemium Unpublished W+M
 * Unactivated products:
 *   Free trials of any products
 *   SSL
 */
function getFilteredProductCount(productObjs = {}) {
  const filteredProductObjs = (Object.values(productObjs) || [])
    .filter(
      productObj => productObj?.name?.toLowerCase() !== GODADDY_PAYMENTS &&
      !(getIsFreemium(productObj) && getIsWAM(productObj) && productObj?.publishStatus !== PUBLISHED) &&
      !((productObj?.billing?.trial || productObj?.billing?.specialStatus === TRIAL) && !productObj?.activated) &&
      !((productObj?.type === productTypes.SSL_CERTIFICATE || productObj?.type === productTypes.SSL_CERTS) && !productObj?.activated)
    );
  return filteredProductObjs.length;
}

const isMultiSiteMwp = product => PFIDs.MWP_MULTISITE.includes(product.CES?.pfid);

const mwpHasCustomDomain = (product, products) => {
  const mwpDomain = product?.detailedName?.toLowerCase();

  if (!mwpDomain || product.type !== WORDPRESS) {
    return false;
  }

  return Object.values(products)
    .some(product => (
      product.type === DOMAINS &&
      product.detailedName.toLowerCase() === mwpDomain
    ));
};

function getIsVhSupportedMwp(product) {
  if (
    product.type !== WORDPRESS ||
    isMultiSiteMwp(product) ||
    product.isChild ||
    product.hasChildren ||
    !product.activated
  ) {
    return false;
  }
  return true;
}

const getIsMwpOnlyProduct = (product, products) => {
  const isVhSupportedMwp = getIsVhSupportedMwp(product);
  return isVhSupportedMwp && !mwpHasCustomDomain(product, products);
};

const getMwpIsDomainAttached = (product, products) => {
  if (product.type !== WORDPRESS || product.isChild || isMultiSiteMwp(product)) {
    // only account for the parent MWP product
    return false;
  }

  return mwpHasCustomDomain(product, products);
};

module.exports = {
  getIsPaidProduct,
  getIsWAM,
  getIsDomain,
  getIsFreemium,
  isMultiSiteMwp,
  getIsExpired,
  getIsExpiring,
  getProductCount,
  getIsDigitalMarketing,
  getIsMwpOnlyProduct,
  getHasECRenewableType,
  getHasExcludedPfid,
  getMwpIsDomainAttached,
  mwpHasCustomDomain,
  getFilteredProductCount,
  getIsVhSupportedMwp,
  getHasVTEligibleRenewableType
};
