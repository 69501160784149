// TODO: Lower this default once we are established in more global regions
// Default timeout is 15 seconds
const defaultTimeoutMS = 15000;

/**
 * Class to wrap abort controller behavior for handling timeouts
 *
 * @class TimeoutController
 */
class TimeoutController {
  /**
   * Creates an instance of TimeoutController.
   * @param {object} configuration - Configuration for API request adapter
   * @param {number} configuration.timeoutMS - timeout in milliseconds for any API call described
   * @memberof TimeoutController
   */
  constructor({ timeoutMS = defaultTimeoutMS } = {}) {
    this._controller = new AbortController();
    this.signal = this._controller.signal;
    this._timeoutMS = timeoutMS;
  }

  /**
   * Begins a timeout that would trigger an abort signal
   * @memberof TimeoutController
   */
  beginTimeout() {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    this._timeoutId = setTimeout(() => this._controller.abort(), this._timeoutMS);
  }

  /**
   * Cancel any outstanding timeouts that would trigger an abort signal
   * @memberof TimeoutController
   */
  cancelTimeout() {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
      this._timeoutId = void 0;
    }
  }
}

module.exports = TimeoutController;
module.exports.defaultTimeoutMS = defaultTimeoutMS;
