const {
  BROKERAGE_PFIDS,
  GD_STUDIO,
  SHOWINBIO,
  SMARTLINE_ANDROID_PFIDS,
  SMARTLINE_IOS_PFIDS
} = require('../src/account-products/config/pfids');
const productTypes = require('./nes-product-types');
const productGroupKeys = require('./product-group-keys');

const optionsDisabledPfids = [
  '847025',
  '877385',
  '877387',
  '877388',
  '877390',
  '877392',
  '877393',
  '877394',
  '877396',
  '877398',
  '877401',
  '877402',
  '877404',
  '877406',
  '877409',
  '877410',
  '877412',
  '878089',
  '939416',
  '1022644', // pfid exception originally for reseller only
  '1022646',
  '1022647',
  '1022649',
  '1022651',
  '1022653',
  '1022655',
  '1022657',
  '1022659',
  '1022661',
  '1224151',
  '1224153',
  '1303241',
  '1303243'
].concat(SMARTLINE_ANDROID_PFIDS)
  .concat(SMARTLINE_IOS_PFIDS)
  .concat(GD_STUDIO)
  .concat(BROKERAGE_PFIDS)
  .concat(SHOWINBIO);

const optionsDisabledTypes = [productGroupKeys.RESELLER, productTypes.GD_STUDIO];

module.exports = {
  optionsDisabledPfids,
  optionsDisabledTypes
};
