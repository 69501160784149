// This is the list of product types we currently support from NES
const productTypes = {
  DOMAIN: 'domain',
  MICROSOFT_EMAIL: 'microsoftEmail',
  OMNI_PAY: 'omniCommerce', // GD Payments (parent entitlements)
  OMNI_HARDWARE: 'omniHardware', // GD Payments Hardware (aka child entitlements)
  WEBSITES_AND_MARKETING: 'websitesAndMarketing',
  ADULT_BLOCK: 'adultBlock',
  GLOBAL_BLOCK: 'globalBlock',
  GD_STUDIO: 'designStudio',
  COMMERCE_HOME: 'commerceHome',
  COMMERCE_BASE: 'commerceBase',
  INVOICING: 'invoicing',
  POINT_OF_SALE: 'pos'
};

module.exports = productTypes;
