const {
  REQUEST_TO_LOAD_APP,
  APP_LOADED,
  SUBSCRIPTIONS_LOADED,
  SUBSCRIPTIONS_LOADING,
  FAILED_APP_LOAD,
  RESET_APP_ERRORS,
  MODAL_OPEN,
  MODAL_DISMISS
} = require('../constants/action-types');
const initialState = require('../constants/initialState');

const app = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TO_LOAD_APP:
      return { ...state, isLoading: true };
    case APP_LOADED:
      return { ...state, isLoading: false };
    case SUBSCRIPTIONS_LOADING:
      return { ...state, subsLoaded: false };
    case SUBSCRIPTIONS_LOADED:
      return { ...state, subsLoaded: true };
    case FAILED_APP_LOAD:
      return { ...state, hasError: true, error: action.error };
    case RESET_APP_ERRORS:
      return { ...state, hasError: false };
    case MODAL_DISMISS:
      return { ...state, modal: null, modalInfo: null };
    case MODAL_OPEN:
      return { ...state, modal: action.modal, modalInfo: action.info };
    default:
      return state;
  }
};

module.exports = {
  default: app,
  errorCode: state => state.app.errorCode
};
