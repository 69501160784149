const { init: initApm } = require('@elastic/apm-rum');
const urlParse = require('url-parse');

const cleanTransactionNames = (url) => {
  const transactionNameUrl = url
    .replace(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/, ':uuid')
    .replace(/\/[\d:]+\//, '/:id/')
    .replace(/\/[\d:]+$/, '/:id')
    .replace(/\/domains\/(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])\//, '/domains/:domain/')
    .replace(/\/shoppers\/[0-9a-z]{3}$/, '/shoppers/:id')
    .replace(/\/get-by-shopper-id\/[0-9a-z]{3}$/, '/get-by-shopper-id/:id')
    .replace(/\/gateway\/v2\/customers\/(.+?)\/subscriptions/, '/gateway/v2/customers/:id/subscriptions')
    .replace(/\/customertypeapi\/v1\/get-by-shopper-id\/(.*)/, '/customertypeapi/v1/get-by-shopper-id/:id')
    .replace(/\/dccfabric\/v1\/customers\/(.+?)\/domains/, '/dccfabric/v1/customers/:id/domains')
    .replace(/\/platapi\/v1\.1\/shoppers\/(.+?)\?auditClientIp/, '/platapi/v1.1/shoppers/:id?auditClientIp')
    .replace(/\/products\/catalogapi\/Catalog\/v1\/pricelockprice\?pfid=(.+?)&productTypeId=(.+?)&resourceId=(.+)/, '/products/catalogapi/Catalog/v1/pricelockprice?pfid=:pfid&productTypeId=:pTypeId&resourceId=:resourceId');
  return transactionNameUrl;
};

function ApmClient() {
  this.client = null;

  this.configure = function configure(apmProps, shopperId, enableDebugMode) {
    if (this.client != null) return;
    if (!apmProps.serviceName || !apmProps.serverUrl || !apmProps.serviceVersion || !apmProps.environment) {
      console.log('Error: APM RUM missing parameter. APM RUM will not start.');
      return;
    }

    // client config docs: https://www.elastic.co/guide/en/apm/agent/rum-js/current/configuration.html
    this.client = initApm({
      serviceName: apmProps.serviceName,
      serverUrl: apmProps.serverUrl,
      serviceVersion: apmProps.serviceVersion,
      environment: apmProps.environment,
      transactionSampleRate: 0.2, // sampling rate is only for transactions -- errors are 100% recorded
      disableInstrumentations: ['eventtarget', 'click', 'history'],
      logLevel: enableDebugMode ? 'debug' : 'info',
      ignoreTransactions: [
        /fullstory/,
        /heartbeat/,
        /liveengage/,
        /google-analytics/,
        /qualtrics/,
        /pageEvents/,
        /eventbus/,
        /events/,
        /intake/
      ]
    });

    if (shopperId) {
      this.client.setUserContext({
        id: shopperId
      });
    }
  };

  /**
   * Creates a new elasticApm transaction of type 'custom'
   * @param {String} method - Http method (e.g. 'GET', 'POST', 'DELETE')
   * @param {String} url - Endpoint url (e.g. '/gateway/v2/customers/60e0b505-5383-472c-9397-54014124fc56/entitlementsSummary')
   * @returns {Object} - The created transaction
   */
  this.startElasticApmTransaction = (method = '', url) => {
    if (!this.client) return null;
    const parsedUrl = urlParse(url);

    // uuids and ids are replaced in order to avoid having one separate transaction per customer
    const transactionNameUrl = cleanTransactionNames(parsedUrl.pathname);
    // Creates a new transaction with the specified name and type === 'custom'
    const transaction = this.client.startTransaction(`${method} ${transactionNameUrl}`, 'custom');

    if (parsedUrl.query) {
      const queryParams = JSON.parse('{"' + decodeURIComponent(parsedUrl.query.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
      transaction?.addLabels(queryParams);
    }
    return transaction;
  };

  this.endSuccessfulApmTransaction = (transaction) => {
    if (transaction != null && transaction) {
      transaction.outcome = 'success'; // This populates the transaction error rate in ESS
      transaction.end();
    }
  };

  this.endFailedApmTransaction = (transaction) => {
    if (transaction != null && transaction) {
      transaction.outcome = 'failure'; // This populates the transaction error rate in ESS
      transaction.end();
    }
  };

  this.captureErrorElasticApm = (errorMessage, transaction, customData = {}) => {
    if (!this.client) return;

    if (errorMessage) {
      const outputErrorMessage = cleanTransactionNames(errorMessage);
      this.client.captureError(outputErrorMessage, { customData, parent: transaction });
    }

    if (transaction != null && transaction) {
      this.endFailedApmTransaction(transaction);
    }
  };
}

const theClient = new ApmClient();

module.exports = {
  apmClient: theClient,
  cleanTransactionNames
};
