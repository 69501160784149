/* eslint-disable id-length */
const get = require('lodash/get');
const {
  PAID,
  EXPIRATION_DATE_STATUS: { SOON, PASSED, NOW, DISTANT },
  SPECIAL_STATUS: { NONE, FREE, TRIAL },
  EXPIRED,
  FAILED_BILLING,
  ACTIVE_NR
} = require('../../../../constants/app');
const { HOSTING_PRODUCT_TYPE_IDS } = require('../../../../constants/product-type-ids');
const PFIDs = require('../../config/pfids');

const { freemiumPfids } = require('../../../../constants/freemiumPfids');
const NESproductTypes = require('../../../../constants/nes-product-types');
const productGroupKeys = require('../../../../constants/product-group-keys');
const renewDisabledPFIDs = require('../../../../constants/renew-disabled-pfids').default;
const thirdPartyBillingVendors = require('../../../../constants/thirdPartyBillingVendors');
const { productTypes, isUPPEligibleProductGUID } = require('../../config/constants');
const { findProductCategoryInfo } = require('../../config/parsers/product_config_parser');
const { isCommerceSaasProduct } = require('../../config/product_config_helpers');

const { getIntlCache } = require('../intlHelper');
const intlCache = getIntlCache();

// returns positive if second date comes after first date
const daysBetween = (first, second) => {
  // Copy date parts of the timestamps, discarding the time parts.
  const one = Date.UTC(first.getUTCFullYear(), first.getUTCMonth(), first.getUTCDate());
  const two = Date.UTC(second.getUTCFullYear(), second.getUTCMonth(), second.getUTCDate());

  // Do the math.
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisBetween = two - one;
  const days = millisBetween / millisecondsPerDay;

  // Round down.
  return Math.floor(days);
};

function normalizeBillingPeriod(original) {
  original = original?.toUpperCase();
  switch (original) {
    case 'MONTH':
      return 'MONTHLY';
    case 'YEAR':
      return 'ANNUAL';
    default:
      return original;
  }
}

/**
 * getDetailedName returns the common name of a product, adjusting for product-specific overrides
 * @param {bool} isDomain is the product a domain
 * @param {string} entitlementType npo.type
 * @param {string} commonName the name of a specific product
 * @param {string} entitlementName the name of the product sold
 * @param {string} sld "second-level domain"
 * @param {string} tld "top-level domain"
 * @returns {string} the common name of a product
 */
// eslint-disable-next-line max-params
function getDetailedName({ isDomain, entitlementType, commonName, entitlementName, sld, tld }) {
  if (isDomain) {
    if (entitlementType === productTypes.LEASED_DOMAIN) {
      return sld.concat('.', tld);
    }
    return commonName.toLowerCase();
  } else if (entitlementType === NESproductTypes.OMNI_HARDWARE) {
    return entitlementName;
  }
  return commonName;
}

/**
 * generate a unified billing object for a normalized product
 *
 * @param {string} paidThroughDate ISO string of the date the product's current term will end
 * @param {boolean} autoRenew whether or not the product is set to auto-renew
 * @param {string} productGroupKeyOrType the product's CES group key or NES product type
 * @param {object} billing the subsApi billing object, if this is a CES product
 * @param {string} pfid the product's PFID, if this is a CES product
 * @param {string} status the product's status, if this is a CES product
 * @param {number} numberOfTerms number of billing periods a product is paid for
 * @param {boolean} renewable if product is renewable
 * @param {string} period the billing period, like ANNUAL or MONTHLY... optional, will convert NES type terms to CES type terms
 * @param {boolean} [isThirdParty = false] if the product is a third party entitlement. it comes from NES, it's false by default for CES cases.
 * @returns {object} the completed billing object, with all values calculated
 */
/* eslint-disable-next-line max-params */
const getBillingInfo = ({
  paidThroughDate,
  autoRenew,
  productGroupKeyOrType,
  billing,
  pfid,
  status,
  numberOfTerms,
  renewable = true,
  period = '',
  isThirdParty = false,
  paymentProfileId
}) => {
  const nonRenewableProductGroups = [
    productGroupKeys.CLOUD_SERVERS,
    productGroupKeys.DOMAIN_BACKORDER,
    productGroupKeys.PRODUCTIVITY,
    productGroupKeys.BROKERAGE,
    NESproductTypes.OMNI_PAY,
    NESproductTypes.OMNI_HARDWARE,
    NESproductTypes.GD_STUDIO
  ];
  // Currently the isThirdParty property is only for app store entitlement
  const thirdPartyIos = (PFIDs.SMARTLINE_IOS_PFIDS.includes(pfid) || isThirdParty) && thirdPartyBillingVendors.IOS;
  const thirdPartyAndroid = PFIDs.SMARTLINE_ANDROID_PFIDS.includes(pfid) && thirdPartyBillingVendors.ANDROID;
  const isFreeProduct = billing ? billing.commitment === FREE || freemiumPfids.includes(pfid) : false;
  const days = daysBetween(new Date(), new Date(paidThroughDate));
  const result = {
    payStatus: PAID, // PAID, EXPIRED, FAILED_BILLING
    paidThroughDateStatus: DISTANT,
    specialStatus: NONE, // NONE, FREE, TRIAL
    paidThroughDateProximity: days, // days between now and paidThroughDate
    isActiveNonRenewable: status ? status === ACTIVE_NR : false,
    isRenewable: renewable,
    thirdParty: thirdPartyIos || thirdPartyAndroid || null,
    period: normalizeBillingPeriod(period),
    numberOfTerms,
    paymentProfileId
  };
  if (days > 90) {
    result.paidThroughDateStatus = DISTANT;
  } else if (days === 0) {
    result.paidThroughDateStatus = NOW;
  } else if (days < 0) {
    result.paidThroughDateStatus = PASSED;
    result.payStatus = autoRenew ? FAILED_BILLING : EXPIRED;
  } else {
    result.paidThroughDateStatus = SOON;
  }
  // TODO: grab this from NES too when they expose it
  if (billing) {
    if (billing.commitment === TRIAL && freemiumPfids.includes(pfid)) {
      // if freemium trial, stay trial
      result.specialStatus = TRIAL;
    } else if (isFreeProduct) {
      result.specialStatus = FREE;
    } else if (billing.commitment === TRIAL) {
      result.specialStatus = TRIAL;
    }
  }

  const isAppStoreNESProduct = !pfid && result.thirdParty;

  // TODO: Get this info from NES when they expose it
  result.isRenewable =
    (!renewDisabledPFIDs.includes(pfid) && // Make sure that pfids included in this list are marked as not renewable
      !isAppStoreNESProduct &&
      (result.specialStatus === TRIAL || // this trial check is needed to flag trial products that are also freemium
        !(nonRenewableProductGroups.includes(productGroupKeyOrType) || isFreeProduct))) &&
    renewable !== false;
  return result;
};

/**
 * apply custom logic to NPO type assignment for CES subscription
 *
 * @param {object} sub the CES subscription
 * @returns {string} the product type for the NPO
 */
function getType(sub) {
  // TODO: check that the rest of the product types are being assigned correctly
  if (PFIDs.OPEN_XCHANGE.includes(sub.pfid?.toString())) return productGroupKeys.OPEN_XCHANGE;
  if (PFIDs.KABBAGE.includes(sub.pfid?.toString())) return productGroupKeys.KABBAGE;
  if (PFIDs.CUSTOM_PFID.DUDA.includes(sub.pfid?.toString()) || sub.namespace === productGroupKeys.DUDA) return productGroupKeys.DUDA;
  return sub.productGroupKey;
}

function getIsProductSetupSupportedSelfServe(productObj) {
  const categoryInfo = findProductCategoryInfo(productObj);
  return categoryInfo?.isSetupSupported || false;
}

function mapFivePackRelationsIds(relationsMap, sub, resultId) {
  if (getType(sub.product) === productGroupKeys.EMAIL_AND_OFFICE) {
    if (sub.relations?.children?.length > 0) {
      relationsMap[sub.subscriptionId] = relationsMap[sub.subscriptionId] || {};
      relationsMap[sub.subscriptionId].parentId = resultId;
    }
    if (sub.relations?.parent) {
      relationsMap[sub.relations.parent] = relationsMap[sub.relations.parent] || {};
      relationsMap[sub.relations.parent].childrenIds = relationsMap[sub.relations.parent].childrenIds || [];
      relationsMap[sub.relations.parent].childrenIds.push(resultId);
    }
  }
}

function addRelationsToNPOs(relationsMap, products) {
  Object.values(relationsMap).forEach(({ parentId, childrenIds }) => {
    if (products[parentId]) {
      products[parentId].relations.childrenIds = childrenIds || [];
    }
    if (!childrenIds) {
      return;
    }
    childrenIds.forEach(childId => {
      if (products[childId]) {
        products[childId].relations.parentId = parentId;
      }
    });
  });
}

/**
 * Fill in the relationsMap instance with any GD Payments children & parent info
 *
 * @param {Object} relationsMap - reference to the map we're going to end up modifying
 * @param {Object} entitlement - current entitlement we're transforming into an NPO
 */
function mapGDPaymentsRelationsIds(relationsMap, entitlement) {
  if (entitlement.product.productType === NESproductTypes.OMNI_HARDWARE) {
    if (entitlement.parentEntitlementUri) {
      // Grab the entitlementId from the URI which is the string after the last slash
      // ex URI: /customers/e15ebf22-4108-41ef-8dcc-018ead551c86/entitlements/f618c04e-f53b-11eb-815c-0050569a00bd
      const parentId = entitlement.parentEntitlementUri.split('/').pop();
      relationsMap[parentId] = relationsMap[parentId] || {};
      relationsMap[parentId].parentId = parentId;
      relationsMap[parentId].childrenIds = relationsMap[parentId].childrenIds || [];
      relationsMap[parentId].childrenIds.push(entitlement.entitlementId);
    }
  }
}

/**
 * Map child commerce home products to parent gdpayment products by shallow copying products
 * Hides all gdpayments hardware sibling products if product is commerce home
 * @param {Object} products - npos
 */
function mapCommerceHomeRelationsToGDPayments(products) {
  const productsArr = Object.values(products);
  productsArr.forEach(product => {
    if (product.type === NESproductTypes.COMMERCE_HOME) {
      const parent = productsArr.find(p => p.type === NESproductTypes.OMNI_PAY && p.NES.businessId === product.NES.businessId);
      const parentId = parent?.id;
      if (!parentId) {
        return;
      }
      products[product.id] = {
        ...products[product.id],
        relations: {
          ...products[product.id].relations,
          parentId
        },
        get hasChildren() { return this.relations.childrenIds.length > 0; },
        get isChild() { return this.relations.parentId.length > 0; }
      };
      const commerceHomeChildrenIds = products[parentId].relations.childrenIds
        .filter((id) => products[id].type === NESproductTypes.COMMERCE_HOME);

      products[parentId] = {
        ...products[parentId],
        relations: {
          ...products[parentId].relations,
          childrenIds: [...commerceHomeChildrenIds, product.id]
        },
        get hasChildren() { return this.relations.childrenIds.length > 0; },
        get isChild() { return this.relations.parentId.length > 0; },
        commerceHomeStoresCount: (commerceHomeChildrenIds?.length || 0) + 1
      };
    }
  });
}

/**
 * Map child commerce saas products to parent gdpayment products by shallow copying products
 * Does not map to parent product if commerceSubscription.storeId is missing
 * @param {Object} products - npos
 */
function mapCommerceSaasRelationsToGDPayments(products) {
  const productsArr = Object.values(products);
  productsArr.forEach(product => {
    if (isCommerceSaasProduct(product)) {
      const parent = productsArr.find(p => p.type === NESproductTypes.OMNI_PAY && p.NES.businessId === product.NES.businessId);
      const parentId = parent?.id;
      if (!parentId || !product?.commerceSubscription?.storeId) {
        return;
      }
      products[product.id] = {
        ...products[product.id],
        relations: {
          ...products[product.id].relations,
          parentId
        },
        get hasChildren() { return this.relations.childrenIds.length > 0; },
        get isChild() { return this.relations.parentId.length > 0; }
      };

      products[parentId] = {
        ...products[parentId],
        relations: {
          ...products[parentId].relations,
          childrenIds: [
            ...products[parentId].relations.childrenIds,
            product.id
          ].sort((a, b) => {
            const aStoreId = products[a].NES?.storeId ?? '';
            const bStoreId = products[b].NES?.storeId ?? '';
            return aStoreId.localeCompare(bStoreId);
          })
        },
        get hasChildren() { return this.relations.childrenIds.length > 0; },
        get isChild() { return this.relations.parentId.length > 0; }
      };
    }
  });
}

/**
 * Adds the businessId in the GDPayment Hardware products considering their parent's businessId
 * @param {Object} products - npos
 */
function addBusinessIdToGDPaymentsHW(products) {
  Object.values(products).forEach(product => {
    if (
      product.type === NESproductTypes.OMNI_HARDWARE &&
      product.relations?.parentId &&
      products[product.relations.parentId]?.NES
    ) {
      product.NES.businessId = products[product.relations.parentId].NES.businessId;
    }
  });
}

function hasUPPEligibleProductId(uri) {
  // parse the product id from the uri
  // ex. /customers/bbbb-bbbb-bbbb-bbbb/products/aaaa-aaaa-aaaa-aaaa
  // will parse to aaaa-aaaa-aaaa-aaaa
  const productIdPattern = /.*products\//gi;
  const uppEnabledProductGUID = isUPPEligibleProductGUID;
  if (typeof uri === 'string') {
    const productId = uri?.replace(productIdPattern, '');
    if (productId.includes(uppEnabledProductGUID)) {
      return true;
    }
  }
  return false;
}

const parseEntitlementID = uri => {
  const re = /.*entitlements\//gi;
  const entitlementId = uri?.replace(re, '');
  return entitlementId;
};

const checkSwitchboardEnabledNamespaces = (subscriptionId, UPPEnabledProducts = {}) => {
  let foundByNamespace = false;

  let uppEnabledProduct = Object.entries(UPPEnabledProducts).some(([namespace, value]) => {
    if (subscriptionId?.includes(`${namespace}:`)) {
      foundByNamespace = true;
      const isProductEnabled = Boolean(value.enabled);
      return isProductEnabled;
    }
  });

  const isWildcardConfigPresent = Boolean(UPPEnabledProducts['*']?.enabled);

  // Only use fallback logic when namespace was not present in Switchboard config
  if (!foundByNamespace && !uppEnabledProduct && isWildcardConfigPresent) {
    uppEnabledProduct = true;
  }

  return uppEnabledProduct;
};

/**
 * Return list of UPP eligible products based on data from NES subs call and UPP eligibility information
 * @param {array} shimSubscriptions subs returned from NES/subscriptions call
 * @param {object} UPPEnabledProducts describes UPP eligible product types (group keys) and eligibility information, sent from switchboard
 * @returns {array} Product IDs or Entitlement IDs of individual UPP eligible products
 */
const identifyUPPEligibleNESProducts = ({ shimSubscriptions = [], UPPEnabledProducts = {} }) => {
  const uppEligibleSubscriptions = [];
  shimSubscriptions?.forEach((subscription) => {
    // retrieve the array of products from within the subscription's offer
    const { offer: { products }, subscriptionId, resourceId } = subscription;

    const canValidateWSBProduct = (subscriptionId?.includes('wsb:') && products?.length && UPPEnabledProducts?.wsb?.enabled);

    // check for WAM productid && namespace
    if (canValidateWSBProduct) {
      const wamEligibleProduct = products.find(({ product: { uri } }) => hasUPPEligibleProductId(uri));
      if (wamEligibleProduct) { uppEligibleSubscriptions.push(subscriptionId?.replace('wsb:', '')); }
    } else {
      // check switchboard for enabled name spaces
      // for NON WAM products we will only check name space
      const found = checkSwitchboardEnabledNamespaces(subscriptionId, UPPEnabledProducts);
      if (found) { uppEligibleSubscriptions.push(resourceId); }
    }
  });
  // check for any duplicates
  const result = [...new Set(uppEligibleSubscriptions)];
  return result;
};

const mapIsUppEligibleToNPO = (uppEligibleSubscriptions, products, UPPEnabledProducts) => {
  for (const [productId, product] of Object.entries(products)) {
    const { id, CES } = product;

    if (CES) {
      let isUPPEligible = false;
      // Verification against ShimSubscriptions already filtered by switchboard
      if (uppEligibleSubscriptions.includes(CES.resourceId)) {
        isUPPEligible = true;
      } else if (CES.nesSubscriptionId?.includes(':')) {
        // Check against switchboard for CES products that were not present on ShimSubscriptions array
        if (checkSwitchboardEnabledNamespaces(CES.nesSubscriptionId, UPPEnabledProducts)) {
          isUPPEligible = true;
        }
      }

      products[productId] = {
        ...products[productId],
        isUPPEligible: isUPPEligible
      };
    } else if (uppEligibleSubscriptions.includes(id)) {
      products[productId] = {
        ...products[productId],
        isUPPEligible: true
      };
    } else {
      products[productId] = {
        ...products[productId],
        isUPPEligible: false
      };
    }
  }
  return products;
};

function identifyUPPEnabledCESProducts({ config, sub, productGroupKey }) {
  const hasUppFeatureFlag = config?.switchboard?.enableUPP;
  var uppEligibleSubscription = false;
  if (hasUppFeatureFlag) {
    const UPPEnabledProducts = config?.switchboard?.UPPEnabledProducts;
    const hasBundleRelations = (sub.bundleRelations?.length > 0);
    // UPP Eligibility
    if (productGroupKey !== productGroupKeys.WEBSITE_BUILDER) {
      for (const namespace in UPPEnabledProducts) {
        if (UPPEnabledProducts[namespace]) {
          const isProductEnabled = Boolean(UPPEnabledProducts[namespace]?.enabled);
          const isGroupKeyEnabled = UPPEnabledProducts[namespace]?.groupKeys?.includes(productGroupKey);
          const isEligibleProduct = isProductEnabled && isGroupKeyEnabled;
          if (sub.subscriptionId?.includes(namespace) && isEligibleProduct && !hasBundleRelations) {
            uppEligibleSubscription = true;
          }
        }
      }
    }
  }
  return uppEligibleSubscription;
}

/*
  for domains, we need to set their Activated field to TRUE
  if there is a matching W+M or WSB product present
*/
function correctDomainActivatedField(products) {
  Object.values(products).forEach(productObjOne => {
    if (productObjOne.domain && !productObjOne.activated) {
      Object.values(products).forEach(productObjTwo => {
        if ([productTypes.WEBSITES_AND_MARKETING, productTypes.WEBSITE_BUILDER].includes(productObjTwo.type)) {
          if (productObjTwo.detailedName.toLowerCase() === productObjOne.detailedName.toLowerCase()) {
            products[productObjOne.id] = {
              ...products[productObjOne.id],
              activated: true
            };
          }
        }
      });
    }
  });
}

function appendNewAccount(productGroupKey, config) {
  const intl = intlCache(config.market);
  if ([productTypes.SSL_CERTS, productTypes.COMMERCE_HOME].includes(productGroupKey)) {
    return intl.formatMessage({ id: 'newAccount' });
  }
  return '';
}

const getIsHostingOwner = productObjs => {
  return Object.values(productObjs)
    .some(productObj => Object.keys(HOSTING_PRODUCT_TYPE_IDS).includes(productObj?.CES?.productTypeId) || productObj.type === productTypes.HOSTING_CPANEL);
};

/* CXAP-6985: replicated isEligibleForVenture logic, as importing it here was causing some issues.Below code, used for Accordions
   Implementation can be found here: https://github.com/gdcorp-im/account-products/blob/master/selectors/selectorsVentureHome.js */

// eslint-disable-next-line max-params
function isEligibleForVentureHome(featureFlags, products, isReseller, isBidi, domainerInfo = {}) {
  if (featureFlags?.enableVentureTilesSkipValidation) {
    return true;
  }

  if (!featureFlags.enableVentureTiles) {
    return false;
  }

  const isDomainer = domainerInfo.isDomainer || 0;
  const domainCount = domainerInfo.domainCount || 0;

  if (isDomainer) {
    if (products?.length + domainCount - 1 >= featureFlags.ventureHomeProductCount) {
      return false;
    }
  } else if (products?.length >= featureFlags.ventureHomeProductCount) {
    return false;
  }

  const isHostingOwner = getIsHostingOwner(products);
  if (!products.length || isReseller || isBidi || isHostingOwner) {
    return false;
  }

  return true;
}

/**
 * We search the bundled domain traversing the bundleRelations of the bundle
 * until we find a child that is a domain
 *
 * @param {Array} subscriptions array to find the domain product in the bundle
 * @param {object} bundle the bundle product
 * @returns {string} the domain name of the domain product inside the bundle
 */
function getBundleDomain(subscriptions, bundle) {
  const { bundleRelations = [] } = bundle;
  for (const relation of bundleRelations) {
    const child = subscriptions.find(sub => sub.subscriptionId === relation.subscriptionId);
    const isDomain = [productGroupKeys.DOMAINS, NESproductTypes.DOMAIN].includes(child?.product.productGroupKey);
    if (isDomain) {
      return child.label;
    }
  }
  return null;
}

function getNormalizedBundleChild(bundleChild, bundleDomainName, bundle) {
  const normalizedBundleChild = {
    ...bundleChild,
    billing: bundle.billing,
    renewable: bundle.renewable,
    renewAuto: bundle.renewAuto,
    expiresAt: bundle.expiresAt,
    bundleName: bundle.product.label
  };

  const bundleChildIsDomain = [
    productGroupKeys.DOMAINS,
    NESproductTypes.DOMAIN
  ].includes(normalizedBundleChild?.product.productGroupKey);

  if (!bundleChildIsDomain && bundleDomainName) {
    normalizedBundleChild.bundleDomain = bundleDomainName.toLowerCase();
  }

  return normalizedBundleChild;
}

/**
 * This function overrides the billing info of the bundle into its children
 * this is made for consistency across the billing status of the children.
 *
 * Additionally, the non-domain bundle children will have the domain name set in them
 * this is for ease of access
 *
 * @param {Array} subscriptions the subscriptions from the subscriptions API
 * @param {object} bundleSubscription a bundle subscription
 */
function normalizeBundleSubsChildren(subscriptions = [], bundleSubscription = {}) {
  const isBundle = bundleSubscription.product.productGroupKey === productTypes.BUNDLE;
  if (!isBundle) {
    return;
  }

  const bundleDomain = getBundleDomain(subscriptions, bundleSubscription);
  const { bundleRelations = [] } = bundleSubscription;
  for (const bundleRelation of bundleRelations) {
    const bundleChildIndex = subscriptions.findIndex(({ subscriptionId }) => subscriptionId === bundleRelation.subscriptionId);

    if (bundleChildIndex >= 0) {
      const bundleChild = subscriptions[bundleChildIndex];
      const normalizedBundleChild = getNormalizedBundleChild(bundleChild, bundleDomain, bundleSubscription);
      subscriptions[bundleChildIndex] = normalizedBundleChild;
    }
  }
}

/**
 * It will look for a bundle product and modify its children properties to match
 * that of his parent, this is made for consistency on the billing side and showing
 * the appropriate domain the bundle products are attached to
 *
 * @param {Array} subscriptions the subscriptions from the subscriptions API
 * @returns {Array} the mutated subscriptions array
 */
function parseSubscriptionsBundles(subscriptions = []) {
  const CESRequiredFields = ['product.productGroupKey', 'subscriptionId'];
  subscriptions.forEach(sub => {
    // if some required field is missing, do nothing
    if (!CESRequiredFields.every(reqField => get(sub, reqField, false))) {
      return;
    }

    const isBundle = sub.product.productGroupKey === productTypes.BUNDLE;
    if (isBundle) {
      normalizeBundleSubsChildren(subscriptions, sub);
    }
  });

  return subscriptions;
}

module.exports = {
  identifyUPPEnabledCESProducts,
  hasUPPEligibleProductId,
  mapIsUppEligibleToNPO,
  identifyUPPEligibleNESProducts,
  checkSwitchboardEnabledNamespaces,
  parseEntitlementID,
  correctDomainActivatedField,
  getDetailedName,
  getBillingInfo,
  getType,
  getIsProductSetupSupportedSelfServe,
  mapFivePackRelationsIds,
  addRelationsToNPOs,
  mapGDPaymentsRelationsIds,
  mapCommerceHomeRelationsToGDPayments,
  mapCommerceSaasRelationsToGDPayments,
  addBusinessIdToGDPaymentsHW,
  appendNewAccount,
  isEligibleForVentureHome,
  getIsHostingOwner,
  parseSubscriptionsBundles,
  normalizeBundleSubsChildren
};
